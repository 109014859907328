import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ProfileEditBtn from "../../common/ProfileEditBtn";
import { FaRegEdit } from "react-icons/fa";

const MyProfile = () => {

    const {user} = useSelector((state) => state.profile);
    const navigate = useNavigate();

    return(
        <div className="p-3 xl:px-[6rem] xl:py-[5rem] overflow-y-auto bg-white">
            <h1 className="text-4xl text-center sm:text-left font-bold overflow-hidden mt-5 xl:mt-0 sm:max-xl:ml-5">
                My profile
            </h1>

            <p className="mt-5 font-semibold text-center sm:text-left sm:max-xl:ml-5">Here you can visit your public information about yourself.
                <br />This information will be displayed to other users.
            </p>

            {/* section 1 */}
            <div className="bg-white border border-slate-200 p-10 rounded-3xl mt-10 shadow-lg
            flex items-center flex-col-reverse gap-7 sm:flex-row justify-between">
                <div className="flex flex-col items-center sm:items-start gap-10 justify-around">
                    <div className="flex flex-col gap-3 items-center sm:items-start">
                        <p className="text-5xl font-bold overflow-hidden">{user?.firstName + " " + user?.lastName}</p>
                        <p>{user?.email}</p>
                    </div>
                    <div>
                        <ProfileEditBtn 
                        text={<>Edit <FaRegEdit /></>}
                        onClick={() => navigate("/dashboard/settings")}
                        />
                    </div>
                </div>

                {/* Profile Img */}
                <div className="w-[75%] sm:w-[30%] lg:w-[20%] xl:w-3/12">
                    <img src={user?.image?.url}
                    alt={`profile-${user?.firstName}`}
                    className="aspect-square w-full rounded-full object-cover"
                    />
                </div>
            </div>

            {/* section 2 */}
            <div className="bg-white border border-slate-200 p-10 rounded-3xl mt-10 shadow-lg
            flex flex-col gap-7 sm:flex-row justify-between items-center w-full">
                <div className="w-full text-center sm:text-left">
                    <p className="text-4xl font-bold overflow-hidden">About</p>
                    <p className={`mt-5 ${user?.additionalDetails?.about === null ? "text-slate-300" : "font-semibold"}`}>{user?.additionalDetails?.about ?? "Write something about yourself..."}</p>
                </div>
                <div className="w-full justify-items-center sm:justify-items-end">
                <ProfileEditBtn 
                text={<>Edit <FaRegEdit /></>}
                onClick={() => {navigate("/dashboard/settings")}}
                />
                </div>
            </div>

            {/* section 3 */}
            <div className="bg-white border border-slate-200 p-10 rounded-3xl mt-10 shadow-lg
            flex flex-col sm:flex-row justify-between items-center sm:items-start">
                <div className="w-fit">
                    <p className="text-4xl overflow-hidden font-semibold mb-4">Personal Details</p>
                    <div className="flex flex-col sm:flex-row rounded-lg shadow-md gap-8 lg:gap-16 xl:gap-20">
                        <div className="w-fit flex flex-col">
                            <div className="mb-4">
                            <p className="text-sm text-gray-600">First Name</p>
                            <p className="text-lg font-medium">{user?.firstName}</p>
                            </div>
                            <div className="mb-4">
                                <p className="text-sm text-gray-600">Email</p>
                                <p className="text-lg font-medium">{user?.email}</p>
                            </div>
                            <div>
                                <p className="text-sm text-gray-600">Gender</p>
                                <p className={`text-lg font-medium ${user?.additionalDetails?.gender === null ? "text-slate-300" : ""}`}>{user?.additionalDetails?.gender ?? "Add Gender"}</p>
                            </div>
                        </div>

                        <div className="w-fit flex flex-col">
                            <div className="mb-4">
                            <p className="text-sm text-gray-600">Last Name</p>
                            <p className="text-lg font-medium">{user?.lastName}</p>
                            </div>
                            <div className="mb-4">
                                <p className="text-sm text-gray-600">Phone Number</p>
                                <p className={`text-lg font-medium ${user?.additionalDetails?.contactNumber === null ? "text-slate-300" : ""}`}>{user?.additionalDetails?.contactNumber ?? "Add Contact Number"}</p>
                            </div>
                            <div>
                                <p className="text-sm text-gray-600">Date of Birth</p>
                                <p className={`text-lg font-medium ${user?.additionalDetails?.dateOfBirth === null ? "text-slate-300" : ""}`}>{user?.additionalDetails?.dateOfBirth ?? "Add Date of Birth"}</p>
                            </div>
                        </div>
                    </div>
                    
                    
                </div>
                <div className="mt-6 justify-items-center sm:justify-items-end">
                    <ProfileEditBtn 
                    text={<>Edit <FaRegEdit /></>}
                    onClick={() => {navigate("/dashboard/settings")}}
                    />
                </div>
            </div>


        </div>
    )
};

export default MyProfile;