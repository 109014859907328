import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import {Provider} from 'react-redux';
import rootReducer from './reducer';
import { configureStore } from '@reduxjs/toolkit';
import { Toaster } from 'react-hot-toast';
import ScrollToTop from './components/common/ScrollToTop';

const store = configureStore({
  reducer:rootReducer,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop />
        <App />
        <Toaster toastOptions={{
          style: {
            background: '#333',
            color: '#fff',
            padding: '16px',
            borderRadius: '8px',
            maxWidth: '300px',  // Adjust width to fit content
            overflow: 'hidden',  // Prevent scroll bar
            display: 'flex',
            alignItems: 'center',
            position: 'relative'
          },
          success:{
            style:{
              width: "300px",
              overflow: "hidden"
            }
          },
          error:{
            style:{
              width: "300px",
              overflow: "hidden"
            }
          }
          }}/>
      </BrowserRouter> 
    </Provider> 

);
