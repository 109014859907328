import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../../services/operations/authAPI";
import { LuFileSpreadsheet } from "react-icons/lu";
import { MdOutlineNotifications } from "react-icons/md";
import { IoMdHelpCircleOutline } from "react-icons/io";
import { LuDoorOpen } from "react-icons/lu";
import { VscAccount } from "react-icons/vsc";

const MobileProfileDropDown = ({user, draftPosts}) => {

    const dispatch = useDispatch();
    
    const navigate = useNavigate();

    return(
        <>
            <div className="border-2 w-[200px] lg:w-[300px] rounded-xl h-auto flex flex-col items-center bg-white">
                <div className="flex w-full items-center">
                    <div className="w-full h-fit flex p-2 py-2 lg:py-5 gap-4 items-center justify-center">
                        <div className="rounded-full">
                            <img src={user?.image?.url} className="w-[30px] h-[30px] lg:w-[50px] lg:h-[50px] rounded-full" />  
                        </div>
                        
                        <div className="flex flex-col font-semibold">
                            <h3 className="text-[14px] lg:text-base">{user?.firstName} {user?.lastName}</h3>
                            <p className="text-[10px] lg:text-[13px] text-gray-300">{user?.email}</p>
                        </div>
                    </div>
                </div>

                <div className="w-[90%] h-[1px] bg-gray-200"></div>

                <div className="w-full p-4 px-2 py-0 lg:py-4 lg:px-8 flex flex-col h-auto lg:gap-2">
                    <Link to="dashboard/my-profile">
                        <div className="flex items-center gap-3 hover:bg-gray-200 p-2 rounded-lg transition-all duration-300
                        text-gray-400 hover:text-black">
                            <VscAccount />Dashboard
                        </div>
                    </Link>
                    <Link to="dashboard/draft">
                        <div className="flex items-center gap-3 hover:bg-gray-200 p-2 rounded-lg transition-all duration-300
                            text-gray-400 hover:text-black">
                                <LuFileSpreadsheet />Drafts
                                <span className="bg-black hidden w-4 h-4 rounded-full text-[11px] lg:flex justify-center items-center
                                text-white">
                                    {draftPosts?.length}
                                </span>
                        </div>
                    </Link>
                    
                        <div className="flex items-center gap-3 p-2 rounded-lg transition-all duration-300
                            text-gray-400 select-none line-through">
                            <MdOutlineNotifications />Notifications
                        </div>
                    
                    <Link to="dashboard/help">
                        <div className="flex items-center gap-3 hover:bg-gray-200 p-2 rounded-lg transition-all duration-300
                            text-gray-400 hover:text-black">
                            <IoMdHelpCircleOutline />Help Center
                        </div>
                    </Link>
                </div>

                <div className="w-[90%] h-[1px] bg-gray-200"></div>

                <div>
                    <button className="text-gray-400 flex gap-3 items-center font-semibold
                    px-5 py-4 hover:scale-90 hover:text-black transition-all duration-300 text-[14px] lg:text-base"
                    onClick={() => dispatch(logout(navigate))}>
                        <LuDoorOpen />
                        Log Out
                    </button>
                </div>
                {/*  */}
            </div>
        </>
    )
}

export default MobileProfileDropDown;