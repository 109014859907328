import React from "react";
import { FaCheck } from "react-icons/fa";
import { useSelector } from "react-redux";
import PostInformationForm from "./PostInformation/PostInformationForm";
import PostBuilderForm from "./PostBuilder/PostBuilderForm";
import PublishPost from "./PublishPost/index";

const RenderSteps = () => {

    const {step} = useSelector((state) => state.post);

    const steps = [
        {
            id: 1,
            title: "Post Information",
        },
        {
            id: 2,
            title: "Post Builder",
        },
        {
            id: 3,
            title: "Publish",
        }
    ]

    return(
        <>
        <div className="w-fit sm:w-full mx-auto flex flex-col sm:flex-row items-center justify-between bg-white p-4 mt-6 px-6 rounded-2xl border-2 border-slate-200">
          {steps.map((item, index) => (
            <React.Fragment key={item.id}>
              <div className="flex flex-col justify-center items-center">
                <div
                  className={`flex items-center justify-center w-8 h-8 rounded-full text-center ${
                    step > item.id
                      ? "bg-green-500 text-white"
                      : step === item.id
                      ? "bg-blue-600 border-blue-600 text-white"
                      : "bg-gray-300 text-gray-500"
                  }`}
                >
                  {step > item.id ? <FaCheck /> : item.id}
                </div>
                <span className="ml-2 text-black mt-3">{item.title}</span>
              </div>
              {index < steps.length - 1 && (
                <div className="flex-grow w-[3px] sm:my-0 sm:w-0 h-[40px] my-2 sm:h-1 bg-gray-200 mx-2"></div>
              )}
            </React.Fragment>
          ))}
        </div>
        <div className="mt-4 h-full">
          {step === 1 && <PostInformationForm />}
          {step === 2 && <PostBuilderForm />}
          {step === 3 && <PublishPost />}
        </div>
      </>
    )
};

export default RenderSteps;