import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FirstImageComponent from "./FirstImageComponent";
import SecondImageComponent from "./SecondImageComponent";
import ThirdImageComponent from "./ThirdImageComponent";
import { storiesEndpoints } from "../../../../../services/apis";
import { apiConnector } from "../../../../../services/apiconnector";
import toast from "react-hot-toast";
import IconBtn from "../../../../common/IconBtn";

const StoryForm = () => {

    const [loading, setLoading] = useState(false);
    const {token} = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {CREATE_STORY_API} = storiesEndpoints;

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: {errors}
    } = useForm();

    const onSubmit = async (data) => {
        //console.log("FORM DATA", data);

        const formData = new FormData();
        formData.append("storyName", data.storyName);
        formData.append("theme", data.theme);
        formData.append("timeTaken", data.timeTaken);
        formData.append("speciality", data.speciality);
        formData.append("storyContent", data.storyContent);
        formData.append("firstThumbnailImage", data.firstThumbnailImage);
        formData.append("secondThumbnailImage", data.secondThumbnailImage);
        formData.append("thirdThumbnailImage", data.thirdThumbnailImage);

        const toastId = toast.loading("Creating Story");
        try{
            setLoading(true);
            
            const response = await apiConnector("POST", CREATE_STORY_API, formData, {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
            });
            //console.log("RESPONSE", response);

            if(!response.data.success){
                throw new Error(response.data.message);
            }

            toast.success("Story Created Successfully");
            setLoading(false);
            navigate("/dashboard/my-stories");
        }
        catch(error){
            console.log("ERROR", error);
            toast.error("Failed to Create Story");
        }
        toast.dismiss(toastId);
    }

    return(
        <>
            <div className="flex items-center gap-4 mt-6">
                <div className="p-2 w-[35px] h-[35px] bg-black text-white rounded-full flex items-center justify-center overflow-hidden"> 
                    1
                </div>
                <p className="text-2xl font-light">Information</p>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}
                  className="rounded-lg space-y-8 mt-8">
                
                <div className="mb-4">
                    <label htmlFor="name" className="block font-bold mb-2">
                        Story Name <span className="text-blue-500">*</span>
                    </label>
                    <input
                        type="text"
                        id="storyName"
                        className="w-full rounded-md p-2 focus:outline-none bg-gray-200 text-black"
                        placeholder="Enter Story Name"
                        {...register("storyName", {required:true})}
                    />
                    {
                        errors.storyName && <span className="text-red-500">This field is required</span>
                    }
                </div>

                <div className="mb-4">
                    <label htmlFor="theme" className="block font-bold mb-2">
                        Theme <span className="text-blue-500">*</span>
                    </label>
                    <input
                        type="text"
                        id="theme"
                        className="w-full rounded-md p-2 focus:outline-none bg-gray-200 text-black"
                        placeholder="Enter Theme"
                        {...register("theme", {required:true})}
                    />
                    {
                        errors.theme && <span className="text-red-500">This field is required</span>
                    }
                </div>

                <div className="mb-4">
                    <label htmlFor="timeTaken" className="block font-bold mb-2">
                        Time Taken <span className="text-blue-500">*</span>
                    </label>
                    <input
                        type="text"
                        id="timeTaken"
                        className="w-full rounded-md p-2 focus:outline-none bg-gray-200 text-black"
                        placeholder="Enter Time Taken"
                        {...register("timeTaken", {required:true})}
                    />
                    {
                        errors.timeTaken && <span className="text-red-500">This field is required</span>
                    }
                </div>

                <div className="mb-4">
                    <label htmlFor="speciality" className="block font-bold mb-2">
                        Speciality <span className="text-blue-500">*</span>
                    </label>
                    <textarea
                        id="speciality"
                        className="w-full rounded-md p-2 focus:outline-none bg-gray-200 text-black resize-none
                        min-h-[100px]"
                        placeholder="The clearer and shorter the better"
                        {...register("speciality", {required:true})}
                    ></textarea>
                    {
                        errors.speciality && (
                            <span className="text-red-500">This field is required</span>
                        )
                    }
                </div>

                <div className="mb-4">
                    <label htmlFor="storyContent" className="block font-bold mb-2">
                        Story Description/Content <span className="text-blue-500">*</span>
                    </label>
                    <textarea
                        id="storyContent"
                        className="w-full rounded-md p-2 focus:outline-none bg-gray-200 text-black resize-none
                        min-h-[140px]"
                        placeholder="Description of your story"
                        {...register("storyContent", {required:true})}
                    ></textarea>
                    {
                        errors.storyContent && (
                            <span className="text-red-500">This field is required</span>
                        )
                    }
                </div>

                {/* Images component */}
                <FirstImageComponent setValue={setValue} />
                <SecondImageComponent setValue={setValue} />
                <ThirdImageComponent setValue={setValue} />
                
                <div className='flex justify-end items-center px-2 gap-5 py-2'>
                    <IconBtn 
                        text={"Publish Story"}
                        type="submit"
                        onClick={() => handleSubmit(onSubmit)}
                        customClasses={"px-6 py-3 bg-blue-600 hover:bg-blue-700 transition-all duration-300 rounded-lg text-white flex items-center gap-2"}
                    />
                </div>
            </form>
        </>
    )
};

export default StoryForm;