import React from "react";

const GettingStarted = () => {
    return (
        <div className="w-full flex flex-col gap-10 h-fit">
            <h1 className="text-center text-[3rem] font-bold text-[#8D493A]">Getting Started</h1>
            
            <div className="flex flex-col lg:flex-row gap-5 lg:gap-0 w-full mt-5">
                <div className="w-full lg:w-1/2 flex flex-col gap-3">
                    <p className="font-semibold text-[3rem] 2xl:text-[5rem] text-center lg:text-left">Introduction</p>
                </div>

                <div className="w-full lg:w-1/2 flex flex-col gap-20 text-slate-400">
                    <p className="text-lg text-justify">
                        Welcome to <span className="font-semibold text-[#8D493A]">Jeviknows</span>! We are thrilled to have you join our dynamic community where creativity knows no bounds. 
                        Our platform is dedicated to empowering individuals like you to express your thoughts, share experiences, and engage with a 
                        diverse audience. Whether you’re looking to document your personal journey, share your expertise on a specific topic, or simply 
                        explore the vast world of ideas and opinions, <span className="font-semibold text-[#8D493A]">Jeviknows</span> is the perfect space for you. We believe that everyone 
                        has a story worth telling, and our mission is to provide you with the tools and resources to share that story with the world. 
                        Let’s embark on this exciting journey of writing, reading, and connecting!
                    </p>

                    <p className="text-lg text-justify">
                        At <span className="font-semibold text-[#8D493A]">Jeviknows</span>, the possibilities are endless! You can create and publish your own blog posts, allowing your 
                        voice to be heard and your ideas to take shape. Our user-friendly editor makes it easy to format your writing, add images, 
                        and customize your layout, giving you the freedom to present your content exactly how you envision it. 
                        Additionally, you can explore a rich library of blogs covering a multitude of topics—from travel and lifestyle to technology 
                        and self-improvement. Engage with posts that resonate with you, share your favorites on social media, and  stay updated on their latest content. 
                        Your adventure begins here—dive in and discover the community that awaits!
                    </p>
                </div>
            </div>

            <div className="w-full h-[2px] bg-slate-200"></div>

            <div className="flex flex-col-reverse lg:flex-row gap-5 lg:gap-0 w-full mt-5">

                <div className="w-full lg:w-1/2 flex flex-col gap-20 text-slate-400">
                    <p className="text-lg text-justify">
                        <span className="text-black font-semibold text-xl">Sign Up Process:</span><br/><br/>
                        Creating an account on <span className="font-semibold text-[#8D493A]">Jeviknows</span> is quick and easy. To get started, simply click on the "Sign Up" button prominently 
                        displayed at the top right corner of the homepage. You’ll be guided through a straightforward process where you'll enter your name, email 
                        and choose a secure password. Once you submit your information, keep an eye on your inbox for a confirmation OTP. This step 
                        is crucial—just type the OTP in the given field  to verify your account. Once you’ve verified your account, you can log in and 
                        access all the features our platform has to offer. This small step opens the door to a world of creativity and community!
                    </p>
                </div>

                <div className="w-full lg:w-1/2 flex flex-col gap-3 text-center lg:text-right">
                    <p className="font-semibold text-[3rem] sm:text-[5rem]">Creating Account</p>
                </div>
            </div>

            <div className="w-full h-[2px] bg-slate-200"></div>

            <div className="flex flex-col lg:flex-row w-full gap-5 lg:gap-0 mt-5">

                <div className="w-full lg:w-1/2 flex flex-col gap-3 text-center lg:text-left">
                    <p className="font-semibold text-[3rem] sm:text-[5rem]">Exploring Platform</p>
                </div>

                <div className="w-full lg:w-1/2 flex flex-col gap-20 text-slate-400">
                    <p className="text-lg text-justify">
                        <span className="text-black font-semibold text-xl">Visiting Posts:</span><br/><br/>
                        Finding fresh and engaging content on <span className="font-semibold text-[#8D493A]">Jeviknows</span> is an enjoyable experience. 
                        Our homepage features a selection of highlighted posts, 
                        showcasing the most recent and popular blogs from various creators. You can easily browse through these posts or use the category filters to explore 
                        specific topics that pique your interest. Categories such as Lifestyle, Technology, Health, and more are readily available to help you navigate our 
                        extensive library. Each post provides a glimpse into the author’s unique perspective, allowing you to dive deep into discussions and ideas that 
                        resonate with you. When you find a post that captures your interest, simply click on it to read in full. Discover more of their work—it’s a great way 
                        to expand your reading list!
                    </p>

                    <p className="text-lg text-justify">
                        <span className="text-black font-semibold text-xl">Reading blogs:</span><br/><br/>
                        Engaging with content is where the magic happens! While reading a blog post, you’ll find an opportunity to interact with the author and other readers. 
                        The comments section allows you to share your thoughts, ask questions, or express appreciation for the content. This interaction not only enriches 
                        your reading experience but also fosters a sense of community among users. Additionally, if you find a particular blog that resonates with you, don’t 
                        hesitate to use the "Share" feature to save it's link for later. This way, you can easily revisit your favorite posts at any time. Remember, you can also 
                        react to posts with likes, providing authors with valuable feedback and encouragement to keep creating. Embrace the opportunity to connect, 
                        converse, and collaborate within our community!
                    </p>
                </div>
            </div>

            <div className="w-full h-[2px] bg-slate-200"></div>

            <div className="flex flex-col-reverse lg:flex-row gap-5 lg:gap-0 w-full mt-5">

                <div className="w-full lg:w-1/2 flex flex-col gap-20 text-slate-400">
                    <p className="text-lg text-justify">
                        We believe in the power of stories and the impact they can have on individuals and communities. That’s why we provided a dedicated section
                        for posts and stories from our administrator. These posts are handpicked by our team to provide valuable insights, perspectives, and
                        experiences from Admin. Whether you’re looking for personal growth, learning new skills, or simply seeking inspiration, these stories
                        will provide you with the motivation and guidance you need to succeed. We encourage you to explore these stories and take advantage of the
                        content offered by our platform. Your support and engagement are greatly appreciated!
                    </p>
                </div>

                <div className="w-full lg:w-1/2 flex flex-col gap-3 text-center lg:text-right">
                    <p className="font-semibold text-[3rem] sm:text-[5rem]">Stories from Admin</p>
                </div>
            </div>

            <div className="flex w-full mt-5 mx-auto">
                <p className="text-lg text-center">You can explore and access various features offered by us upon creating the account. If you want to Edit your profile according to your preferences
                and is not sure where to start kindly visit <span className="text-[#8D493A] font-semibold">Manage Account</span> or 
                <span className="text-[#8D493A] font-semibold"> Manage/Create Posts</span> section which will help you to know better to post and access your space.
                </p>
            </div>
        </div>
    )
}

export default GettingStarted;