import { useSelector } from "react-redux"
import RenderDraftPosts from "./RenderDraftPosts";
import { useEffect, useState } from "react";
import { getAdminPostDetails, getUserPosts } from "../../../../services/operations/blogDetailsAPI";
import EmptyLoader from "../../../EmptyLoader";
import { Link } from "react-router-dom";

export default function Draft() {

    const {user} = useSelector((state) => state.profile);
    const {token} = useSelector((state) => state.auth);
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const fetchDraftPosts = async () => {
            try{
                let response;
                if(user.accountType === "Admin"){
                    response = await getAdminPostDetails(token);
                } else {
                    response = await getUserPosts(token);
                }

                if(response){
                    setPosts(response);
                }
            }
            catch(error){
                console.log(error);
            }
        }

        fetchDraftPosts();
    },[]);

    const draftPosts = posts.filter((post) => post.status === "Draft");
    // console.log("DRAFT POSTS", draftPosts);

    return (
        <div className="py-8 px-8 lg:px-[6rem] lg:py-[5rem] overflow-y-auto bg-white min-h-[785px]">
            <h1 className="text-4xl  font-bold overflow-hidden">Drafts</h1>
            {
                draftPosts?.length > 0 && 
                    <p className="font-medium text-sm mt-5">{draftPosts?.length} Posts in Draft</p>
            }

            {
                draftPosts?.length > 0 ? (
                    <div>
                        {/* Render Draft Posts */}
                        <RenderDraftPosts drafts={draftPosts}/>
                        {/* Isko abhi baad me dekhenge <RenderTotalItems /> */}
                    </div>
                )
                : (
                    <div className="flex flex-col gap-4 items-center justify-center mt-[10rem]">
                        <EmptyLoader />
                        <h1>Your draft is currently empty</h1>
                        <Link to={`../dashboard/create-post`} className="bg-[#8D493A] hover:bg-[#75392c] transition-all duration-300
                        px-6 py-2 rounded-lg text-white font-medium">
                            Create New Post
                        </Link>
                    </div>
                )
            }

        </div>
    )
}