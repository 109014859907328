import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import { getFullDetailsOfPostToView } from "../../../services/operations/postDetailsAPI";
import { useParams } from "react-router-dom";
import { IoMdHeart } from "react-icons/io";
import { IoMdHeartEmpty } from "react-icons/io";
import { useSelector } from "react-redux";
import { likePost, unlikePost } from "../../../services/operations/postDetailsAPI";

const LikeButton = ({postId}) => {
    const navigate = useNavigate();
    const [liked, setLiked] = useState(false);
    const [likeCount, setLikeCount] = useState(0);
    const {user} = useSelector((state) => state.profile);
    const {token} = useSelector((state) => state.auth);
    const [isAnimating, setIsAnimating] = useState(false);

    useEffect(() => {
        //fetch the current like status and count from the server
        
        const fetchLikeStatus = async () => {
            try{
                const response = await getFullDetailsOfPostToView(postId);
                setLikeCount(response.likes?.length);

                //check for user like
                const userHasLiked = response.likes?.some(like => like.user === user._id);
                
                if(userHasLiked){
                    setLiked(true);
                }
                else{
                    setLiked(false);
                }
            }
            catch(error){
                console.log("ERROR CALLING API", error);
            }
        }
        fetchLikeStatus();
    },[postId])

    const handleLike = async () => {
        try{
            if(!token){
                navigate("/login");
            }

            if(liked){
                //unlike
                const result = await unlikePost(postId, token);
                if(result){
                    setLikeCount(prev => prev - 1);
                    setLiked(false);
                }
            } else {
                //like
                const res = await likePost(postId, token);
                if(res){
                    
                    setIsAnimating(true);
                    setLikeCount(prev => prev + 1);
                    setLiked(true);

                    // Remove the animation class after it completes
                    setTimeout(() => setIsAnimating(false), 2000); // Match this duration with the animation time
                }
            } 
        } 
        
        catch(error){
            console.log("Error liking/unliking post", error);
        }
    }


    return(
        <div className="flex items-center gap-2 p-2">
            <button className={`p-2 rounded-lg ${isAnimating && liked ? 'animate-bounce': ''}`} onClick={handleLike}>
                {liked ? <IoMdHeart fontSize={26} className="text-red-500 text-[4rem]" /> : <IoMdHeartEmpty fontSize={26} className='text-red-500 text-[4rem]'/>}
            </button>
            <span className='text-[2rem]'>{likeCount} {likeCount === 1 ? 'Like' : 'Likes'}</span>
        </div>
    )
}

export default LikeButton;