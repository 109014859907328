import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    step: 1,
    post: null,
    editPost: false,
    pinnedPostIds:[],
};

const postSlice = createSlice({
    name:"post",
    initialState,
    reducers:{
        setStep:(state,action) => {
            state.step = action.payload;
        },
        setPost:(state, action) => {
            //console.log("Updating Post State", action.payload);
            state.post = action.payload;
        },
        setEditPost:(state, action) => {
            state.editPost = action.payload;
        },
        resetPostState:(state) => {
            state.step = 1;
            state.post = null;
            state.editPost = false;
        },
        setPinnedPostIds:(state, action) => {
            state.pinnedPostIds = action.payload;
        },
        pinPostSuccess:(state, action) => {
           state.pinnedPostIds.push(action.payload);
        },
        unpinPostSuccess:(state, action) => {
            state.pinnedPostIds = state.pinnedPostIds.filter(id => id !== action.payload);
        }
    }
});

export const {setStep, setPost, setEditPost, resetPostState, setPinnedPostIds, pinPostSuccess, unpinPostSuccess} = postSlice.actions;
export default postSlice.reducer;