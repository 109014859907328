import { useForm } from 'react-hook-form';
import { useState } from 'react';
import React, { useEffect} from "react";
import { deleteComment, getFullDetailsOfPostToView } from "../../../services/operations/postDetailsAPI";
import EmptyLoader from '../../EmptyLoader';
import { useSelector } from 'react-redux';
import { createComment, getAllComments } from '../../../services/operations/postDetailsAPI';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../../services/formatDate';
import { MdOutlineDeleteOutline } from "react-icons/md";
import { FaUser } from "react-icons/fa";


const CommentSection = ({postId}) => {
    const navigate = useNavigate();
    const [comments, setComments] = useState([]);
    const [commentCount, setCommentCount] = useState(0);
    const [postData, setPostData] = useState();
    const {token} = useSelector((state) => state.auth);
    const {user} = useSelector((state) => state.profile);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isSubmitSuccessful },
    } = useForm();

    useEffect(() => {
        //fetch comments from the server
        const fetchComments = async () => {
            try{
                const response = await getAllComments(postId);
                
                const res = await getFullDetailsOfPostToView(postId);
                setPostData(res);
                
                setComments(response?.comments);
                setCommentCount(response?.comments?.length);
            }
            catch(error){
                console.log("ERROR CALLING API", error);
            }
        }
        fetchComments();
    },[postId]);

    const handleCommentSubmit = async (data) => {
        //console.log("DATA", data)
        if(!token){
            navigate("/login");
            return;
        }

        try{
            const response = await createComment(postId, token, {
                comment: data.comment
            });

            if(response){
                setComments(response, [...comments]);
                setCommentCount(prevCount => prevCount + 1);
                reset({comment: ""});
            }
        }
        catch(error){
            console.log("ERROR CALLING API", error);
        }
    }

    const handleDelete = async (commentId) => {
        if(!token){
            navigate("/login");
        }

        try{
            const response = await deleteComment(postId, token, commentId);
            if(response){
                setComments(comments.filter(comment => comment._id !== commentId));
                setCommentCount(prevCount => prevCount - 1);
            }

        }
        catch(error){
            console.log("ERROR CALLING API", error);
        }
    }

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset({
                comment:""
            });
        }
    }, [isSubmitSuccessful, reset]);


    return(
        <div className='space-y-3 text-sm xl:text-base'>
            <h1 className='px-2'>{commentCount === 1 ? 'Comment' : 'Comments'} {`(${commentCount})`}</h1>
            <div className='relative rounded-lg'>
                <form className='p-2 flex flex-col gap-4' onSubmit={handleSubmit(handleCommentSubmit)}>
                    <label className="absolute right-6 top-4 z-10">
                        {
                            user?.image?.url ? (<img src={user?.image?.url} alt={user?.firstName} className='w-[25px] h-[25px] xl:w-[45px] xl:h-[45px] rounded-md xl:rounded-xl border border-slate-400'/>):
                            (
                                <>
                                    <FaUser className='w-[25px] h-[25px] xl:w-[45px] xl:h-[45px]'/>
                                </>
                            )
                        }
                    </label>
                            <textarea
                                required
                                type="text"
                                name="comment"
                                {...register("comment", {required:true})}
                                placeholder="Enter your comment"
                                className="text-black bg-slate-200 p-2 rounded-lg shadow-md w-full h-[40px] xl:h-[60px] 
                                focus:outline-none resize-none pr-14"
                            />
                            {errors.comment && <span className='text-red-500'>This field is required</span>}

                    <button type='submit' className='px-4 py-2 bg-blue-500 text-white font-semibold hover:bg-blue-600 transition-all duration-300 w-fit rounded-lg'>Post</button>
                </form>
            </div>

            <div className='flex flex-col gap-4 py-4'>
                {
                    comments.map(comment => {
                        //console.log("COMMENT", comment);
                        return(
                            
                            <div className='flex gap-3 bg-white shadow-lg w-full border-2 border-slate-300 rounded-xl p-5' 
                                key={comment._id}>
                                <div className='w-fit'>
                                    <img src={comment?.user?.image?.url} alt={comment?.user?.firstName} className='w-[40px] h-[40px] sm:w-[50px] sm:h-[50px] aspect-square object-cover
                                    border-2 border-slate-300 rounded-xl'/> 
                                </div>
                                <div className='w-11/12 text-[10px] sm:text-sm flex flex-col'>
                                    <div className='flex justify-between items-center'>
                                        <p className='font-semibold capitalize'>{comment?.user?.firstName} {comment?.user?.lastName}</p>
                                        <p className='text-[10px] sm:text-sm text-slate-400'>{formatDate(comment?.createdAt)}</p>
                                    </div>
                                    <p className='mt-2 text-slate-500'>{comment?.body}</p>
                                    {
                                        token && comment?.user?._id === user?._id || token && user?._id === postData?.user?._id ? (
                                            <button onClick={() => handleDelete(comment._id)} 
                                            className='p-2 text-white bg-red-600 mt-3 rounded-full self-end'>
                                                <MdOutlineDeleteOutline/>
                                            </button>
                                        ) : ""
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div className='flex flex-col gap-3 items-center'>
                {
                    commentCount === 0 ? (
                        <>
                            <div><EmptyLoader /></div>
                            <span>No comments Yet</span>
                        </>
                    ) : ""
                }  
            </div>
        </div>
    )
};

export default CommentSection;