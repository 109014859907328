import React from "react";
import info1 from "../../../../assets/guide/info1.png";
import info2 from "../../../../assets/guide/info2.png";
import info3 from "../../../../assets/guide/info3.png";
import info4 from "../../../../assets/guide/info4.png";
import info5 from "../../../../assets/guide/info5.png";
import info6 from "../../../../assets/guide/info6.png";
import { useNavigate } from "react-router-dom";

const ManagePosts = () => {
    const navigate = useNavigate();
    return(
        <div className="w-full flex flex-col gap-10 h-fit px-1 lg:px-16">
            <h1 className="text-center text-[3rem] font-bold text-[#8D493A]">Create and Manage Posts</h1>

            <h1 className="text-center text-4xl uppercase font-semibold overflow-hidden">Creating Post</h1>

            <p className="leading-10">
                Creating a post on your blogging platform is a straightforward process, designed to empower users to share their thoughts, stories, and knowledge with others. 
                Users can go to the dashboard and click on Create Post Button on the left to create the post. Users are encouraged to add relevant tags to their post, which will make it easier for others to discover through 
                searches or category browsing. There’s also a preview option, allowing users to see how their post will appear before it goes live, ensuring that every detail 
                is just right. This section encourages users to craft engaging and visually appealing content, and provides them with guidance on best practices for reaching 
                a wider audience.
            </p>

            <div className="bg-[#8D493A] rounded-xl p-3 lg:p-10 text-white flex flex-col gap-4">
                <p className="text-xl">Creating a post is a 3 step process:</p>
                {/* Section 1 */}
                <div className="p-6 bg-white rounded-lg text-[#8D493A] flex w-fit font-semibold text-center">
                    1. Post Information Form     
                </div>
                <div className="w-full flex flex-col items-center">
                    <img src={info1} alt="info1image" className="w-full lg:w-[70%]"/>
                </div>
                <ul className="rounded-lg space-y-2 p-5 bg-white text-[#8D493A]"> 
                  <li className="flex items-center gap-5"><p className="bg-[#8D493A] py-3 text-center w-[50px] px-4 text-white">1</p>Enter the necessary Details for the Post</li>
                  <li className="flex items-center gap-5"><p className="bg-[#8D493A] py-3 text-center w-[50px] px-4 text-white">2</p>Upload the image or Display Picture</li>
                  <li className="flex items-center gap-5"><p className="bg-[#8D493A] py-3 text-center w-[50px] px-4 text-white">3</p>Select category and Tags</li>
                  <li className="flex items-center gap-5"><p className="bg-[#8D493A] py-3 text-center w-[50px] px-4 text-white">4</p>Click on save to Save your changes.</li>
                </ul>
                <h1 className="text-2xl uppercase font-semibold overflow-hidden">Categories and Tags</h1>
                <p className="leading-10 text-lg">The use of categories and tags helps users organize their content and makes it easier for readers to find relevant posts. When creating a post, 
                    users can select an appropriate category that reflects the main theme of their content, such as technology, lifestyle, or health. Tags, on the 
                    other hand, are more specific and can represent subtopics or key points discussed in the post. This dual organization system enhances the discoverability 
                    of posts, as readers can browse categories or search for specific tags to find content that matches their interests. By organizing their posts with categories 
                    and tags, users contribute to a well-structured platform that benefits both authors and readers.
                    <br/>
                    <b>There are various categories to select from such as Self-Improvement, Healthy Lifestyle, Fashion, etc.</b>  
                </p>
                <p className="font-bold leading-10 text-lg">Note: As soon as you have clicked on Save button, the Post will be created and sent to drafts untill and unless you publicly publish it.</p>

                {/* Section 2 */}
                <div className="p-6 bg-white rounded-lg text-[#8D493A] flex w-fit font-semibold text-center">
                    2. Post Builder Form    
                </div>
                <div className="w-full flex flex-col items-center">
                    <img src={info2} alt="info1image" className="w-full lg:w-[70%]"/>
                </div>
                <ul className="rounded-lg space-y-2 p-5 bg-white text-[#8D493A]"> 
                  <li className="flex items-center gap-5"><p className="bg-[#8D493A] py-3 text-center w-[50px] px-4 text-white">1</p>Enter the Name of the Major Section.</li>
                  <li className="flex items-center gap-5"><p className="bg-[#8D493A] py-3 text-center w-[50px] px-4 text-white">2</p>Click on create Section.</li>
                  <li className="flex items-center gap-5"><p className="bg-[#8D493A] py-3 text-center w-[50px] px-4 text-white">3</p>A new section will be added</li>
                </ul>
                <h1 className="text-2xl uppercase font-semibold overflow-hidden">Sub-sections</h1>
                <p className="leading-10 text-lg">
                    Each section must consist of a sub-section determining the content of the section. It consists of image, title and actual content of
                    the post you need to create. Below is the image of how it will look after section creation.
                    <br/>
                    <div className="w-full flex flex-col items-center my-5">
                        <img src={info3} alt="info1image" className="w-full lg:w-[70%]"/>
                    </div>
                    <b>There can be as many sections and sub-sections created but we insist to keep the content thorough to the reader and up to date</b>  
                </p>
                <p className="font-bold leading-10 text-lg">Note: If you are using any photo which needs the attribution of the creator or photographer please mention it in the
                    description of the sub-section.
                </p>
                <h1 className="text-2xl uppercase font-semibold overflow-hidden">Creating a sub-section:</h1>
                <p className="leading-10 text-lg">
                    Each section must consist of a sub-section determining the content of the section. It consists of image, title and actual content of
                    the post you need to create. Below is the image of sub-section creation window.
                    <br/>
                    <div className="w-full flex flex-col items-center my-5">
                        <img src={info4} alt="info1image" className="w-full lg:w-[70%]"/>
                    </div>
                </p>
                <ul className="rounded-lg space-y-2 p-5 bg-white text-[#8D493A]"> 
                    <li className="flex items-center gap-5"><p className="bg-[#8D493A] py-3 text-center w-[50px] px-4 text-white">1</p>Click on Add Sub-section and select the image on the window.</li>
                    <li className="flex items-center gap-5"><p className="bg-[#8D493A] py-3 text-center w-[50px] px-4 text-white">2</p>Enter the title of the Sub-Section.</li>
                    <li className="flex items-center gap-5"><p className="bg-[#8D493A] py-3 text-center w-[50px] px-4 text-white">3</p>Write down the description of your sub-section.</li>
                    <li className="flex items-center gap-5"><p className="bg-[#8D493A] py-3 text-center w-[50px] px-4 text-white">4</p>Click on Save to create the sub-section.</li>
                    <li className="flex items-center gap-5"><p className="bg-[#8D493A] py-3 text-center w-[50px] px-4 text-white">5</p>If all your sections and sub-sections are created, Click on Next.</li>
                </ul>
                <p className="leading-10 text-lg">
                    As soon as the sub-section is created, it is added to the Major Section. You can either view/edit the sub-section or delete it as you wish. Below is the image of
                    window lookalike:
                    <br/>
                    <div className="w-full flex flex-col items-center my-5">
                        <img src={info5} alt="info1image" className="w-full lg:w-[70%]"/>
                    </div>
                </p>
                <div className="p-6 bg-white rounded-lg text-[#8D493A] flex w-fit font-semibold text-center">
                    3. Publish Post    
                </div>
                <div className="w-full flex flex-col items-center">
                    <img src={info6} alt="info1image" className="w-full lg:w-[70%]"/>
                </div>
                <p className="leading-10 text-lg">
                    The last and final step of Post creation is to determine whether you want the post to be publicly available for other users to read or you can just save it to drafts
                    for further modification if needed..
                </p>
                <ul className="rounded-lg space-y-2 p-5 bg-white text-[#8D493A]"> 
                  <li className="flex items-center gap-5"><p className="bg-[#8D493A] py-3 text-center w-[50px] px-4 text-white">1</p>Check the box if you want to publish the post.</li>
                  <li className="flex items-center gap-5"><p className="bg-[#8D493A] py-3 text-center w-[50px] px-4 text-white">2</p>Click on Save Changes</li>
                </ul>
            </div>

            <h1 className="text-center text-4xl uppercase font-semibold overflow-hidden">Managing Posts</h1>

            <p className="leading-10">
                Once a post is published, users have several options to manage it and ensure that it remains up-to-date. The platform’s <span className="underline text-[#8D493A] font-semibold cursor-pointer" onClick={() => navigate('/dashboard/my-posts')}>My Posts</span> dashboard gives users a 
                comprehensive view of all their content, displaying each post's title, date, category, and engagement metrics, such as the number of likes and comments. 
                Users can edit a published post to update information, correct errors, or improve the content’s clarity and relevance. This feature is especially useful 
                for users who want to keep their content fresh and accurate over time. There’s also an option to delete the post if the content is no longer serving a purpose, 
                allowing users to remove it. Managing published posts is designed to be user-friendly, so users can maintain control over their content and engage meaningfully with their audience.
            </p>


        </div>
    )
}

export default ManagePosts;