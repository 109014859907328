import React from "react";
import { formatDate } from "../../../../services/formatDate";
import { MdDelete } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { AiFillEdit } from "react-icons/ai";
const RenderDraftPosts = ({drafts}) => {

    const navigate = useNavigate();
    
    const truncateText = (text = '', wordLimit) => {
        const words = text.split(' ');
        return words.length > wordLimit ? `${words.slice(0, wordLimit).join(' ')}...` : text;
    };

    return(
        <div className="w-full mt-10">
            <div className="w-full flex flex-col gap-4">
                {
                    drafts?.map((post, index) => {
                        //console.log("DRAFTS", drafts);
                        return (
                            <div className="w-full min-h-[150px] flex justify-between items-center gap-4 p-4 px-7 bg-white shadow-md border-2 rounded-xl" key={index}>
                                <div className="flex gap-4 items-center">
                                    <img src={post?.thumbnail?.url} alt={post?.postName} 
                                    className="w-[100px] rounded-lg aspect-square object-cover"/>

                                    <div className="flex flex-col gap-2">
                                        <p className="text-xl font-bold">{post?.postName}</p>
                                        <p className="text-sm text-slate-300">{truncateText(post?.postDescription, 20)}</p>
                                        <p className="text-sm text-slate-400">{formatDate(post?.createdAt)}</p>
                                    </div>
                                </div>

                                <div className="flex gap-4">

                                    <button onClick={() => {
                                        navigate(`/dashboard/edit-post/${post?._id}`)
                                    }}
                                    className="text-white bg-blue-500 hover:bg-blue-600 p-2 rounded-xl
                                    transition-all duration-300 h-fit">
                                        <AiFillEdit fontSize={24}/>
                                    </button>

                                </div>
                            </div>
                    )}) 
                }
            </div>
        </div>
    )
};

export default RenderDraftPosts;