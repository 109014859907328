import React from "react";
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import PropTypes from 'prop-types'; 
import { Link } from "react-router-dom";
import comingSoon from "../../../assets/cs.jpg";
// Import PropTypes

const PostSlider = ({ posts = [] }) => { 
  // Provide default value for posts
  // console.log("POSTS IN SLIDER", posts);

  const adminPosts = posts.filter(post => post.isAdminPost === true);
  const AutoplaySlider = withAutoplay(AwesomeSlider);

  const truncateText = (text = '', wordLimit) => {
    const words = text.split(' ');
    return words.length > wordLimit ? `${words.slice(0, wordLimit).join(' ')}...` : text;
  };

  const slider = (
    <AutoplaySlider
      play={true}
      cancelOnInteraction={false}
      interval={3000}
      className={"overflow-hidden h-[250px] sm:h-full"}
      buttons={false}
    >
      {
        adminPosts.length > 0 ? (
          adminPosts.map((post, index) => {

            // Parse and format the createdAt date
            const date = new Date(post.createdAt);
            const options = { day: 'numeric', month: 'short', year:'numeric'  };
            const formattedDate = date.toLocaleDateString('en-US', options);

            return(
              
                <div key={index} className="overflow-hidden relative">
                  <Link to={`/posts/${post._id}`}>
                    <img src={post?.thumbnail?.url} alt={post?.postName} 
                      className="w-full object-cover overflow-hidden"/>
                    
                    <div className="absolute w-fit text-white top-5 right-2 lg:top-7 lg:right-3 xl:top-10 xl:right-5 bg-black/50 backdrop-blur-sm p-2 rounded-lg shadow-md text-right">
                              {/* Replace this text with your content */}
                              <p className="font-bold text-[10px] lg:text-sm xl:text-xl 2xl:text-2xl">
                                  {post?.postName}
                              </p>
                              <p className="text-[7px] lg:text-[10px] xl:text-base 2xl:text-lg">{truncateText(post?.postDescription, 9)}</p>
                              <p className="text-[7px] lg:text-[10px] xl:text-base 2xl:text-lg">{formattedDate}</p>
                              <p className="font-bold text-[7px] lg:text-[10px] xl:text-base 2xl:text-lg">By Admin: {post?.user?.firstName} {post?.user?.lastName}</p>
                    </div>
                  </Link>
                </div>
              
          )})
        ) 
        : 
        (<div className="relative">
            <img src={comingSoon} alt="Post Unavailable" className="w-full object-cover"/>
            <div className="absolute text-[10px] lg:text-base p-1 sm:p-3 lg:p-5 rounded-lg bottom-3 sm:bottom-10 lg:bottom-16 lg:left-0 lg:right-0 bg-white/20 text-white w-fit ml-5 lg:ml-10 z-20 backdrop-blur-sm">
              <p>Stay Tuned for future posts!</p>
            </div>
        </div>)
      }
    </AutoplaySlider>
  ); 

  return (
    <div className="overflow-hidden">
      {slider}
    </div>  
  )
};

// Define prop types
PostSlider.propTypes = {
  posts: PropTypes.array
};

export default PostSlider;
