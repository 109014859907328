import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { storiesEndpoints } from "../../../services/apis";
import { apiConnector } from "../../../services/apiconnector";
import EmptyLoader from "../../EmptyLoader";
import { IoMdHeart } from "react-icons/io";
import ConfirmationModal from "../../common/ConfirmationModal";
import { MdOutlineDelete } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import IconBtn from "../../common/IconBtn";
import { FaPlus } from "react-icons/fa";
import Error from "../../../pages/Error";
import { logout } from "../../../services/operations/authAPI";

const UploadedStories = () => {

    const {token} = useSelector((state) => state.auth);
    const {user} = useSelector((state) => state.profile);
    const [stories, setStories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [confirmationModal, setConfirmationModal] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { GET_ALL_ADMIN_STORIES, DELETE_STORY_API } = storiesEndpoints;

    //api call
    useEffect(() => {
        const toastId = toast.loading("Loading...");
        
        const getAllAdminPosts = async () => {
            try{
                if(user?.accountType === "User"){
                    <Error />
                    dispatch(logout(navigate));
                }

                const response = await apiConnector("GET", GET_ALL_ADMIN_STORIES, null, {
                    Authorization: `Bearer ${token}`
                });
    
                //console.log("RESPONSE OF ADMIN STORIES API", response);
    
                if(!response.data.success){
                    throw new Error(response.data.message);
                }
                setStories(response.data.data);
            }
            catch(error){
                toast.dismiss(toastId);
                toast.error("Something went wrong");
                //console.log("ERROR FETCHING STORIES", error);
            }
            toast.dismiss(toastId);
        }
        
        getAllAdminPosts();
    },[]);

    const handleStoryDelete = async (storyId) => {
        setLoading(true);

        try{
            const response = await apiConnector("POST", DELETE_STORY_API, {storyId}, {
                Authorization: `Bearer ${token}`
            });

            //console.log("STORY DELETED");

            if(!response.data.success){
                throw new Error(response.data.message);
            }
            toast.success("Story Deleted Successfully");
            setLoading(false);
            setConfirmationModal(null);
            window.location.reload();
        }
        catch(error){
            console.log("ERROR DELETING STORY", error);
            toast.error("Something went wrong");
        }
    }

    return (
        <div className="px-8 py-8 lg:px-[6rem] lg:py-[5rem] overflow-y-auto bg-white min-h-[790px] space-y-10">
            <div className="flex justify-between">
                <h1 className='text-4xl font-bold overflow-hidden'>My Stories</h1>
                {
                    user.accountType === "Admin" && 
                    <IconBtn 
                    text={<>Create Story <FaPlus fontSize={10} /></>}
                    customClasses={"hidden sm:flex gap-2 items-center text-md text-white bg-blue-600 hover:bg-blue-700 transition-all duration-300 px-4 py-2 rounded-lg"}
                    onClick={() => navigate("/dashboard/create-story")}
                    //ADD ICON
                    />
                }
                {
                    user.accountType === "Admin" &&
                    <IconBtn 
                        text={<><FaPlus fontSize={10} /></>}
                        customClasses={"flex sm:hidden gap-2 items-center text-md text-white bg-blue-600 hover:bg-blue-700 transition-all duration-300 px-4 py-4 rounded-lg"}
                        onClick={() => navigate("/dashboard/create-story")}
                    />
                }
            </div>
            

            <div className="w-full mx-auto flex justify-center items-center">

                {/* Stories */}
                
                {
                    user.accountType === "Admin" && stories.length > 0 ? 
                    (
                        <div className="flex flex-col sm:flex-row items-center sm:items-start gap-5 flex-wrap w-full mx-auto">
                            {
                                stories?.map((story, index) => {
                                    return(
                                        
                                            <div className="w-[90%] sm:w-[40%] lg:w-[30%] rounded-xl flex flex-col gap-4" key={index}>
                                                <Link to={`/stories/${story?._id}`}>
                                                    <img src={story?.firstImage?.url} alt={story?.storyName} loading="lazy"
                                                    className="w-full aspect-square object-cover rounded-xl"/>
                                                </Link>
                                                <div className="flex justify-between items-center">
                                                    <p className="font-light">{story?.storyName}</p>
                                                    <div className="flex gap-3">
                                                        <p className="flex gap-2 items-center"><IoMdHeart className="text-red-500"/>{story?.storyLikes?.length}</p>
                                                        <button
                                                            className="text-white p-2 rounded-full bg-red-500"
                                                            disabled={loading}
                                                            onClick={() => {
                                                                setConfirmationModal({
                                                                    text1:"Do you want to delete this story?",
                                                                    text2:"All the data related to this story will be deleted",
                                                                    btn1Text:"Delete",
                                                                    btn2Text:"Cancel",
                                                                    btn1Handler: !loading ? () => handleStoryDelete(story._id): () => {},
                                                                    btn2Handler:!loading ? () => setConfirmationModal(null): () => {},
                                                                })
                                                            }}
                                                        >
                                                            <MdOutlineDelete />

                                                        </button>
                                                    </div>
                                                </div>

                                                {
                                                    confirmationModal && <ConfirmationModal modalData={confirmationModal} />
                                                }              
                                                
                                            </div>
                                    )
                                })
                            }
                        </div>
                    ) : 
                    
                    (
                    <div>
                        <EmptyLoader />
                    </div>)
                }

            </div>
        </div>
    )
};

export default UploadedStories;