import React, { useState, useEffect } from "react";

const ChipInput = ({ label, name, placeholder, register, errors, setValue, getValues }) => {
    const [inputValue, setInputValue] = useState("");
    const [tags, setTags] = useState(getValues(name) || []);

    useEffect(() => {
        setValue(name, tags, { shouldValidate: true }); // Set value in react-hook-form
    }, []);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === ',') {
            e.preventDefault();
            addTag(inputValue.trim());
        }
    };

    const addTag = (value) => {
        if (value && !tags.includes(value)) {
            const newTags = [...tags, value];
            setTags(newTags);
            setValue(name, newTags, { shouldValidate: true }); // Set value in react-hook-form
            setInputValue("");
        }
    };

    const removeTag = (indexToRemove) => {
        const newTags = tags.filter((_, index) => index !== indexToRemove);
        setTags(newTags);
        setValue(name, newTags, { shouldValidate: true }); // Set value in react-hook-form
    };

    return (
        <div className="mb-4">
            <label htmlFor={name} className="block font-bold mb-2">
                {label} <span className="text-blue-500">*</span>
            </label>
            <input
                type="text"
                id={name}
                placeholder={placeholder}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={handleKeyDown}
                className="w-full rounded-md p-2 focus:outline-none bg-gray-200 text-black"
            />
            <input
                type="hidden"
                value={JSON.stringify(tags)}
                {...register(name, { required: true })}
            />
            {errors[name] && <span className="text-red-500">This field is required</span>}
            <div className="flex flex-wrap gap-2 mt-2">
                {tags.map((tag, index) => (
                    <div key={index} className="bg-blue-200 text-blue-800 rounded-full px-4 py-1 flex items-center gap-2">
                        <span>{tag}</span>
                        <button
                            type="button"
                            onClick={() => removeTag(index)}
                            className="text-red-500 font-bold"
                        >
                            &times;
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ChipInput;
