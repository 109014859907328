import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";

const initialState = {
    draft: localStorage.getItem("draft")
        ? JSON.parse(localStorage.getItem("draft"))
        : [],

    totalItems: localStorage.getItem("totalItems")
        ? JSON.parse(localStorage.getItem("totalItems"))
        : 0
};

const draftSlice = createSlice({
    name: "draft",
    initialState,
    reducers: {
        setTotalItems(state, action) {
            state.totalItems = action.payload;
            localStorage.setItem("totalItems", JSON.stringify(state.totalItems));
        },
        setDrafts: (state, action) => {
            state.draft = action.payload;
            state.totalItems = action.payload.length;
            localStorage.setItem("draft", JSON.stringify(state.draft));
            localStorage.setItem("totalItems", JSON.stringify(state.totalItems));  
        },
        addToDraft: (state, action) => {
            const post = action.payload;
            const index = state.draft.findIndex((item) => item._id === post._id);

            // if post is already in the draft
            if (index >= 0) {
                toast.error("Post already in draft");
                return;
            }

            // if the post is not in the draft then add it
            state.draft.push(post);
            state.totalItems += 1;
            toast.success("Post added to draft");

            // update localStorage
            localStorage.setItem("draft", JSON.stringify(state.draft));
            localStorage.setItem("totalItems", JSON.stringify(state.totalItems));
        },
        removeFromDraft: (state, action) => {
            state.draft = state.draft.filter((item) => item._id !== action.payload);
            state.totalItems = state.draft.length;

            // update localStorage
            localStorage.setItem("draft", JSON.stringify(state.draft));
            localStorage.setItem("totalItems", JSON.stringify(state.totalItems));
            toast.success("Post removed from draft");
        },
        resetDraft: (state) => {
            state.draft = [];
            state.totalItems = 0;

            // update localStorage
            localStorage.removeItem("draft");
            localStorage.removeItem("totalItems");
            //toast.success("Draft reset");
        }
    }
});

export const { addToDraft, removeFromDraft, resetDraft, setTotalItems, setDrafts } = draftSlice.actions;
export default draftSlice.reducer;
