import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ProfileEditBtn from "../../../common/ProfileEditBtn";
import { MdOpenInBrowser } from "react-icons/md";
import { LuFiles } from "react-icons/lu";
import { updateDisplayPicture } from "../../../../services/operations/profileAPI";

const ChangeProfilePicture = () =>{

    const {user} = useSelector((state) => state.profile);
    const {token} = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [error, setError] = useState('');

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];

        if(selectedFile && selectedFile.type.startsWith('image/')){
            setFile(selectedFile);
            //console.log("File", selectedFile);
            setFileName(selectedFile.name);
            setError('');
        } else {
            setFile(null);
            setFileName('');
            setError('Please choose a valid image file.');
        }
    };

    const handleUploadClick = async () => {
        if(!file){
            setError('Please choose a file.');
            return;
        }

        const formData = new FormData();
        formData.append('displayPicture', file);
        // console.log("FORM DATA", formData);
        // Log formData entries to verify its content
         for (let [key, value] of formData.entries()) {
            //console.log(key, value);
        }

        dispatch(updateDisplayPicture(formData, navigate, token));

        setFile(null);
        setFileName('');
    }
 
    return(
        <div className="rounded-3xl shadow-lg">

            {/* section 1 */}
            <div className="bg-white border gap-5 lg:gap-0 border-slate-200 p-10 rounded-3xl mt-10 shadow-lg
            flex flex-col-reverse lg:flex-row justify-between items-center">
                <div className="flex flex-col gap-10 justify-around items-center lg:items-start text-center lg:text-left">
                    <div className="flex flex-col gap-3">
                        <p className="text-5xl font-bold overflow-hidden">Profile Picture</p>
                        <p>Upload a new Profile Picture from below Browse button</p>
                    </div>
                    <div className="flex gap-4 items-center">
                        <input
                            type="file"
                            id="displayPicture"
                            accept="image/*"
                            onChange={handleFileChange}
                            style={{display:'none'}}
                        />
                        <ProfileEditBtn 
                        type="button"
                        text={<>Browse <LuFiles /></>}
                        onClick={() => document.getElementById('displayPicture').click()}
                        />

                        <button
                            type="button"
                            onClick={handleUploadClick}
                            className="flex gap-3 items-center bg-[#8D493A] py-3 px-4 rounded-xl text-white hover:bg-[#6e3528] transition-all duration-300">
                            <>Upload <MdOpenInBrowser /></>
                        </button>

                        {fileName && <span className="font-bold">{fileName}</span>}
                        {error && <span className="text-red-600 font-bold">{error}</span>}

                    </div>
                </div>

                {/* Profile Img */}
                <div className="w-full sm:w-[50%] lg:w-3/12">
                    <img src={user?.image?.url}
                    alt={`profile-${user?.firstName}`}
                    className="aspect-square w-full rounded-full object-cover"
                    />
                </div>
            </div>
        </div>
    )
};

export default ChangeProfilePicture;