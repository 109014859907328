// import { ACCOUNT_TYPE } from "../utils/constants"

export const ACCOUNT_TYPE = {
    admin: "Admin",
    user: "User",
};

export const sidebarLinks = [
    {
        id: 1,
        name: "My Profile",
        path: "/dashboard/my-profile",
        icon: "VscAccount",
    },
    {
        id: 2,
        name: "Dashboard",
        path: "/dashboard/admin",
        type: ACCOUNT_TYPE.admin,
        icon: "VscDashboard",
    },
    //check if there will be seperate paths for fetching admin posts and user posts seperately if not fetch in same path
    // {
    //     id: 3,
    //     name: "My Posts",
    //     path: "/dashboard/my-admin-posts",
    //     type: ACCOUNT_TYPE.admin,
    //     icon: "VscVm"
    // },
    {
        id:3,
        name: "My Posts",
        path: "/dashboard/my-posts",
        //type: ACCOUNT_TYPE.user,
        icon: "VscVm"
    },
    //check if there will be seperate paths for creating admin posts and user posts seperately if not create in same path
    {
        id: 5,
        name: "Create Post",
        path: "/dashboard/create-post",
        //type: ACCOUNT_TYPE.admin,
        icon: "VscAdd"
    },
    {
        id: 6,
        name: "My Stories",
        path: "/dashboard/my-stories",
        type: ACCOUNT_TYPE.admin,
        icon: "VscVm"
    }
    // {
    //     id: 6,
    //     name: "Create Post",
    //     path: "/dashboard/add-post",
    //     type: ACCOUNT_TYPE.user,
    //     icon: "VscAdd"
    // },
];