import React from "react";
import IconBtn from "./IconBtn";

const ConfirmationModal = ({ modalData }) => {
    return (
        <div className="fixed inset-0 px-5 sm:px-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-md shadow-lg z-50">
                <p className="text-4xl font-bold mb-4 overflow-hidden text-center">{modalData.text1}</p>
                <p className="text-md mb-6">{modalData.text2}</p>
                <div className="flex space-x-4 justify-center">
                    <IconBtn 
                        onClick={modalData?.btn1Handler}
                        text={modalData?.btn1Text}
                        customClasses="px-4 py-2 bg-red-500 text-white rounded-md"
                    />
                    <button 
                        onClick={modalData?.btn2Handler}
                        className="px-4 py-2 bg-gray-300 text-black rounded-md"
                    >
                        {modalData?.btn2Text}
                    </button>
                </div>
                {/* <p className="mt-6 text-center font-bold text-xl">{`You will be missed :)`}</p> */}
            </div>
        </div>
    );
};

export default ConfirmationModal;
