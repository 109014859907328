import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { ImArrowUpRight2 } from "react-icons/im";
import newlogo from '../../assets/mail/LOGO JPG.jpg';

const Footer = ({footer, logo, logotext}) => {

    const navigate = useNavigate();

    return(
            <div className="w-full h-auto relative flex justify-center items-center">
                    <img src={footer} alt="footer-img"
                    className="w-full opacity-80" />
                    <div className="w-11/12 h-[95%] absolute rounded-2xl gap-2 flex flex-col">
                        <div className="w-full h-[45%] text-center py-3 gap-2 sm:gap-5 flex flex-col items-center justify-center">
                            <h1 className="text-white font-semibold text-md sm:text-2xl lg:text-4xl xl:text-5xl 2xl:text-6xl overflow-hidden
                            xl:leading-normal 2xl:leading-normal">Ready to Experience the Open <br/> 
                            World of Blogging?</h1>
                            <div className="flex gap-5 text-sm lg:text-lg xl:text-xl">
                                <Link to={"/signup"} className="bg-white h-fit text-black font-semibold py-1 px-3 xl:py-3 xl:px-5 rounded-full hover:scale-90 transition-all duration-300">
                                
                                    Get Started
                                
                                </Link>
                                <Link to={"/about"} className="flex items-center h-fit gap-2 bg-black bg-opacity-70 px-3 py-1 xl:py-3 xl:px-5 rounded-full border-white
                                text-white hover:scale-90 transition-all duration-300">
                                    Learn More
                                    <ImArrowUpRight2/>
                                </Link>  
                            </div>
                            
                        </div>

                        <div className="w-full h-[55%] bg-white rounded-2xl overflow-hidden">
                            <div className="flex justify-between w-full p-3 px-4 xl:p-8 xl:px-10">
                                <div className="flex">
                                    <img src={newlogo} alt="logo" loading="lazy" className="w-[110px] h-[60px] sm:w-[200px] sm:h-[120px] xl:w-[270px] xl:h-[250px]
                                    aspect-video mb-16"/>
                                </div>

                                <div className="flex text-[8px] sm:text-lg gap-3 sm:gap-9 sm:p-5">
                                    {/* footer area */}
                                    <div>
                                        {/* <ul className="flex flex-col gap-2 text-gray-500">
                                            <li className="text-black font-semibold">What we do</li>
                                            <li>Features</li>
                                            <li>Security</li>
                                            <li>Business</li>
                                            <li>Services</li>
                                        </ul> */}
                                    </div>
                                    <div>
                                        <ul className="flex flex-col gap-2 text-gray-500 lg:pr-10">
                                            <li className="text-black font-semibold">About</li>
                                            <li onClick={() => navigate("/about")} className="cursor-pointer hover:translate-x-3 transition-all duration-300">About us</li>
                                            <li onClick={() => navigate("/contact")} className="cursor-pointer hover:translate-x-3 transition-all duration-300">Contact Us</li>
                                            <li onClick={() => navigate("/privacy")} className="cursor-pointer hover:translate-x-3 transition-all duration-300">Privacy</li>
                                        </ul>
                                    </div>
                                    {/* <div>
                                        <ul className="flex flex-col gap-2 text-gray-500">
                                            <li className="text-black font-semibold">Social Media</li>
                                            <li>Twitter</li>
                                            <li>Facebook</li>
                                            <li>Pinterest</li>
                                            <li>Instagram</li>
                                            <li>Youtube</li>
                                        </ul>
                                    </div> */}
                                </div>
                            </div>

                            {/* Large text */}
                            <div className="w-full hidden lg:block xl:block text-[10rem] xl:text-[15rem] -mt-8 xl:mt-3 h-auto overflow-hidden text-center">
                                Jeviknows
                            </div>
                        </div>  
                    </div>
                    
                    <div>

                    </div>

            </div>
    )
}

export default Footer;