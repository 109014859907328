import React, { useEffect, useState } from "react";
import { FaFire } from "react-icons/fa6";
import { IoMdHeart } from "react-icons/io";
import { apiConnector } from "../services/apiconnector";
import { storiesEndpoints } from "../services/apis";
import EmptyLoader from "../components/EmptyLoader";
import StorySlider from "../components/core/Stories/StorySlider";
import footer from '../assets/footer-bg.jpg';
import logo from '../assets/Logo Circle - PNG.png';
import logotext from '../assets/logo-text.png';
import Footer from "../components/common/Footer";
import {TypeAnimation} from 'react-type-animation';

const Stories = () => {

    const [stories, setStories] = useState([]);
    const [mostLikedStories, setMostLikedStories] = useState([]);

    const { GET_ALL_STORIES_API } = storiesEndpoints;
    
    useEffect(() => {
        try{
            const getAllStories = async () => {
                const response = await apiConnector("GET", GET_ALL_STORIES_API);
                //console.log("RESPONSE", response);
    
                setStories(response.data.data);
            };
    
            getAllStories();
        }
        catch(error){
            console.log("Error", error);
        }
    },[]);

    useEffect(() => {
        // Filter and sort most liked stories
        const filteredLikedStories = stories
            .filter((story) => story.storyLikes.length > 0) // Remove stories with 0 likes
            .sort((a, b) => b.storyLikes.length - a.storyLikes.length); // Sort in descending order

        setMostLikedStories(filteredLikedStories);
    }, [stories]);

    return(
        <>
            <div className="w-screen">

                <div className="max-w-[1710px] mt-10 mx-auto mb-10 flex flex-col gap-5">

                    <div className="space-y-5 px-10 lg:px-20 xl:px-20 2xl:px-20">
                        <h1 className="text-[2rem] sm:text-[3rem] lg:text-[5.5rem] xl:text-[5.5rem] 2xl:text-[5.5rem] font-bold text-center">
                        <TypeAnimation
                            sequence={[
                                // Same substring at the start will only be typed once, initially
                                'BEHIND THE SCENE',
                                2000,
                                'BEHIND THE ACTION',
                                2000,
                                'BEHIND THE ART',
                                2000,
                                'BEHIND THE CREATIVITY',
                                2000,
                            ]}
                            speed={300}
                            repeat={Infinity}
                            />
                        </h1>

                        <p className="font-semibold text-center leading-7 text-sm lg:text-lg xl:text-lg 2xl:text-lg">This section contains the creative artifacts explaining its significance and their vibrant colors enhancing beauty of the art that lies within it.</p>
                    </div>

                    <div className="isolate p-4 lg:p-10 xl:p-10 2xl:p-10 bg-[#8D493A]/60 backdrop-blur-sm rounded-xl w-11/12 sm:w-9/12 lg:w-8/12 xl:w-8/12 2xl:w-8/12  
                    mx-auto mt-10 flex flex-col gap-10">

                        <div className="w-full bg-[#F8EDE3] shadow-md rounded-2xl border-2 border-slate-200 h-full p-4 lg:px-10 xl:px-10 2xl:px-10 space-y-4">
                            <div className="py-4 flex gap-4 items-center text-xl rounded-2xl w-fit">
                                <FaFire className="text-[#FF6942]" fontSize={24}/>
                                <p>Recently Added</p>
                            </div>

                            <div className="w-fit rounded-xl flex flex-col mx-auto lg:flex-row xl:flex-row 2xl:flex-row">
                                {/* Stories will come here */}
                                {
                                    stories?.length == 0 ? (
                                    <div className="flex flex-col gap-3 items-center justify-center mx-auto">
                                        <EmptyLoader />
                                        <p className="text-2xl font-semibold">No Stories Yet!</p>
                                    </div>
                                    ) : 
                                    (
                                    <div className="rounded-xl w-fit mx-auto">
                                        <div className="">
                                            <StorySlider stories={stories}/>
                                        </div>
                                    </div>
                                    ) 
                                }
                            </div>
                        </div>

                        <div className="w-full bg-[#F8EDE3] shadow-md rounded-2xl border-2 border-slate-200 h-full p-4 lg:px-10 xl:px-10 2xl:px-10 space-y-4">
                            <div className="py-4 flex gap-4 items-center text-xl rounded-2xl w-fit">
                                <IoMdHeart className="text-red-500" fontSize={24}/>
                                <p>Most Liked</p>
                            </div>

                            <div className="w-fit rounded-xl mx-auto flex flex-col lg:flex-row xl:flex-row 2xl:flex-row">
                                {/* Stories will come here */}
                                {
                                    stories?.length == 0 ? (
                                    <div className="flex flex-col gap-3 items-center justify-center mx-auto">
                                        <EmptyLoader />
                                        <p className="text-2xl font-semibold">No Stories Yet!</p>
                                    </div>
                                    ) : 
                                    (
                                    <div className="rounded-xl w-fit mx-auto">
                                        <div className="">
                                            <StorySlider stories={mostLikedStories}/>
                                        </div>
                                    </div>
                                    ) 
                                }
                            </div>
                        </div>

                    </div>

                </div>
            
            {/* Footer */}
            <Footer footer={footer} logo={logo} logotext={logotext}/>
            </div>
        </>
    )
}

export default Stories;