import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FcLike } from "react-icons/fc";
import { FaRegComments } from "react-icons/fa";
import { TiPin, TiPinOutline } from "react-icons/ti";
import { pinPost, unpinPost } from "../../../../services/operations/postDetailsAPI"; 
import { pinPostSuccess, unpinPostSuccess } from "../../../../slices/postSlice";
import ConfirmationModal from "../../../common/ConfirmationModal";
import { getAdminPostDetails, getUserPosts } from "../../../../services/operations/blogDetailsAPI";
import { deletePost } from "../../../../services/operations/postDetailsAPI";
import { MdOutlineDelete } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { AiFillEdit } from "react-icons/ai";

export default function PostsCard({ posts, setPosts}) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.profile);
    const { token } = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false);
    const [confirmationModal, setConfirmationModal] = useState(null);

    const { pinnedPostIds } = useSelector((state) => state.post);
    const pinnedIds = pinnedPostIds.map(pinnedPost => pinnedPost._id);

    const handlePin = async (postId) => {
        const userId = user._id;
        setLoading(true);
        try{
            if(pinnedIds.includes(postId)){
                dispatch(unpinPost(userId, postId, token));
                dispatch(unpinPostSuccess(postId));
            } else {
                dispatch(pinPost(userId, postId, token))
                dispatch(pinPostSuccess(postId));
            }
        }
        catch(error){
            console.log(error);
        }
        finally{
            setLoading(false);
        }
    }

    const handlePostDelete = async (postId) => {
        setLoading(true);

        await deletePost({postId:postId}, token);

        try {
            let response;
            if (user.accountType === "Admin") {
                response = await getAdminPostDetails(token);
            } else {
                response = await getUserPosts(token);
            }
            if(response){
                setPosts(response);
            }
            setConfirmationModal(null);
            setLoading(false);
            
        } catch (error) {
            console.log("Error fetching posts", error);
        }
    }

    return (
        <div className="w-full mt-10 rounded-xl flex justify-center">
            {
                posts.length === 0 ? (<div>No Posts Available</div>)
                    :
                    (
                        <div className="w-full flex gap-4 flex-wrap flex-col items-center sm:flex-row">
                            {
                                posts.filter(post => post !== undefined).map(post => {
                                    //console.log("Display POST", post);
                                    //console.log("POST TAG", post.tag);

                                    // Parse and format the createdAt date
                                    const date = new Date(post.createdAt);
                                    const options = { month: 'short', day: 'numeric' };
                                    const formattedDate = date.toLocaleDateString('en-US', options);

                                    // Check if tag is a JSON string and parse it
                                    let tags = [];
                                    if (post?.tag?.length > 0) {
                                    try {
                                        tags = JSON.parse(post.tag[0]);
                                    } catch (error) {
                                        console.error("Failed to parse tags:", error);
                                    }
                                    }

                                    return (
                                        <div key={post._id} className="w-[90%] sm:w-[40%] lg:w-[30%] rounded-xl flex flex-col shadow-md mb-6 relative">
                                            <Link to={`/posts/${post._id}`}>
                                                <img src={post?.thumbnail?.url} alt={post?.postName} loading="lazy"
                                                className="w-[full] aspect-square object-cover"/>
                                            </Link>
                                            

                                            {/* <div className="absolute bg-black/50 w-fit top-5 left-5 backdrop-blur-sm rounded-full px-3 text-white">
                                                <div className="flex gap-3 p-1">
                                                    <img src={post?.user?.image?.url} alt={post?.user?.firstName} 
                                                    className="w-[30px] object-cover aspect-square rounded-full"/>

                                                    <div className="flex flex-col text-sm">
                                                        <p>{post?.user?.firstName} {post?.user?.lastName}</p>
                                                        <p>{formattedDate}</p>
                                                    </div>
                                                </div>
                                            </div> */}

                                            <div className="absolute bg-white w-fit top-5 right-5 rounded-full p-3 text-slate-400">
                                                <div className='cursor-pointer rounded-full'
                                                    onClick={() => handlePin(post._id)}
                                                    disabled={loading}
                                                    >
                                                    {pinnedIds.includes(post._id) ? <TiPin /> : <TiPinOutline />}
                                                </div> 
                                            </div>

                                            {
                                                post?.status === "Draft" && <div className="absolute bg-white w-fit bottom-16 right-5 rounded-full text-slate-400">
                                                    <div className='rounded-full px-3 py-2 text-red-600 font-semibold'>
                                                        Drafted
                                                    </div> 
                                                </div>
                                            }

                                            <div className="w-full bg-white rounded-b-xl px-6 py-3 flex justify-between">
                                                
                                                <div className="flex items-center gap-5">
                                                    <p className='flex items-center gap-2 text-lg'>
                                                        {post.likes?.length > 0 ? post.likes?.length : 0} <FcLike />
                                                    </p>
                                                    <p className='flex items-center gap-2 text-lg'>
                                                        {post.comments?.length > 0 ? post.comments?.length : 0} <FaRegComments />
                                                    </p>
                                                </div>

                                                <div className="text-slate-500 justify-center items-center text-lg flex gap-4">
                                                    <button
                                                        disabled={loading}
                                                        onClick={() => {
                                                            navigate(`/dashboard/edit-post/${post._id}`)
                                                        }}
                                                    >
                                                        <AiFillEdit />
                                                    </button>
                                                    <button
                                                        className="text-white p-1 bg-red-500 rounded-full hover:bg-red-600 transition-all duration-300"
                                                        disabled={loading}
                                                        onClick={() => {
                                                            setConfirmationModal({
                                                                text1:"Do you want to delete this post?",
                                                                text2:"All the data related to this post will be deleted",
                                                                btn1Text:"Delete",
                                                                btn2Text:"Cancel",
                                                                btn1Handler: !loading ? () => handlePostDelete(post._id): () => {},
                                                                btn2Handler:!loading ? () => setConfirmationModal(null): () => {},
                                                            })
                                                        }}
                                                    >
                                                        <MdOutlineDelete />

                                                    </button>
                                                </div>

                                            </div>


                                            {
                                                confirmationModal && <ConfirmationModal modalData={confirmationModal} />
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
            }

        </div>
    )
}

