import toast from "react-hot-toast";
import { apiConnector } from "../apiconnector";
import { settingsEndpoints } from "../apis";
import { setToken } from "../../slices/authSlice";
import { setUser } from "../../slices/profileSlice";
import { setLoading } from "../../slices/profileSlice";
import { resetDraft } from '../../slices/draftSlice';

const {
    UPDATE_DISPLAY_PICTURE_API,
    UPDATE_PROFILE_API,
    CHANGE_PASSWORD_API,
    DELETE_ACCOUNT_API
} = settingsEndpoints;

export function updateDisplayPicture(formData, navigate, token){
    return async (dispatch) => {
        const toastId = toast.loading("Loading...")
        
        dispatch(setLoading(true));

        // const token = localStorage.getItem("token");
        // console.log("token", token);

            try{
                const response = await apiConnector("PUT", UPDATE_DISPLAY_PICTURE_API, formData, {
                        "Content-Type": "multipart/form-data",
                        'Authorization': `Bearer ${token}`,
                });

                //console.log("UPDATE_DISPLAY_PICTURE_API RESPONSE", response);

                if(!response.data.success){
                    throw new Error(response.data.message);
                }

                toast.success("Profile Photo Updated Successfully");
                
                const userImage = response.data?.user?.image
                ? response.data.user.image
                : `https://api.dicebear.com/8.x/initials/svg?seed=${response.data.user.firstName} ${response.data.user.lastName}`

                dispatch(setUser({...response.data.user, image:userImage}));
                localStorage.setItem("user", JSON.stringify(response.data.user));
                navigate("/dashboard/settings");
            }
            catch(error){
                //console.log("UPDATE_DISPLAY_PICTURE_API ERROR", error);
                toast.error("Failed to Update Display Picture");
            }

            dispatch(setLoading(false));
            toast.dismiss(toastId);
    }
}

export function updateProfile(data, navigate, token){
    return async (dispatch) => {
        const toastId = toast.loading("Loading...");
        
        dispatch(setLoading(true));

        try{
            const response = await apiConnector("PUT", UPDATE_PROFILE_API, data, {
                'Authorization': `Bearer ${token}`,
            })

            //console.log("UPDATE_PROFILE_API RESPONSE", response);

            if(!response.data.success){
                throw new Error(response.data.message);
            }

            toast.success("Profile Updated Successfully");

            const updatedUserProfile = response.data.profileDetails;

            dispatch(setUser(updatedUserProfile));
            localStorage.setItem("user", JSON.stringify(updatedUserProfile));
            navigate("/dashboard/my-profile");

        }
        catch(error){
            //console.log("UPDATE_PROFILE_API ERROR", error);
            toast.error("Failed to Update Profile");
        }

        dispatch(setLoading(false));
        toast.dismiss(toastId);
    }
}

export function updatePassword(data, navigate, token){
    return async (dispatch) => {
        const toastId = toast.loading("Loading...");
        
        dispatch(setLoading(true));

        try{
            

            const response = await apiConnector("PUT", CHANGE_PASSWORD_API, data, {
                'Authorization': `Bearer ${token}`,
            });

            //console.log("CHANGE_PASSWORD_API RESPONSE", response);

            if(!response.data.success){
                throw new Error(response.data.message);
            }
            
            toast.success("Password Changed Successfully");
            navigate("/dashboard/my-profile");
            
        }
        catch(error){
            //console.log("CHANGE_PASSWORD_API ERROR", error);
            toast.error("Failed to Change Password");
        }

        dispatch(setLoading(false));
        toast.dismiss(toastId);
    }
}

export function deleteAccount(token, navigate){
    return async (dispatch) => {
        const toastId = toast.loading("Loading...");
       
        dispatch(setLoading(true));

        try{
            const response = await apiConnector("DELETE", DELETE_ACCOUNT_API, null, {
                'Authorization': `Bearer ${token}`,
            });

            if(!response.data.success){
                throw new Error(response.data.message);
            }

            dispatch(setToken(null));
            dispatch(setUser(null));
            dispatch(resetDraft(null));
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            toast.success("Account Deleted Successfully");
            navigate("/");
        }
        catch(error){
            //console.log("DELETE_ACCOUNT_API ERROR", error);
            toast.error("Failed to Delete Account");
        }

        dispatch(setLoading(false));
        toast.dismiss(toastId);
    }
}