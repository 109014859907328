import { useForm } from 'react-hook-form';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ProfileEditBtn from '../../../common/ProfileEditBtn';
import CountryCode from '../../../../data/countrycode.json';
import { updateProfile } from '../../../../services/operations/profileAPI';
import { MdOpenInBrowser } from 'react-icons/md';

const EditProfile = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { token } = useSelector((state) => state.auth);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isSubmitSuccessful },
    } = useForm();

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset({
                gender: '',
                dateOfBirth: '',
                contactNumber: '',
                about: '',
            });
        }
    }, [isSubmitSuccessful, reset]);

    const submitProfileForm = (data) => {
        //console.log(data);

        // Ensure contactNumber is parsed as a number
        data.contactNumber = parseInt(data.contactNumber, 10);

        // Dispatch the form data here
        dispatch(updateProfile(data, navigate, token));
    };

    return (
        <div className="rounded-3xl shadow-lg bg-white p-10 mt-10 border border-slate-200">
            {/* section 1 */}
            <div>
                <div className="flex flex-col gap-10">
                    <div className="flex flex-col gap-3">
                        <p className="text-5xl font-bold overflow-hidden">Edit Profile</p>
                        <p>Please fill the correct information in the respective fields</p>
                    </div>

                    <form onSubmit={handleSubmit(submitProfileForm)}>
                        <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
                            {/* Gender */}
                            <div className="flex flex-col">
                                <label htmlFor="gender">Gender</label>
                                <select
                                    name="gender"
                                    id="gender"
                                    {...register('gender', { required: true })}
                                    className="text-black px-3 py-2 rounded-lg bg-gray-200 focus:outline-none"
                                >
                                    <option value="">Select Gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                </select>
                                {errors.gender && <span className="text-red-500">Gender is required</span>}
                            </div>

                            {/* Date of Birth */}
                            <div className="flex flex-col">
                                <label htmlFor="dateOfBirth">Date of Birth</label>
                                <input
                                    type="date"
                                    name="dateOfBirth"
                                    id="dateOfBirth"
                                    {...register('dateOfBirth', { required: true })}
                                    className="text-black px-3 py-2 rounded-lg bg-gray-200 focus:outline-none"
                                />
                                {errors.dateOfBirth && <span className="text-red-500">Date of Birth is required</span>}
                            </div>

                            {/* Phone Number */}
                            <div className="flex flex-col">
                                <label>Phone No</label>
                                <div className="flex gap-2">
                                    <select
                                        name="countrycode"
                                        id="countrycode"
                                        {...register('countrycode', { required: true })}
                                        className="text-black px-3 py-2 rounded-lg bg-gray-200 focus:outline-none w-8/12"
                                    >
                                        {CountryCode.map((element, index) => (
                                            <option key={index} value={element.dial_code}>
                                                {element.dial_code} - {element.name}
                                            </option>
                                        ))}
                                    </select>

                                    <input
                                        type="number"
                                        name="contactNumber"
                                        id="contactNumber"
                                        placeholder="12345 67890"
                                        className="text-black px-3 py-2 rounded-lg bg-gray-200 w-full focus:outline-none"
                                        {...register('contactNumber', {
                                            required: 'Phone number is required',
                                            maxLength: {
                                                value: 10,
                                                message: 'Invalid phone number',
                                            },
                                            minLength: {
                                                value: 8,
                                                message: 'Invalid phone number',
                                            },
                                        })}
                                    />
                                </div>
                                {errors.contactNumber && <span className="text-red-500">{errors.contactNumber.message}</span>}
                            </div>
                        </div>

                        {/* About */}
                        <div className="flex flex-col mt-5">
                            <label htmlFor="about" className="mb-2">
                                About
                            </label>
                            <textarea
                                name="about"
                                id="about"
                                placeholder="Share your creative life with us"
                                {...register('about')}
                                className="text-black px-3 py-2 rounded-lg bg-gray-200 w-full h-32 focus:outline-none resize-none"
                            />
                        </div>

                        {/* Buttons */}
                        <div className="flex gap-4 mt-5">
                            <ProfileEditBtn type="submit" text="Save" />
                            <ProfileEditBtn type="button" text="Cancel" onClick={() => navigate('/dashboard/my-profile')} />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EditProfile;
