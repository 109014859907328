import React, { useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { CiImageOn } from "react-icons/ci";
import { HiUpload } from "react-icons/hi";

const SecondImageComponent = ({ setValue }) => {

    const [image, setImage] = useState(null);
    const [imageName, setImageName] = useState("");

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        //console.log("FILE", file);
        if(file){
            setImage(URL.createObjectURL(file));
            setImageName(file.name);
            setValue("secondThumbnailImage", file);
        }
    };

    const handleImageRemove = () => {
        setImage(null);
        setImageName("");
        setValue("secondThumbnailImage", null);
    }

    return(
        <div className="mb-4">
            <label htmlFor="picture" className="block font-bold mb-2">
                Second Picture <span className="text-blue-500">*</span>
            </label>
            <div className="flex items-center justify-around border-2 border-dashed border-slate-400 bg-gray-200 rounded-md p-4 h-auto relative">
                {image ? (
                <>
                    <img src={image} alt="Thumbnail" className="object-cover aspect-video w-full h-full rounded-md" />
                    <button
                    type="button"
                    className="absolute top-5 right-5 p-1 text-red-500 bg-white rounded-full"
                    onClick={handleImageRemove}
                    >
                    <AiOutlineDelete fontSize={20} />
                    </button>
                    <span className="absolute bottom-2 left-2 text-gray-800 bg-white p-1 rounded-md">
                    {imageName}
                    </span>
                </>
                ) : (
                <div className="flex items-center gap-4">
                    <div className="p-1 sm:p-3 hidden sm:block bg-slate-300 rounded-lg"><CiImageOn fontSize={24} /></div>
                    <span className="text-gray-500">Upload a photo as the thumbnail</span>
                    <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    className="hidden"
                    id="upload"
                    />
                    <label
                    htmlFor="upload"
                    className="font-medium text-gray-500 bg-white p-2 sm:px-4 rounded-full focus:outline-none cursor-pointer"
                    >
                    <span className="hidden sm:block">Browse</span><HiUpload className="block sm:hidden"/>
                    </label>
                </div>
                )}
            </div>
            <p className="text-gray-500 text-sm mt-1">PNG/JPG Supported</p>
        </div>
    )
};

export default SecondImageComponent;