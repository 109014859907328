import { auth, provider } from "../../firebase/firebase";
import { signInWithPopup } from "firebase/auth";
import { apiConnector } from "../apiconnector";
import { endpoints } from "../apis";
import toast from "react-hot-toast";
import { setLoading, setToken } from "../../slices/authSlice";
import { setUser } from "../../slices/profileSlice";

export function handleGoogleLogin(navigate){
    return async (dispatch) => {
        const toastId = toast.loading("Loading...");
        dispatch(setLoading(true));
        const { GOOGLE_LOGIN_API } = endpoints;

        try{
            const result = await signInWithPopup(auth, provider);
            //console.log("RESULT", result);
            const user = result.user;
            //console.log("USER", user);

            //const token = await getIdToken(user);

            const response = await apiConnector("POST", GOOGLE_LOGIN_API, {
                uid: user.uid,
                firstName: user.displayName.split(" ")[0],
                lastName: user.displayName.split(" ")[1],
                email: user.email,
                imageUrl:user.photoURL,
                accountType: "User"
            });

            //console.log("RESPONSE FROM GOOGLE LOGIN", response);

            if(!response.data.success){
                throw new Error(response.data.message);
            }

            toast.success("Login Successful");
            //console.log("USER", response.data.user);
            dispatch(setToken(response.data.token));
            dispatch(setUser({...response.data.user}));

            localStorage.setItem("token", JSON.stringify(response.data.token));
            localStorage.setItem("user", JSON.stringify(response.data.user));

            navigate("/dashboard/my-profile");
        }
        catch(error){
            if (error.code === 'auth/popup-closed-by-user') {
                toast.error("Popup closed before completing login.");
            } else {
                toast.error("An error occurred during Google login.");
                //console.error("ERROR OCCURRED WHILE LOGIN WITH GOOGLE", error);
            }
        }

        dispatch(setLoading(false));
        toast.dismiss(toastId);
    }
}