import React from "react";

const Error = () => {
    return(
        <div className="flex justify-center items-center
        text-3xl mt-[5rem]">
            Something Went Wrong.
        </div>
    )
}

export default Error;