import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const API_KEY = process.env.API_KEY
const AUTH_DOMAIN = process.env.AUTH_DOMAIN
const PROJECT_ID = process.env.PROJECT_ID
const STORAGE_BUCKET = process.env.STORAGE_BUCKET
const MESSAGING_SENDER_ID = process.env.MESSAGING_SENDER_ID
const APP_ID = process.env.APP_ID

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB-8dtGkSixe9QAnCCC0EZa_th7bCkKEis",
  authDomain: "loginauth-85a0c.firebaseapp.com",
  projectId: "loginauth-85a0c",
  storageBucket: "loginauth-85a0c.firebasestorage.app",
  messagingSenderId: "349946997762",
  appId: "1:349946997762:web:079c3f399a1d3b10690b1b"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export {app, auth, provider};