import React from "react";
import ContactUsForm from "../ContactPage/ContactUsForm";

const ContactFormSection = () => {
    return(
        <div className="mx-auto pt-10 pb-10 overflow-hidden">
            <h1 className="text-5xl font-bold text-center leading-[3.5rem] overflow-clip">Get in Touch</h1>
            <p className="py-4 text-center text-2xl font-semibold">We'd love to here from you. Please fill out this form</p>
            <div className="py-5">
                <ContactUsForm />
            </div>
        </div>
    )
};

export default ContactFormSection;