import React, { useState } from "react";
import { MDBAccordion, MDBAccordionItem } from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";



const ManageAccount = () => {

  const navigate = useNavigate();

  const [active, setActive] = useState('editProfile');

  const handleTabClick = (tab) => {
    setActive(tab);
  };

  const getClasses = (tab) => {
    return active === tab ? 'bg-white text-[#8D493A] rounded-lg' : 'text-white border-2 border-white hover:bg-[#F8EDE3] hover:text-black transition-all duration-300';
  };

  return (
    <div className="w-full flex flex-col gap-10 h-fit">
      <h1 className="text-center text-[3rem] font-bold text-[#8D493A]">Manage Account</h1>
      <p className="text-lg text-justify">Taking care of your account doesn’t just protect your personal data, it enhances your overall experience on the platform. By keeping your information up to date and prioritizing security, you’re 
        ensuring a seamless, worry-free blogging journey. Remember, managing your account well is a small effort that pays off in the long run, giving you peace of mind and uninterrupted access to the features 
        you enjoy.</p>

      <div className="w-full p-5 lg:p-16 bg-[#8D493A] gap-14 flex flex-col lg:flex-row rounded-xl">
          <div className="flex flex-col text-white gap-7 w-full lg:w-2/12">

            <p className={` ${getClasses('editProfile')} cursor-pointer py-6 px-3 rounded-lg flex flex-col items-center justify-center font-bold`} onClick={() => handleTabClick('editProfile')}>
              <span>1</span>
              <span>Edit Profile</span>
            </p>

            <p className={` ${getClasses('changePassword')} cursor-pointer py-6 px-3 rounded-lg flex flex-col items-center justify-center font-bold`} onClick={() => handleTabClick('changePassword')}>
              <span>2</span>
              <span>Change Password</span>
            </p>

            <p className={` ${getClasses('deleteAccount')} cursor-pointer py-6 px-3 rounded-lg flex flex-col items-center justify-center font-bold`} onClick={() => handleTabClick('deleteAccount')}>
              <span>3</span>
              <span>Delete Account</span>
            </p>
              
          </div>
          
          <div className="w-full lg:w-10/12">
            {
              active === "editProfile" && <div className="space-y-4">
                <h1 className="text-[2rem] font-bold text-white">Edit Profile</h1>
                <p className="text-white text-justify">Keeping your profile information updated is vital for making sure your connections, followers, and collaborators can reach you with ease. A complete and current 
                  profile helps build trust with the community and ensures that any communication, whether professional or casual, goes to the right place.</p>
                <p className="text-white text-justify"><strong>Why it Matters:</strong><br/> Your profile reflects who you are within the community. Inaccurate or outdated information can lead to confusion or missed opportunities for collaboration.</p>
                <p className="bg-white shadow-md rounded-lg p-5 font-semibold">How to Edit Profile Information:</p>
                <ul className="rounded-lg space-y-2 p-5 bg-white"> 
                  <li className="flex items-center gap-5"><p className="bg-[#8D493A] py-3 text-center w-[50px] px-4 text-white">1</p>Go to your Dashboard from top-right corner.</li>
                  <li className="flex items-center gap-5"><p className="bg-[#8D493A] py-3 text-center w-[50px] px-4 text-white">2</p>Click on the "Settings" Tab on the left/top.</li>
                  <li className="flex items-center gap-5"><p className="bg-[#8D493A] py-3 text-center w-[50px] px-4 text-white">3</p>Update your profile information as necessary.</li>
                  <li className="flex items-center gap-5"><p className="bg-[#8D493A] py-3 text-center w-[50px] px-4 text-white">4</p>Save your changes.</li>
                </ul>
                <div className="bg-[#D0B8A8] px-4 py-3 rounded-lg shadow-md font-semibold">
                  <p>Note: If the user is logged in from Google SignUp/Login, they must not be able to change the profile photo as it is already associated from the Google's Profile Information.</p>
                </div>
                <p className="text-white font-bold mt-5 text-right cursor-pointer" onClick={() => navigate('/contact')}>For More Information, Contact Us</p>
              </div>
            }

            {
              active === "changePassword" && <div className="space-y-4">
                <h1 className="text-[2rem] font-bold text-white">Change Password</h1>
                <p className="text-white text-justify">Maintaining the security of your account is crucial. Your password is your first line of defense against unauthorized access. Regularly changing your 
                  password and ensuring it's strong and unique helps protect your personal information and blog content.</p>
                <p className="text-white text-justify"><strong>Why it Matters:</strong><br/> Weak or reused passwords make your account vulnerable to hacking attempts, especially if the same password is used across multiple platforms. By changing your password periodically, you can reduce the risk of cyber attacks and data breaches.</p>
                <p className="bg-white shadow-md rounded-lg p-5 font-semibold">How to Change Password of your Account:</p>
                <ul className="rounded-lg space-y-2 p-5 bg-white"> 
                  <li className="flex items-center gap-5"><p className="bg-[#8D493A] py-3 text-center w-[50px] px-4 text-white">1</p>Go to your Dashboard from top-right corner.</li>
                  <li className="flex items-center gap-5"><p className="bg-[#8D493A] py-3 text-center w-[50px] px-4 text-white">2</p>Click on the "Settings" Tab on the left/top.</li>
                  <li className="flex items-center gap-5"><p className="bg-[#8D493A] py-3 text-center w-[50px] px-4 text-white">3</p>Scroll down to Change Password Section and write your New Password.</li>
                  <li className="flex items-center gap-5"><p className="bg-[#8D493A] py-3 text-center w-[50px] px-4 text-white">4</p>Save your changes and your new Password will be updated.</li>
                </ul>
                <div className="bg-[#D0B8A8] px-4 py-3 rounded-lg shadow-md font-semibold">
                  <p>Note: If the user is logged in from Google SignUp/Login, they must not be able to change the password as it is already associated from the Google's Profile Information.</p>
                </div>
                <p className="text-white font-bold mt-5 text-right cursor-pointer" onClick={() => navigate('/contact')}>For More Information, Contact Us</p>
              </div>
            }

            {
              active === "deleteAccount" && <div className="space-y-4">
                <h1 className="text-[2rem] font-bold text-white">Delete Account</h1>
                <p className="text-white text-justify">If you ever decide to leave the platform, we make it easy for you to delete your account. Deleting your account means all your personal information, blog 
                  posts, and interactions will be permanently removed. Please consider this decision carefully, as once your account is deleted, this action cannot be undone.</p>
                <p className="text-white text-justify"><strong>Why it Matters:</strong><br/> While we hope you stay with us, we respect your choice to delete your account. It’s important to understand the consequences of account deletion and to ensure you have exported any important data before proceeding.</p>
                <p className="bg-white shadow-md rounded-lg p-5 font-semibold">How to Permanently Delete your account:</p>
                <ul className="rounded-lg space-y-2 p-5 bg-white"> 
                  <li className="flex items-center gap-5"><p className="bg-[#8D493A] py-3 text-center w-[50px] px-4 text-white">1</p>Go to your Dashboard from top-right corner.</li>
                  <li className="flex items-center gap-5"><p className="bg-[#8D493A] py-3 text-center w-[50px] px-4 text-white">2</p>Click on the "Settings" Tab on the left/top.</li>
                  <li className="flex items-center gap-5"><p className="bg-[#8D493A] py-3 text-center w-[50px] px-4 text-white">3</p>Scroll down to Delete Account Section..</li>
                  <li className="flex items-center gap-5"><p className="bg-[#8D493A] py-3 text-center w-[50px] px-4 text-white">4</p>Click on Delete Account Icon to delete your account.</li>
                </ul>
                <div className="bg-[#D0B8A8] px-4 py-3 rounded-lg shadow-md font-semibold">
                  <p>Note: This process is irreversible and all the Data associated with the user will be permanently removed.</p>
                </div>
                <p className="text-white font-bold mt-5 text-right cursor-pointer" onClick={() => navigate('/contact')}>For More Information, Contact Us</p>
              </div>
            }
          </div>
      </div>
      
      
      
      <div>
         {/* <MDBAccordion initialActive={1}>
            {data.map((item) => (
                <MDBAccordionItem collapseId={item.id} headerTitle={item.title} key={item.id}>
                    {item.content}
                </MDBAccordionItem>
            ))}
         </MDBAccordion> */}
      </div>
    </div>
  );
};

export default ManageAccount;