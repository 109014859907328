import React from 'react';
import './AboutCard.css';
import { AiFillEnvironment } from "react-icons/ai";
import { SiGitbook } from "react-icons/si";

const AboutCard = () => {
  return (<div className='flex flex-col sm:flex-row items-center space-between overflow-visible gap-16 justify-center'>
            <div className="card">
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="card-inner">
                    <div className='p-5 text-center flex justify-center flex-col items-center'>
                        <AiFillEnvironment className='text-4xl text-[#3A7E8D]'/>
                        <p className='font-semibold text-2xl mt-5'>Best Environment</p>
                        <p className='text-center mt-5'>Inclusive and supportive environment where creativity flourishes and diverse voices are celebrated</p>
                    </div>
                </div>
            </div>

            <div className="card">
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="card-inner">
                    <div className='p-5 text-center flex justify-center flex-col items-center'>
                        <SiGitbook className='text-3xl text-[#3A7E8D]'/>
                        <p className='font-semibold text-2xl mt-5'>Blogging Experience</p>
                        <p className='text-center mt-5'>Discover new perspectives and build relationships. With features like user-generated content, interactive comments, and personlized recommendations.</p>
                    </div>
                </div>
            </div>
            
        </div>
    
  );
};

export default AboutCard;
