import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { fetchAllGeneratedComments } from "../../../services/operations/postDetailsAPI";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import EmptyLoader from "../../EmptyLoader";
import { FaComment } from "react-icons/fa";


const CommentSlider = () => { 

    const [comments, setComments] = useState([]);

    useEffect(() => {
        const fetchComments = async () => {
          try{
              const response = await fetchAllGeneratedComments();
              setComments(response.data);
          }
          catch(error){
              console.log(error);
          }
        }

        fetchComments();
    },[])

    //console.log("COMMENTS IN SLIDER", comments);

    const settings = {
      arrows: true,
      fade: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      waitForAnimate: false,
      autoplay:true,
      autoplaySpeed:3000,
    };

    return (
      <div className="h-full w-full flex items-center justify-center">
              {
                comments?.length === 0 ? (
                  <div className="flex items-center justify-center w-full">
                      <EmptyLoader />
                  </div>
                ) :
                (
                  <div className="slider-container w-full h-full ">
                      
                      <Slider {...settings} className="h-full w-full py-5">
                          {
                              comments?.slice(0, 10).map((comment, index) => {

                                  const date = new Date(comment?.createdAt);
                                  const hours = date.getHours();

                                  const tags = () => {
                                    // Check if tag is a JSON string and parse it
                                    let tags = [];
                                    if (comment?.post?.tag.length > 0) {
                                    try {
                                        tags = JSON.parse(comment?.post.tag[0]);
                                    } catch (error) {
                                        console.error("Failed to parse tags:", error);
                                    }
                                    }
                                    return tags[0];
                                }
                            

                                  return(
                                    <div key={index} className="bg-[#F8EDE3] flex flex-col gap-1 text-black rounded-lg p-5 px-10">
                                        <div className="flex items-center justify-between">
                                            <div className="flex items-center gap-7 text-sm">
                                                <p>{`#${tags(comment?.post?.tag)}`}</p>
                                                <p className="text-[#8D493A]">{hours} hours ago</p>
                                            </div>
                                            <div className="flex items-center gap-3">
                                                <p><FaComment /></p>
                                                <span>{comment?.post?.comments.length}</span>
                                            </div>
                                        </div>
                                        <Link to={`/posts/${comment?.post?._id}`} className="font-semibold mt-4 text-lg">
                                            {comment?.post.postName}
                                        </Link>
                                        <p className="text-sm font-medium mt-1">{comment?.body}</p>
                                        <div className="flex items-center gap-4 mt-3">
                                            <img src={comment?.user?.image?.url} alt="user" 
                                            className="w-[40px] h-[40px] aspect-square rounded-full"/>
                                            <p>{comment?.user?.firstName}</p>
                                        </div>
                                    </div>
                                  )
                              })
                          }
                      </Slider>
                  </div>
                )
              }
      </div>  
    )
};

export default CommentSlider;
