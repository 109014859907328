import React from 'react';
import { Link } from 'react-router-dom';
import visionimg from '../assets/vision.jpg';
import { MdKeyboardArrowRight } from "react-icons/md";
import Footer from '../components/common/Footer';
import footer from '../assets/footer-bg.jpg';
import logo from '../assets/Logo Circle - PNG.png';
import logotext from '../assets/logo-text.png';
import AboutCard from '../components/core/AboutPage/AboutCard';
import creativeimg from '../assets/creative.jpg';
import ContactFormSection from '../components/core/AboutPage/ContactFormSection';

const AboutUs = () => {
    return(
        <div className='w-screen'>
            <div className='max-w-[1080px] mx-auto p-3 lg:p-10'>
                <div className='bg-white w-full lg:h-[500px] mt-6 rounded-2xl flex flex-col-reverse lg:flex-row'>
                    <div className='w-full lg:w-1/2 p-8 lg:p-10 my-auto flex flex-col gap-4'>
                        <h1 className='text-[#3A7E8D] font-bold text-xl'>About Us</h1>

                        <p className='text-3xl lg:text-4xl leading-[3rem] font-extrabold overflow-hidden'>The common spiritcharacterizes us</p>

                        <p className='italic text-lg'>"What is worth doing is worth doing well." <br/> -Nicolas Poussin</p>
                        <Link to="/">
                            <button className='flex items-center gap-2 bg-[#3A7E8D] text-white py-3 px-4 rounded-lg hover:bg-[#255964]
                            transition-all duration-300'>Read More <MdKeyboardArrowRight /> </button>
                        </Link>
                    </div>
                    <div className='w-full lg:w-1/2 px-8 pt-8 lg:p-10 my-auto'>
                        <img src={visionimg} alt='vision' loading='lazy' className='rounded-xl shadow-lg'></img>
                    </div>
                </div>

                <div className='mx-auto p-10 sm:py-16 overflow-hidden'>
                    <h1 className='text-3xl sm:text-5xl font-bold text-center leading-10 sm:leading-[3.5rem] overflow-hidden'>
                        Our goal is to make creative minds explore each other
                    </h1>
                    <p className='text-justify pt-5 sm:px-10'>Hey there, it's a pleasure to announce you that here in JeviKnows you get the platform where you can express any
                        kind of art to your liking. As you know this world is filled with so many uncertainity where each and every event and
                        moment is captured by Art, whether it's a painting, poetry or story writing. <b>"Everything is an Art when you put
                            your heart into it."
                        </b>
                    </p>
                    <div className='relative pt-10'>
                        <img src={creativeimg} alt='photo' loading='lazy' 
                        className='rounded-xl'/>
                        <div className='text-[10px] sm:text-base absolute bottom-[10px] left-[10px] sm:bottom-[40px] sm:left-[40px] text-white bg-slate-700 p-3 rounded-lg'>
                            Photo by <a href="https://unsplash.com/@rachaelgorjestani?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Rachael Gorjestani</a> on <a href="https://unsplash.com/photos/person-holding-book-with-sketch-X6CZGpJBi8U?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
                        </div>
                    </div>
                </div>

                <div className='mx-auto pb-10 overflow-hidden'> 
                    <p className='text-center mb-12 text-[2rem] text-black font-semibold'>OUR SERVICES</p>
                    <AboutCard />
                </div>

                <div className='overflow-hidden'>
                    <ContactFormSection />
                </div>

            </div>
            
            <div className='h-auto w-screen text-center bg-white py-10'>
                <div className='max-w-3xl mx-auto px-8 sm:px-4'>
                    <p className='text-3xl font-bold mb-6 text-gray-800 overflow-hidden'>Note from Admin:</p>
                    <ul className='text-left list-disc list-inside text-lg text-gray-700'>
                        <li className='mb-4'>JeviKnows is currently under development, and various features will be implemented to engage users with their interests.</li>
                        <li className='mb-4'>Some features may be temporarily unavailable and will be fixed in future updates.</li>
                        <li className='mb-4'>Our primary focus is to connect users with creative minds worldwide. Share your views with us at:</li>
                        <li><a href='mailto:jeviknows@gmail.com' className='text-blue-500 hover:underline'>jeviknows@gmail.com</a></li>
                    </ul>
                </div>
            </div>



            {/* Section - 3 */}
            <Footer footer={footer} logo={logo} logotext={logotext}/>
        </div>
    )
};

export default AboutUs;