import { HiHome } from "react-icons/hi2";
import { BsPeopleFill } from "react-icons/bs";
import { IoMdContact } from "react-icons/io";
import { MdOutlineLibraryBooks } from "react-icons/md";
import { FaSearch } from "react-icons/fa";

export const NavbarLinks = [
    {
        id:'home',
        title:"Home",
        path:"/",
        Logo:<HiHome/>
    },
    {
        id:'blog',
        //title:"Blogs",
        path:"/catalog/all",
        Logo:<MdOutlineLibraryBooks />

    },
    {
        id:'about',
        title:"About Us",
        path:"/about",
        Logo:<BsPeopleFill />
    },
    {
        id:'contact',
        title:"Contact Us",
        path:"/contact",
        Logo:<IoMdContact />
    },
    {
        id:'search',
        //title:"Search",
        Logo:<FaSearch />
    }
]