import React from "react";
import StoryForm from "./StoryBuilder/StoryForm";
import Card from "../../../common/Card Component/Card";

const AddStory = () => {
    return(
        <div className="py-8 px-8 xl:px-[6rem] xl:py-[5rem] overflow-y-auto bg-white flex flex-col-reverse lg:flex-row gap-10 w-full justify-center flex-1 min-h-[790px] items-center lg:items-start">
            <div className="w-full lg:w-8/12">
                <h1 className="text-4xl  font-bold overflow-hidden">Add Story</h1>
                <div>
                    <StoryForm />
                </div>
            </div>
            <div className="w-full lg:w-4/12 2xl:p-10">
                <Card text={"story"}/>
            </div>  
        </div>
    )
};

export default AddStory;