import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import { IoMdHeart } from "react-icons/io";
// import { IoMdHeartEmpty } from "react-icons/io";
import { useSelector } from "react-redux";
import { apiConnector } from '../../../services/apiconnector';
import { storiesEndpoints } from '../../../services/apis';

const StoryLikeButton = ({storyId}) => {
    const navigate = useNavigate();
    const [liked, setLiked] = useState(false);
    const [likeCount, setLikeCount] = useState(0);
    const {user} = useSelector((state) => state.profile);
    const {token} = useSelector((state) => state.auth);
    const [isAnimating, setIsAnimating] = useState(false);

    const {GET_STORY_DETAILS_API, LIKE_STORY_API, DISLIKE_STORY_API} = storiesEndpoints;

    useEffect(() => {
        //fetch the current like status and count from the server
        
        const fetchLikeStatus = async () => {
            try{
                //fetch story details
                const response = await apiConnector("POST", GET_STORY_DETAILS_API, {storyId});
                //console.log("RESPONSE", response.data.data);
                setLikeCount(response.data.data.storyLikes?.length);

                //check for user like
                const userHasLiked = response.data.data.storyLikes?.some(like => like.user === user._id);
                
                if(userHasLiked){
                    setLiked(true);
                }
                else{
                    setLiked(false);
                }
            }
            catch(error){
                console.log("ERROR CALLING API", error);
            }
        }
        fetchLikeStatus();
    },[storyId])

    const handleLike = async () => {
        try{
            if(!token){
                navigate("/login");
            }

            if(liked){
                //unlike
                
                const response = await apiConnector("POST", DISLIKE_STORY_API, {storyId}, {
                    Authorization: `Bearer ${token}`
                });
                //console.log("RESPONSE OF DISLIKE", response);
                
                if(!response.data.success){
                    throw new Error(response.data.message);
                }

                const result = response.data.success;

                if(result){
                    setLikeCount(prev => prev - 1);
                    setLiked(false);
                }
            } else {
                //like
                
                const response = await apiConnector("POST", LIKE_STORY_API, {storyId}, {
                    Authorization: `Bearer ${token}`
                });

                //console.log("RESPONSE OF LIKE", response);

                if(!response.data.success){
                    throw new Error(response.data.message);
                }

                const res = response.data.success;
                if(res){
                    
                    setIsAnimating(true);
                    setLikeCount(prev => prev + 1);
                    setLiked(true);

                    // Remove the animation class after it completes
                    setTimeout(() => setIsAnimating(false), 2000); // Match this duration with the animation time
                }
            } 
        } 
        
        catch(error){
            console.log("Error liking/unliking post", error);
        }
    }


    return(
        <div className="flex items-center gap-4 p-2">
            <button className={`p-2 rounded-full bg-white border border-slate-200 ${isAnimating && liked ? 'animate-bounce': ''}`} onClick={handleLike}>
                {liked ? <IoMdHeart fontSize={26} className="text-red-500" /> : <IoMdHeart fontSize={26} className='text-red-200'/>}
            </button>
            <span className='font-bold text-[1.5rem]'>{likeCount}</span>
        </div>
    )
}

export default StoryLikeButton;