const BASE_URL = process.env.REACT_APP_BASE_URL

// export const categories = {
//     CATEGORIES_API: BASE_URL + "/post/showAllCategories",
// };

export const endpoints = {
    SENDOTP_API: BASE_URL + "/auth/sendOTP",
    SIGNUP_API: BASE_URL + "/auth/signup",
    LOGIN_API: BASE_URL + "/auth/login",
    RESETPASSTOKEN_API: BASE_URL + "/auth/reset-password-token",
    RESETPASSWORD_API: BASE_URL + "/auth/reset-password",
    GOOGLE_LOGIN_API: BASE_URL + "/auth/google-login"
};

//SETTINGS PAGE API
export const settingsEndpoints = {
    UPDATE_DISPLAY_PICTURE_API: BASE_URL + "/profile/updateDisplayPicture",
    UPDATE_PROFILE_API: BASE_URL + "/profile/updateProfile",
    CHANGE_PASSWORD_API: BASE_URL + "/auth/changePassword",
    DELETE_ACCOUNT_API: BASE_URL + "/profile/deleteAccount",
}

//CONTACT US API
export const contactusEndpoints = {
    CONTACT_US_API: BASE_URL + "/contact/contactus",
    GET_ALL_CONTACT_FORMS_API : BASE_URL + "/contact/contactForms"
}

//METRICS API
export const metricsEndpoints = {
    GET_POST_METRICS_API: BASE_URL + "/metrics/postMetrics",
    GET_USER_REGISTRATION_METRICS_API: BASE_URL + "/metrics/getUserRegistrationMetrics",
}

//BLOG DETAILS AND USER/ADMIN DETAILS API
export const blogDetailsEndpoints = {
    GET_USER_DETAILS_API: BASE_URL + "/profile/getUserDetails",
    GET_USER_POSTS_API: BASE_URL + "/post/getUserPosts",
    GET_USER_POST_DETAILS_API: BASE_URL + "/post/getAllPosts",
    GET_ADMIN_POST_DETAILS_API: BASE_URL + "/post/getAdminPosts"
}

//post apis
export const postEndpoints = {
    CREATE_POST_API: BASE_URL + "/post/createPost",
    EDIT_POST_API: BASE_URL + "/post/editPost",
    DELETE_POST_API: BASE_URL + "/post/deletePost",
    CREATE_SECTION_API: BASE_URL + "/post/addSection",
    UPDATE_SECTION_API: BASE_URL + "/post/updateSection",
    DELETE_SECTION_API: BASE_URL + "/post/deleteSection",
    CREATE_SUB_SECTION_API: BASE_URL + "/post/addSubSection",
    UPDATE_SUB_SECTION_API: BASE_URL + "/post/updateSubSection",
    DELETE_SUB_SECTION_API: BASE_URL + "/post/deleteSubSection",
    POST_CATEGORIES_API: BASE_URL + "/post/showAllCategories",
    GET_POST_DETAILS_API: BASE_URL + "/post/getPostDetails",
    GET_POST_DETAILS_TO_VIEW_API: BASE_URL + "/post/getPostDetailsToView",
    GET_PINNED_POSTS: BASE_URL + "/post/pinned",
    PIN_POSTS: BASE_URL + "/post/pin",
    UNPIN_POSTS: BASE_URL + "/post/unpin",
    SEARCH_POSTS_API: BASE_URL + "/post",
    LIKE_POST_API: BASE_URL + "/post/createLike",
    UNLIKE_POST_API: BASE_URL + "/post/dislikePost",
    CREATE_COMMENT_API: BASE_URL + "/post/createComment",
    DELETE_COMMENT_API: BASE_URL + "/post/deleteComment",
    VIEW_ALL_COMMENTS_API: BASE_URL + "/post/getAllComments",
    FETCH_ALL_GENERATED_COMMENTS_API: BASE_URL + "/post/getAllGeneratedComments",
}

//CATALOG PAGE DATA
export const catalogData = {
    CATALOG_PAGE_DATA_API: BASE_URL + "/post/categoryPageDetails"
}

//STORIES ENDPOINTS
export const storiesEndpoints = {
    CREATE_STORY_API: BASE_URL + "/story/createStory",
    DELETE_STORY_API: BASE_URL + "/story/deleteStory",
    GET_ALL_STORIES_API: BASE_URL + "/story/allStories",
    GET_STORY_DETAILS_API: BASE_URL + "/story/viewStory",
    LIKE_STORY_API: BASE_URL + "/story/likeStory",
    DISLIKE_STORY_API: BASE_URL + "/story/dislikeStory",
    GET_ALL_STORY_LIKES_API: BASE_URL + "/story/getAllSLikes",
    GET_ALL_ADMIN_STORIES: BASE_URL + "/story/allAdminStories"
}