import React from "react";
import * as Icons from "react-icons/vsc";
import { useDispatch } from "react-redux";
import { matchPath, NavLink, useLocation } from "react-router-dom";

const SidebarLink = ({link, iconName}) => {

    const Icon = Icons[iconName];
    const location = useLocation();
    const dispatch = useDispatch();

    const matchRoute = (route) => {
        return matchPath({path:route}, location.pathname);
    }

    return(
        <NavLink
            to={link.path}
            //onClick={}
            className={`relative py-4 px-4 xl:px-0 xl:py-2 text-sm font-medium m-2 xl:m-4 rounded-lg 
            ${matchRoute(link.path) ? "bg-[#F8EDE3]" : "bg-opacity-0"}`}
        >
            <span className={`absolute left-0 top-0 h-full w-[0.2rem] bg-[#3A7E8D]
            ${matchRoute(link.path) ? "opacity-100" : "opacity-0"}`}>
            </span>

            <div className="flex flex-col justify-center items-center gap-y-2">
                <Icon className="text-lg" />
                <span>{link.name}</span>
            </div>
        </NavLink>
    )
};

export default SidebarLink;