import React from "react";

const ProfileEditBtn = ({
    text,
    onClick,
    children,
    disabled,
    outline=false,
    customClasses,
    type
}) => {
    return(
        <button
        disabled={disabled}
        onClick={onClick}
        type={type}
        className="flex gap-3 items-center bg-[#3A7E8D] py-3 px-4 rounded-xl text-white hover:bg-[#245b67] transition-all duration-300"
        >
            {
                children ? (
                    <>
                        <span>
                            {text}
                        </span>
                        {children}
                    </>
                ) : 
                (text)
            }
        </button>
    )
};

export default ProfileEditBtn;