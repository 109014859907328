import React, { useState } from "react";
import { sidebarLinks } from "../../../data/dashboard-links";
import { logout } from "../../../services/operations/authAPI";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../Spinner";
import SidebarLink from "./SidebarLink";
import { useNavigate } from "react-router-dom";
import { VscSignOut } from "react-icons/vsc";
import ConfirmationModal from "../../common/ConfirmationModal";

const Sidebar = () => {

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [confirmationModal, setConfirmationModal] = useState(null);

    const {loading: authLoading} = useSelector((state) => state.auth);
    const {user, loading: profileLoading} = useSelector((state) => state.profile);

    if(authLoading || profileLoading){
        return(
            <div>
                <Spinner />
            </div>
        )
    }

    return(
        <div className="w-fit xl:w-1/10 bg-white shadow-lg h-auto rounded-2xl relative z-10">
            <div className="flex justify-center flex-wrap flex-row xl:flex-col">
                {
                    sidebarLinks.map((link) => {
                        // console.log("Account Type", user?.accountType);
                        // console.log("Link Type", link.type);
                        if(link.type && user?.accountType !== link.type) return null;
                        return(
                            <SidebarLink key={link.id} link={link} iconName={link.icon}/>
                        )
                    })
                }
            </div>

            <div className="mx-auto xl:mt-2 xl:mb-2 h-[2px] rounded-sm w-10/12 bg-slate-200">

            </div>  
            
            <div className="flex flex-row xl:flex-col justify-center gap-4 xl:mb-6">
                <SidebarLink 
                    link={{name:"Settings", path:"dashboard/settings"}} 
                    iconName={"VscSettings"}
                />

                <button
                    onClick={() => setConfirmationModal({
                        text1:"Are you Sure ?",
                        text2:"You will be logged out of your account",
                        btn1Text:"Logout",
                        btn2Text:"Cancel",
                        btn1Handler: () => dispatch(logout(navigate)),
                        btn2Handler: () => setConfirmationModal(null)
                    })}
                className="text-sm font-medium text-red-500 p-2 m-2 xl:p-0 xl:m-0"
                >
                    <div className="flex flex-col items-center gap-2">
                        <VscSignOut className="text-lg" />
                        <span>Logout</span>
                    </div>
                </button>
            </div>

        {
            confirmationModal && 
            <ConfirmationModal modalData={confirmationModal} />
        }
        </div>
    )
};

export default Sidebar;