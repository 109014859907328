import DeleteAccount from "./DeleteAccount";
import ChangeProfilePicture from "./ChangeProfilePicture";
import UpdatePassword from "./UpdatePassword";
import EditProfile from "./EditProfile";
import { useSelector } from "react-redux";

export default function Setting() {

    const {user} = useSelector((state) => state.profile);

    return(
        <div className="px-8 py-8 lg:px-[6rem] lg:py-[5rem] overflow-y-auto bg-white">
            <h1 className="text-4xl  font-bold overflow-hidden">
                Edit profile
            </h1>
            
            <p className="mt-5 font-semibold">Here you can edit public information about yourself.
                <br />This information will be displayed for other users within 5 minutes.
            </p>

            {/* Change Profile Picture */}
            {!user?.uid && <ChangeProfilePicture />}

            {/* Edit Profile */}
            <EditProfile />

            {/* Update Password */}
            {!user?.uid && <UpdatePassword />}

            {/* Delete Account */}
            <DeleteAccount />
        </div>
    )
}