import React, { useState, useEffect } from "react";
import { getFullDetailsOfPostToView } from "../services/operations/postDetailsAPI";
import { useParams } from "react-router-dom";
import { IoIosLink } from "react-icons/io";
import { FaFacebook } from "react-icons/fa";
import { IoLogoInstagram } from "react-icons/io5";
import { FaWhatsapp } from "react-icons/fa";
import { FaC, FaXTwitter } from "react-icons/fa6";
import toast from "react-hot-toast";
import Error from "./Error";
import Spinner from "../components/Spinner";
import { RiUser3Fill } from "react-icons/ri";
import { MdEmail } from "react-icons/md";
import { FcAbout } from "react-icons/fc";
import { formatDate } from "../services/formatDate";
import { TbCategoryFilled } from "react-icons/tb";
import { FaTags } from "react-icons/fa";
import { FaComments } from "react-icons/fa6";
import { IoMdHeart } from "react-icons/io";
import { BsTabletFill } from "react-icons/bs";
import { FcBookmark } from "react-icons/fc";
import { FaCircle } from "react-icons/fa";
import { TbMoodEmpty } from "react-icons/tb";
import LikeButton from "../components/core/LikeAndComment/LikeButton";
import CommentSection from "../components/core/LikeAndComment/CommentSection";
import { IoIosArrowForward } from "react-icons/io";



const PostDetails = () => {
    const {postId} = useParams();
    const [postData, setPostData] = useState();
    const [loading, setLoading] = useState();

    useEffect(() => {
        const getFullPostDetails = async (postId) => {
            try{
                const res = await getFullDetailsOfPostToView(postId);
                setPostData(res);
            }
            catch(error){
                console.log("ERROR CALLING API", error);

            }
        }
        if(postId){
            getFullPostDetails(postId);
            //console.log('postData', postData);
        }
    },[postId]);

    const [totalNoOfSections, setTotalNoOfSections] = useState(0);
    
    useEffect(() => {
        let sections = 0;
        postData?.postContent?.forEach((sec) => {
            sections += sec.subSection.length || 0
        })

        setTotalNoOfSections(sections);
        //console.log("TOTAL NUMBER OF SECTION", totalNoOfSections);

    }, [postData]);

    if(loading || !postData){
        return(
            <Spinner />
        )
    }

    const tags = () => {
        // Check if tag is a JSON string and parse it
        let tags = [];
        if (postData?.tag.length > 0) {
        try {
            tags = JSON.parse(postData.tag[0]);
        } catch (error) {
            console.error("Failed to parse tags:", error);
        }
        }
        return tags;
    }

    const sharePage = (platform) => {
        const url = window.location.href;
      
        switch (platform) {
          case 'copy':
            navigator.clipboard.writeText(url).then(() => {
              toast.success("Copied to Clipboard");
            });
            break;
          case 'facebook':
            window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`, '_blank');
            break;
          case 'whatsapp':
            window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(url)}`, '_blank');
            break;
          case 'twitter':
            window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`, '_blank');
            break;
          default:
            break;
        }
      };

    const scrollToSection = (sectionId) => {
        const sectionElement = document.getElementById(sectionId);
        if (sectionElement) {
            sectionElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return(
        <div className="w-screen"> 
            <div className="max-w-[1710px] mt-10 mx-auto mb-10 space-y-3 px-3">
                <div className="bg-[#181820] rounded-xl w-full h-auto p-8 xl:p-16 gap-[2rem] xl:gap-[8rem] flex flex-col-reverse lg:flex-row justify-evenly xl:justify-center">
                    <div className="w-full lg:w-7/12 text-white">
                        <p className="text-[2rem] xl:text-[3.5rem] font-bold capitalize">{postData?.postName}</p>
                        <p className="text-sm lg:text-base text-justify mt-5">{postData?.postDescription}</p>
                        <div className="w-fit mx-auto text-[13px] xl:text-base p-2 px-4 xl:p-5 mt-10 rounded-xl font-semibold flex flex-col sm:flex-row items-center justify-center gap-3 xl:gap-10 bg-white text-black">
                            <div className="flex flex-col gap-3 items-center">
                                <p className="flex gap-2 xl:gap-4 items-center"><RiUser3Fill /> Author</p>
                                <p>{postData?.user?.firstName} {postData?.user?.lastName}</p>
                            </div>
                                <div className="h-[2px] bg-slate-300 w-14 sm:h-14 sm:w-[2px]"></div>
                            <div className="flex flex-col gap-3 items-center">
                                <p className="flex gap-4 items-center"><MdEmail /> Email</p>
                                <p>{postData?.user?.email}</p>
                            </div>
                                <div className="h-[2px] bg-slate-300 w-14 sm:h-14 sm:w-[2px]"></div>
                            <div className="flex flex-col gap-3 items-center">
                                <p className="flex gap-4 items-center"><FcAbout /> About</p>
                                <p>{postData?.user?.additionalDetails?.about ? postData?.user?.additionalDetails?.about : "Not available"}</p>
                            </div>
                        </div>

                        <div className="flex mt-10 items-center text-[14px] sm:text-base">
                            Posted At {formatDate(postData?.createdAt)}
                        </div>

                        <div className="flex items-center gap-4 mt-5">
                            <p className="text-slate-300 font-semibold">Share this on:</p>
                            <div className="flex gap-5 items-center text-xl pl-1">
                                <button className="border border-slate-300 rounded-2xl hover:text-[#181820] p-2 hover:bg-slate-300 transition-all duration-300" onClick={() => sharePage('copy')}><IoIosLink /></button>
                                <button className="border border-slate-300 rounded-2xl hover:text-[#181820] p-2 hover:bg-slate-300 transition-all duration-300" onClick={() => sharePage('facebook')}><FaFacebook /></button>
                                <button className="border border-slate-300 rounded-2xl hover:text-[#181820] p-2 hover:bg-slate-300 transition-all duration-300" onClick={() => sharePage('whatsapp')}><FaWhatsapp /></button>
                                <button className="border border-slate-300 rounded-2xl hover:text-[#181820] p-2 hover:bg-slate-300 transition-all duration-300" onClick={() => sharePage('twitter')}><FaXTwitter /></button>
                            </div>
                        </div>
                    </div>

                    {/* Card Container */}
                    <div className="w-full lg:w-4/12 xl:w-3/12 bg-white rounded-xl h-fit p-2 text-sm sm:text-base">
                        <img src={postData?.thumbnail?.url} alt={postData?.postName} className="w-full rounded-xl max-h-[220px] object-cover" />
                        <div className="mt-5 px-5">
                            <p className="font-semibold py-2 flex gap-4 items-center"><TbCategoryFilled fontSize={24}/>{postData?.category?.name}</p>
                            <p className="font-semibold py-2 flex gap-4 items-center mt-4"><FaTags fontSize={24}/> {`#${tags(postData?.tag)}`}</p>
                            <p className="font-semibold py-2 flex gap-4 items-center mt-4"><IoMdHeart fontSize={24}/>{`${postData?.likes?.length} likes`}</p>
                            <p className="font-semibold py-2 flex gap-4 items-center mt-4"><FaComments fontSize={24}/>{`${postData?.comments?.length} comments`}</p>
                            <p className="flex items-center gap-4 py-2"></p>
                        </div>
                    </div>
                </div>

                {/* content and Table of Content */}
                <div className="rounded-xl w-full gap-5 lg:gap-0 xl:p-16 flex flex-col-reverse lg:flex-row justify-center">
                    <div className="w-full lg:w-9/12 rounded-xl sm:px-5">
                        <div className="p-5 sm:p-10 rounded-3xl flex flex-col gap-4 border border-slate-300 text-black bg-white">
                            {/* <p className="font-bold text-2xl">Post Content</p> */}

                            <div className="w-full flex flex-col gap-10">
                                <div className="w-full flex flex-col gap-5">
                                    {
                                        postData?.postContent.length > 0 ? postData?.postContent.map((section, index) => {
                                            return(
                                                <>
                                                    <p key={index} className="text-xl sm:text-3xl font-bold" id={`section-${index}`}>
                                                        {`Section : ${index + 1} - ${section?.sectionName}`}
                                                    </p>

                                                    <div>
                                                        {
                                                            section.subSection.map((subSection, subIndex) => {
                                                                return(
                                                                    <div key={subIndex} id={`subsection-${index}-${subIndex}`}
                                                                    className="flex flex-col gap-5 mb-10">
                                                                        <p className="font-semibold text-[2rem] sm:text-[2.5rem] lg:text-[3.2rem] text-center">
                                                                            {subSection?.title}
                                                                        </p>

                                                                        <img src={subSection?.imageUrl.url} alt={subSection?.title} loading="lazy"
                                                                        className="w-full object-cover rounded-xl"/>

                                                                        <p className="font-semibold lg:text-xl">Description:</p>
                                                                        <p className="text-justify text-sm xl:text-[1.2rem] xl:leading-normal">{subSection?.description}</p>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </>
                                            )
                                        }) : (<div className="flex flex-col items-center justify-center mt-5 gap-4"> <TbMoodEmpty fontSize={"3rem"}/>No sections available right now</div>)
                                    }
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="w-fit mx-auto lg:w-3/12 px-5 lg:px-0 rounded-xl bg-white border-2 border-slate-300 shadow-lg h-fit">
                        <div className="flex flex-col p-5 xl:p-10 gap-4">
                            <p className="text-lg xl:text-2xl font-bold">Table of Contents</p>
                            <div className="w-full bg-slate-200 h-[2px]"></div>
                            <div>
                                {
                                    postData?.postContent.length > 0 ? postData?.postContent.map((section, index) => {
                                        return(
                                            <>
                                                <p key={index} className="xl:my-2 flex xl:gap-3 text-sm xl:text-xl items-center font-semibold">
                                                    <p className="flex gap-4 hover:text-blue-600 transition-all duration-300 xl:p-3 rounded-lg hover:bg-slate-100 w-full cursor-pointer"
                                                    onClick={() => scrollToSection(`section-${index}`)}>
                                                        <p className="">{index+1}.</p>
                                                        <p className="">{section?.sectionName}</p>
                                                    </p>
                                                </p>
                                                <div>
                                                    {
                                                        section.subSection.map((subSection, subIndex) => {
                                                            return(
                                                                <p onClick={() => scrollToSection(`subsection-${index}-${subIndex}`)} className="pl-5 text-[12px] xl:text-base flex gap-3 uppercase font-medium items-center py-3 xl:p-5 hover:bg-slate-100 
                                                                transition-all duration-300 rounded-xl hover:font-bold cursor-pointer" key={subIndex}>
                                                                    <IoIosArrowForward />{subSection.title}
                                                                </p>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </>
                                        )
                                    }) : (<div className="flex flex-col items-center justify-center mt-5 gap-4"> <TbMoodEmpty fontSize={"3rem"}/>No sections available right now</div>)
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {/* Likes and comments */}
                <div className="w-full sm:p-5 xl:px-16">
                    <div className="w-full lg:w-9/12 bg-white rounded-xl flex flex-col xl:p-5 xl:py-10 xl:gap-5">
                        <div className="flex items-center gap-3 px-10 mx-auto sm:mx-0">
                            <LikeButton postId={postData?._id} />
                        </div>
                        <div className="w-11/12 mx-auto h-[2px] bg-slate-200"></div>                
                        <div className="w-full flex flex-col justify-center gap-3 p-5">
                            <CommentSection postId={postData._id} />
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
};

export default PostDetails;