import { setLoading } from '../../slices/authSlice';
import toast from 'react-hot-toast';
import { setToken } from '../../slices/authSlice';
import { resetDraft } from '../../slices/draftSlice';
import { setUser } from '../../slices/profileSlice';
import { setPinnedPostIds } from '../../slices/postSlice';
import { apiConnector } from '../apiconnector';
import { endpoints } from '../apis';
import { postEndpoints } from '../apis';

const {
    SENDOTP_API,
    SIGNUP_API,
    LOGIN_API,
    RESETPASSWORD_API,
    RESETPASSTOKEN_API
} = endpoints;


export function sendOtp(email, navigate){
    return async (dispatch) => {
        const toastId = toast.loading("Loading...")
        dispatch(setLoading(true));
        try{
            const response = await apiConnector("POST", SENDOTP_API, {
                email,
                checkUserPresent: true,
            })
            //console.log("SENDOTP API RESPONSE............", response)

            //console.log(response.data.success);

            if(!response.data.success){
                throw new Error(response.data.message);
            }

            toast.success("OTP Sent Successfully");
            navigate("/verify-email");
        }
        catch(error){
            //console.log("SENDOTP API ERROR..............", error);
            toast.error("Could not send OTP");
        }

        dispatch(setLoading(false));
        toast.dismiss(toastId);
    }
}

export function signUp(
    accountType,
    firstName,
    lastName,
    email,
    password,
    confirmPassword,
    otp,
    navigate
) {
    return async (dispatch) => {
        const toastId = toast.loading("Loading...");
        dispatch(setLoading(true));
        try{
            const response = await apiConnector("POST", SIGNUP_API, {
                accountType,
                firstName,
                lastName,
                email,
                password,
                confirmPassword,
                otp,
            })

            //console.log("SIGNUP API RESPONSE..........", response);

            if(!response.data.success){
                throw new Error(response.data.message)
            }
            toast.success("Signup Successful");
            navigate("/login")
        }
        catch(error){
            //console.log("SIGNUP API ERROR", error);
            toast.error("Signup Failed");
            navigate("/signup");
        }
        dispatch(setLoading(false));
        toast.dismiss(toastId)
    }
}

export function login(email, password, navigate){
    return async (dispatch) => {
        const toastId = toast.loading("Loading...");
        dispatch(setLoading(true));
        try{
            const response = await apiConnector("POST", LOGIN_API, {
                email,
                password,
            })

            //console.log("LOGIN API RESPONSE..............", response);

            if(!response.data.success){
                throw new Error(response.data.message)
            }

            toast.success("Login Successful")
            //for testing
            //console.log("RESPONSE", response);
            dispatch(setToken(response.data.token))
            const userImage = response.data?.user?.image
            ? response.data.user.image
            : `https://api.dicebear.com/8.x/initials/svg?seed=${response.data.user.firstName} ${response
                .data.user.lastName}`
            
            dispatch(setUser({ ...response.data.user, image: userImage }))
            
            localStorage.setItem("token", JSON.stringify(response.data.token))
            localStorage.setItem("user", JSON.stringify(response.data.user))

            navigate("/dashboard/my-profile")
        }
        catch(error){
            //console.log("LOGIN API ERROR................", error)
            toast.error("Login Failed");
        }

        dispatch(setLoading(false));
        toast.dismiss(toastId);
    }
}

export function logout(navigate){
    return(dispatch) => {
        dispatch(setToken(null))
        dispatch(setUser(null))
        dispatch(resetDraft(null))
        localStorage.removeItem("token")
        localStorage.removeItem("user")
        toast.success("Logged Out")
        navigate("/")
    }
}

export function getPasswordResetToken(email, setEmailSent){
    return async (dispatch) => {
        dispatch(setLoading(true));
        try{
            const response = await apiConnector("POST", RESETPASSTOKEN_API, {
                email
            })
            //console.log("RESET PASSWORD TOKEN RESPONSE....", response);

            if(!response.data.success){
                throw new Error(response.data.message);
            }

            toast.success("Reset Email Sent");
            setEmailSent(true);
        }
        catch(error){
            //console.log("RESET PASSWORD TOKEN ERROR...", error);
            toast.error("Failed to send reset Email");
        }

        dispatch(setLoading(false));
    }
}

export function resetPassword(password, confirmPassword, token){
    return async (dispatch) => {
        dispatch(setLoading(true));
        try{
            const response = await apiConnector("POST", RESETPASSWORD_API, {
                password,
                confirmPassword,
                token
            });
            //console.log("RESET PASSWORD RESPONSE.......", response);

            if(!response.data.success){
                throw new Error(response.data.message);
            }

            toast.success("Password Reset Successfull");
        }
        catch(error){
            //console.log("RESET PASSWORD Error", error);
            toast.error("Failed to Reset Password");
        }
        
        dispatch(setLoading(false));
    }
}