import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState } from "react";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { deleteAccount } from '../../../../services/operations/profileAPI';
import ConfirmationModal from '../../../common/ConfirmationModal';


const DeleteAccount = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {token} = useSelector((state) => state.auth);

    const [confirmationModal, setConfirmationModal] = useState(null);

    const handleDeleteAccount = () => {
        dispatch(deleteAccount(token, navigate));
    }

    return(
        <div className="rounded-3xl shadow-lg border-4 border-red-700 p-10 mt-10 relative z-20">
            {/* section 1 */}
            <div>
                <div className="flex flex-col lg:flex-row gap-10 justify-between items-center">
                    <div className="flex flex-col gap-3">
                        <p className="text-5xl font-bold overflow-visible">Delete Account</p>
                        <p className="font-bold text-red-600">Would you like to delete your account?</p>
                        <p>Deleting your account will permanently remove all your data associated with it.</p>
                    </div>

                    <div>
                        <button 
                            onClick={() => setConfirmationModal({
                                text1:"Are you Sure?",
                                text2:"Deleting the account is irreversible process",
                                btn1Text:"Delete",
                                btn2Text:"Cancel",
                                btn1Handler: () => handleDeleteAccount(),
                                btn2Handler: () => setConfirmationModal(null)
                        })}
                            className='bg-red-600 text-white hover:bg-red-700 p-4 rounded-2xl transition-all duration-300'
                        >
                            <MdOutlineDeleteOutline fontSize={24} />
                        </button>

                    </div>
                </div>
            </div>
            {
                confirmationModal && 
                <ConfirmationModal modalData={confirmationModal} />
            }   
        </div>
    )
};

export default DeleteAccount;