import React from "react";
import "./Card.css";
import { useNavigate } from "react-router-dom";
import { FaQuestionCircle } from "react-icons/fa";

const Card = ({text}) => {

    const navigate = useNavigate();

    return(
        <div className="card w-full sm:w-[350px] mx-auto flex flex-col items-center justify-center gap-4">
            <FaQuestionCircle fontSize={28} className="text-white"/>
            <p className="text-justify text-white">In order to create the {text}, We request the users to first go through the guide in Help Center <b> (Managing/Creating Post) </b> of how to create and Manage Posts.</p>
            <p className="px-5 py-2 rounded-lg bg-[#3A7E8D] hover:bg-[#255b67] transition-all duration-300 text-white cursor-pointer" onClick={() => navigate('/dashboard/help')}>Help Center</p>
            <p className="w-full text-center p-3 bg-white text-black font-semibold rounded-lg">Note: Attribution of the Image used in the posts is mandatory.</p>
        </div>
    )
};

export default Card;