import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { apiConnector } from "../services/apiconnector";
import { storiesEndpoints } from "../services/apis";
import Spinner from "../components/Spinner";
import { formatDate } from "../services/formatDate";
import StoryLikeButton from "../components/core/Stories/StoryLikeButton";
import { IoIosLink } from "react-icons/io";
import { FaFacebook } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { toast } from "react-hot-toast";

const StoryDetails = () => {

    const {storyId} = useParams();
    const [storyData, setStoryData] = useState();
    const [loading, setLoading] = useState();

    const {GET_STORY_DETAILS_API} = storiesEndpoints;

    useEffect(() => {
        const getFullStoryDetails = async () => {
            setLoading(true);
            const response = await apiConnector("POST", GET_STORY_DETAILS_API, {storyId});
            // console.log("RESPONSE", response.data);
            setStoryData(response.data.data);
            setLoading(false);
        }
        if(storyId){
            getFullStoryDetails(storyId);
        }
    }, [storyId])

    // useEffect(() => {
    //     console.log("STORY DATA", storyData);
    // })

    if(loading || !storyData){
        return(
            <Spinner />
        )
    }

    const sharePage = (platform) => {
        const url = window.location.href;
      
        switch (platform) {
          case 'copy':
            navigator.clipboard.writeText(url).then(() => {
              toast.success("Copied to Clipboard");
            });
            break;
          case 'facebook':
            window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`, '_blank');
            break;
          case 'whatsapp':
            window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(url)}`, '_blank');
            break;
          case 'twitter':
            window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`, '_blank');
            break;
          default:
            break;
        }
      };

    return(
        <div className="w-screen">
            
            <div className="max-w-[1710px] mt-10 mx-auto mb-10 flex flex-col gap-5 p-2">

                <h1 className="text-[5.5rem] mx-auto font-bold">STORY</h1>

                <div className="bg-white w-11/12 lg:w-9/12 xl:w-9/12 2xl:w-9/12 rounded-xl mx-auto shadow-md flex flex-col-reverse lg:flex-row xl:flex-row 2xl:flex-row  gap-5 p-5">
                    <div className="w-full lg:w-5/12 xl:w-5/12 2xl:w-5/12 rounded-xl flex flex-col gap-5 py-4 overflow-auto">

                        <div className="flex items-center gap-3">
                            <img src={storyData?.user?.image?.url} alt={storyData?.user?.firstName} 
                            className=" aspect-square rounded-full w-[40px] border-2 border-black"/>
                            <h1>{storyData?.user?.firstName} {storyData?.user?.lastName}</h1>
                        </div>

                        <div className="sm:px-10 lg:px-10 xl:px-10 2xl:px-10">
                            <h1 className="font-bold capitalize text-2xl sm:text-3xl lg:text-2xl xl:text-3xl 2xl:text-3xl">{storyData?.storyName}</h1>
                            <p className="text-slate-400 text-sm sm:text-base lg:text-sm xl:text-base 2xl:text-base">Uploaded By Jeviknows</p>   
                        </div>

                        <div className="flex flex-col sm:flex-row lg:flex-col xl:flex-row 2xl:flex-row bg-white shadow-md rounded-xl mx-auto p-4 
                        justify-center items-center sm:space-x-5 xl:space-x-5 2xl:space-x-10 px-6 gap-y-3">
                            
                            <div className="flex flex-col items-center sm:max-lg:bg-slate-200 sm:max-lg:rounded-lg sm:max-lg:p-2">
                                <p className="font-semibold text-2xl">{storyData?.theme}</p>
                                <p className="text-sm">Theme</p>
                            </div>
                            <div className="w-full bg-slate-300 h-[2px] sm:w-[2px] sm:h-full lg:w-full lg:h-[2px] xl:w-[2px] xl:h-full 2xl:w-[2px] 2xl:h-full"></div>
                            <div className="flex flex-col items-center sm:max-lg:bg-slate-200 sm:max-lg:rounded-lg sm:max-lg:p-2">
                                <p className="font-semibold text-2xl">{storyData?.timeTaken}</p>
                                <p className="text-sm">Time Taken</p>
                            </div>
                            <div className="w-full bg-slate-300 h-[2px] sm:w-[2px] sm:h-full lg:w-full lg:h-[2px] xl:w-[2px] xl:h-full 2xl:w-[2px] 2xl:h-full"></div>
                            <div className="flex flex-col items-center sm:max-lg:bg-slate-200 sm:max-lg:rounded-lg sm:max-lg:p-2">
                                <p className="font-semibold text-2xl">{storyData?.storyLikes?.length}</p>
                                <p className="text-sm">Likes</p>
                            </div>
                            
                        </div>

                        <div className="sm:px-10">
                            <p><span className="font-semibold">Speciality: </span>{storyData?.speciality}</p>
                        </div>

                        <div className="sm:px-10 flex flex-col gap-5 pb-5">
                            <p className="font-semibold">Story</p>

                            <div className="bg-white shadow-lg p-4 rounded-xl text-justify border-2 border-slate-300">
                                <p>{storyData?.storyContent}</p>
                            </div>
                        </div>

                        <div className="sm:px-10">
                            <p><span className="font-semibold">Uploaded At: </span>{formatDate(storyData?.createdAt)}</p>
                        </div>

                        <div className="px-6 mx-auto bg-white shadow-md border-2 border-slate-300 rounded-xl">
                            <p><StoryLikeButton storyId={storyData._id} /></p>
                        </div>

                        <div className="flex lg:max-xl:flex-col items-center gap-4 mt-5 sm:px-10">
                            <p className="font-semibold">Share this on:</p>
                            <div className="flex gap-5 items-center text-xl pl-1">
                                <button className="border border-slate-300 rounded-2xl hover:text-[#181820] p-2 hover:bg-slate-300 transition-all duration-300" onClick={() => sharePage('copy')}><IoIosLink /></button>
                                <button className="border border-slate-300 rounded-2xl hover:text-[#181820] p-2 hover:bg-slate-300 transition-all duration-300" onClick={() => sharePage('facebook')}><FaFacebook /></button>
                                <button className="border border-slate-300 rounded-2xl hover:text-[#181820] p-2 hover:bg-slate-300 transition-all duration-300" onClick={() => sharePage('whatsapp')}><FaWhatsapp /></button>
                                <button className="border border-slate-300 rounded-2xl hover:text-[#181820] p-2 hover:bg-slate-300 transition-all duration-300" onClick={() => sharePage('twitter')}><FaXTwitter /></button>
                            </div>
                        </div>

                    </div>

                    <div className="w-full lg:w-7/12 xl:w-7/12 2xl:w-7/12 bg-white rounded-xl flex flex-col gap-5">

                        <div className="">
                            <img src={storyData?.firstImage?.url} alt={storyData?.storyName} 
                            className="w-full object-cover overflow-hidden rounded-xl"/>
                        </div>

                        <div className="w-full flex gap-5">

                            <div className="w-6/12">
                                <img src={storyData?.secondImage?.url} alt={storyData?.storyName} 
                                className="w-full object-cover overflow-hidden rounded-xl h-full"/>
                            </div>
                            <div className="w-6/12">
                                <img src={storyData?.thirdImage?.url} alt={storyData?.storyName} 
                                className="w-full object-cover overflow-hidden rounded-xl h-full"/>
                            </div>

                        </div>

                    </div>
                </div>

            </div>

        </div>
    )
};

export default StoryDetails;