import _ from "lodash";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { searchPost } from "../../../services/operations/postDetailsAPI";
import { LuSearch } from "react-icons/lu";
import { IoMdHeart } from "react-icons/io";

const SearchBar = () => {

    const navigate = useNavigate();
    const [query, setQuery] = useState();
    const [suggestions, setSuggestions] = useState();

    //debounced search function
    const debouncedSearch = useCallback(
        _.debounce(async (searchQuery) => {
            if(searchQuery){
                try{
                    const response = await searchPost(searchQuery);
                    //console.log("RESPONSE", response);
                    setSuggestions(response || []);
                }
                catch(error){
                    console.error("Error fetching search results", error);
                }
            }
            else{
                setSuggestions([]);
            }
        }, 500),     //delay
        []
    );

    const handleInputChange = (e) => {
        const searchQuery = e.target.value;
        setQuery(searchQuery);
        debouncedSearch(searchQuery);
    }

    const handleSelectPost = (postId) => {
        setSuggestions([]);
        navigate(`/posts/${postId}`);
    }

    return(
        <div className="w-full">
            <div className="relative rounded-lg">
                <label className="absolute right-3 top-3 z-10"><LuSearch fontSize={26}/></label>
                    <input 
                        type="text"
                        value={query}
                        onChange={handleInputChange}
                        placeholder="Search for posts..."
                        className="text-black backdrop-blur-sm p-2 rounded-lg shadow-md w-full h-[50px] focus:outline-none"
                    />
            </div>
            {
                suggestions?.length > 0 && (
                    <div className="bg-white transition-all duration-300 ease-in-out mt-2 space-y-2 rounded-lg p-4 w-full max-h-[400px] overflow-auto">
                        {
                            suggestions.slice(0, 10).map(post => (
                                <>
                                    <div key={post._id} 
                                        onClick={() => handleSelectPost(post._id)}
                                        className="duration-300 ease-in-out hover:bg-slate-200 transition-all rounded-lg cursor-pointer flex items-center">
                                        <div className="p-3 w-9/12">
                                            <h4 className="font-bold text-lg">{post.postName}</h4>
                                            <p className="text-sm">{post.postDescription.substring(0, 35)}...</p>
                                            <span className="flex gap-4 items-center"><IoMdHeart className="text-red-500"/> {post?.likes.length}</span>
                                        </div>
                                        <div className="p-3">
                                            <img src={post?.thumbnail?.url} className="w-[100px] h-[100px] rounded-lg object-cover" alt="image"/>
                                        </div>
                                    </div>
                                    {
                                        suggestions.length > 1 && <div className="w-full h-[2px] bg-slate-200"></div>
                                    }
                                </>
                            ))
                        }
                    </div>
                )
            }
        </div>
    )
}

export default SearchBar;

