import React from "react";
import './StorySlider.css'
import { IoMdHeart } from "react-icons/io";
import { Link } from "react-router-dom";
import { TiArrowRight } from "react-icons/ti";

const StorySlider = ({stories = []}) => {

    const truncateText = (text = '', wordLimit) => {
      const words = text.split(' ');
      return words.length > wordLimit ? `${words.slice(0, wordLimit).join(' ')}...` : text;
    };

  return(
    <>
      <div className="flex flex-wrap gap-[25px] lg:ml-[15px] xl:ml-[15px] 2xl:ml-[15px] justify-center">
          {
            stories?.map((story, index) => {

              return(
                <>
                  
                  <div className="flex gap-[10px] flex-col h-auto w-full lg:w-[350px] xl:w-[350px] 2xl:w-[350px] rounded-[10px] cursor-pointer 
                  transition-all duration-400 shadow-md p-3 bg-white" key={index}>
                    <img src={story?.firstImage?.url} alt={story?.storyName} loading="lazy"
                    className="w-full object-cover rounded-lg overflow-hidden aspect-video"/>
                    <div className="overflow-hidden space-y-2">
                      <p className="text-black text-lg font-semibold  ml-2">{story?.storyName}</p>
                      <p className="text-slate-400 text-sm ml-2">{truncateText(story?.storyContent, 9)}</p>
                      <p className="flex gap-2 items-center"><IoMdHeart className="text-red-500 ml-2"/>{story?.storyLikes?.length}</p>
                      <Link to={`/stories/${story?._id}`}>
                        <button className="ml-2 bg-[#3A7E8D] hover:bg-[#245661] text-white px-4 py-2 flex gap-3 justify-center items-center my-2 rounded-lg
                        transition-all duration-300">
                          View More <TiArrowRight  fontSize={24}/>
                        </button>
                      </Link>
                    </div>
                  </div>
                </>
              )
            })
          }
      </div>
    </>
  )
};

export default StorySlider;