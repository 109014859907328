import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";

import { getPasswordResetToken } from "../services/operations/authAPI";

const ForgotPassword = () => {

    const [emailSent, setEmailSent] = useState(false);

    const [email, setEmail] = useState("");

    const {loading} = useSelector((state) => state.auth);

    const dispatch = useDispatch();

    const handleOnSubmit = (e) => {
        e.preventDefault();
        dispatch(getPasswordResetToken(email, setEmailSent));
    }

    return(
        <div className="w-screen flex flex-col p-3  justify-center items-center">
            {
                loading ? (
                    <div className="mt-[5rem]">Loading...</div>
                ) : 
                (
                    <div className="flex flex-col gap-5 w-full max-w-[500px] mt-[5rem] text-center bg-white shadow-lg ring-1 ring-black/5
                    rounded-2xl py-10 px-10  h-auto">
                        <h1 className="text-[1.875rem`] font-bold">
                            {
                                !emailSent ? "Reset Your Password" : "Check Email"
                            }
                        </h1>

                        <p>
                            {
                                !emailSent ? "Enter the email associated with your account and we'll send an email with instructions to reset your password." 
                                : `We have sent the reset password email to ${email}`
                            }
                        </p>

                        <form className="flex flex-col gap-4 justify-center"
                        onSubmit={handleOnSubmit}>
                            {
                                !emailSent && (
                                    <label>
                                        <input 
                                            required
                                            type="email"
                                            name="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            placeholder="Enter Email Address"
                                            className="px-3 py-4 rounded-xl focus:outline-none bg-[#dbedf1] w-[80%]"
                                        />
                                    </label>
                                )
                            }

                            <button className="w-fit px-6 mx-auto py-4 bg-red-400 rounded-xl font-semibold text-white
                            hover:bg-red-500 transition-all duration-300 hover:scale-95"
                            type="submit">
                                {
                                    !emailSent ? "Reset Password" : "Resend Email"
                                }
                            </button>
                        </form>

                        <div className="flex flex-col items-center ">
                            <Link to="/login">
                                <p className="font-semibold text-blue-600 flex items-center gap-2">
                                    Back to Login
                                    <MdKeyboardArrowRight fontSize={20}/>
                                </p>
                            </Link>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default ForgotPassword;