import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Post_Card = ({post, height}) => {

    const truncateText = (text = '', wordLimit) => {
        const words = text.split(' ');
        return words.length > wordLimit ? `${words.slice(0, wordLimit).join(' ')}...` : text;
    };

    // Parse and format the createdAt date
    const date = new Date(post.createdAt);
    const options = { day: 'numeric', month: 'short', year:'numeric'  };
    const formattedDate = date.toLocaleDateString('en-US', options);

    return(
        <div className="w-[80%] mx-auto lg:mx-0 lg:w-[40%] xl:w-[30%] relative">
            <Link to={`/posts/${post._id}`}>
                <div className="bg-white rounded-3xl relative">
                    
                    <img src={post?.thumbnail?.url}
                    alt={post?.postName}
                    className={`rounded-xl object-cover w-full h-full aspect-square`}/>
                    
                    <div className="absolute w-fit text-white text-sm bottom-5 right-5 bg-black/50 backdrop-blur-sm p-2 rounded-lg shadow-md">
                        {/* Replace this text with your content */}
                        <p className="font-bold">
                            {post?.postName}
                        </p>
                        <p className="text-[10px]">{truncateText(post?.postDescription, 6)}</p>
                        <p className="text-[10px]">{formattedDate}</p>
                    </div>

                    <div className="absolute top-5 left-5 bg-white rounded-full border-4 border-white shadow-md">
                        <img src={post?.user?.image?.url} className=" w-[40px] h-[40px] rounded-full" alt="image"/>
                    </div>
                </div>
            </Link>
        </div>
    )
};

export default Post_Card;