import { useEffect, useState } from 'react';
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAdminPostDetails, getUserPosts } from "../../../services/operations/blogDetailsAPI";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import IconBtn from '../../common/IconBtn';
import PostsCard from './Posts/PostsCard';
import { apiConnector } from '../../../services/apiconnector';
import { postEndpoints } from '../../../services/apis';
import { setPinnedPostIds } from '../../../slices/postSlice';
import { AiFillEdit } from "react-icons/ai";
import EmptyLoader from "../../EmptyLoader";

const UploadedPosts = () => {
    const { token } = useSelector((state) => state.auth);
    const { user } = useSelector((state) => state.profile);
    const { pinnedPostIds } = useSelector((state) => state.post);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [posts, setPosts] = useState([]);

    const getPosts = async () => {
        try {
            let response;
            if (user.accountType === "Admin") {
                response = await getAdminPostDetails(token);
            } else {
                response = await getUserPosts(token);
            }
            if(response){
                setPosts(response);
            }
            
        } catch (error) {
            console.log("Error fetching posts", error);
        }
    }

    useEffect(() => {
        getPosts();
        getPinPosts();
    }, []);

    // useEffect(() => {
    //     console.log("DISPATCED FUNCTION RESPONSE OF SETPINNEDPOSTIDS", pinnedPostIds);
    // })

    const { GET_PINNED_POSTS } = postEndpoints;

    const getPinPosts = async () => {
        try{
            const userId = user._id;
            //console.log("USERID", userId);
            const response = await apiConnector("POST", GET_PINNED_POSTS, { userId }, {
                Authorization: `Bearer ${token}`,
            });
            //const result = response.json();
            //console.log("RESPONSE REACHED", response.data.data);
            if(response.data.success){
                //toast.success("FETCHED");
                dispatch(setPinnedPostIds(response.data.data));
            }
            else{
                //toast.error("NOT FETCHED");
                dispatch(setPinnedPostIds([]));
            }
        }

        catch(error){
            console.log("ERROR FETCHING PINNED POSTS", error);
        }
    }

    const truncateText = (text = '', wordLimit) => {
        const words = text.split(' ');
        return words.length > wordLimit ? `${words.slice(0, wordLimit).join(' ')}...` : text;
    };

    return (
        <div className='px-8 py-8 lg:px-[6rem] lg:py-[5rem] overflow-y-auto bg-white min-h-[790px] space-y-5'>

            <div className='flex justify-between'>
                <h1 className='text-4xl font-bold overflow-hidden'>My Posts</h1>
                {/* Buttons */}
                <div className='flex gap-4 items-center'>
                    <IconBtn 
                        text={<>Create Post <FaPlus fontSize={10} /></>}
                        customClasses={"hidden sm:flex gap-2 items-center text-md text-white bg-blue-600 hover:bg-blue-700 transition-all duration-300 px-4 py-2 rounded-lg"}
                        onClick={() => navigate("/dashboard/create-post")}
                    />
                    <IconBtn 
                        text={<><FaPlus fontSize={10} /></>}
                        customClasses={"flex sm:hidden gap-2 items-center text-md text-white bg-blue-600 hover:bg-blue-700 transition-all duration-300 px-4 py-4 rounded-lg"}
                        onClick={() => navigate("/dashboard/create-post")}
                    />
                </div>
            </div>

            <div className='w-full'>

                <div className='w-full flex flex-col sm:flex-row flex-wrap gap-5 mx-auto'>

                    {
                        posts && <PostsCard posts={posts} setPosts={setPosts} />
                    }

                </div>

            </div>

            <div>

                <div className='w-full rounded-xl text-center space-y-10'>
                        <h1 className='text-4xl font-bold overflow-hidden text-left'>Pinned Posts</h1>
                        <div className='w-full flex flex-col sm:flex-row flex-wrap gap-5 mx-auto'>
                        {
                            pinnedPostIds.length > 0 ? pinnedPostIds.map((post, index) => {

                                // Parse and format the createdAt date
                                const date = new Date(post.createdAt);
                                const options = { month: 'short', day: 'numeric' };
                                const formattedDate = date.toLocaleDateString('en-US', options);

                                return(
                                        <div key={index} className='w-full sm:w-[40%] lg:w-[30%] p-2 bg-[#EED3BA] shadow-md rounded-lg flex overflow-hidden hover:shadow-lg 
                                        transition-all duration-300 items-center'>
                                            <div className='overflow-hidden w-1/3 justify-center'>
                                                <img src={post?.thumbnail?.url} alt={`${post?.postName}`} loading='lazy'
                                                className='w-full aspect-square rounded-lg object-cover'/>
                                            </div>
                                            <div className='ml-3 py-2 text-left space-y-1 w-2/3 text-[#8D493A]'>
                                                <p className='text-left text-sm'>{truncateText(post?.postName, 8)}</p>
                                                <p className={`text-sm font-semibold ${post?.status === "Draft" ? "text-red-600" : "text-green-600"}`}>{post.status}</p>
                                                <p className='text-sm'>{formattedDate}</p>
                                                <div className='space-x-4'>
                                                    <button 
                                                        onClick={() =>{
                                                            navigate(`/dashboard/edit-post/${post?._id}`)
                                                        }}
                                                        className='bg-blue-500 text-white hover:bg-blue-600 transiton-all duration-300 rounded-md p-2 text-sm'><AiFillEdit /></button>
                                                </div>
                                            </div>
                                        </div>
                                )
                            }) :
                            (
                                <div className='flex flex-col items-center gap-4'>
                                    <p>No Posts Pinned</p>
                                    <p><EmptyLoader /></p>
                                </div>
                            )
                        }
                        </div>
                    </div>

            </div>
            
        </div>
    )
};

export default UploadedPosts;
