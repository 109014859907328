import { useForm } from 'react-hook-form';
import React, { useState } from "react";
import IconBtn from '../../../../common/IconBtn';
import { GoPlusCircle } from "react-icons/go";
import { IoIosArrowForward } from "react-icons/io";
import { useDispatch, useSelector } from 'react-redux';
import { setEditPost, setStep, setPost } from '../../../../../slices/postSlice';
import toast from 'react-hot-toast';
import { createSection, updateSection } from '../../../../../services/operations/postDetailsAPI';
import NestedView from './NestedView';
const PostBuilderForm = () => {

    const {register, handleSubmit, setValue, formState: {errors}} = useForm();
    const [editSectionName, setEditSectionName] = useState(null);
    const {post} = useSelector((state) => state.post);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const {token} = useSelector((state) => state.auth);

    const onSubmit = async (data) => {
        setLoading(true);
        let result;

        if(editSectionName) {
            //we are editing the section name
                result = await updateSection(
                {
                    sectionName: data.sectionName,
                    sectionId: editSectionName,
                    postId: post._id
                }, token
            )
            //for testing
            //console.log("AFTER UPDATION RESULT:", result)
        }
        else {
            result = await createSection(
                {
                    sectionName: data.sectionName,
                    postId: post._id
                }, token
            )
            //console.log("AFTER CREATION RESULT", result)
        }

        //update values
        if(result){
            dispatch(setPost(result));
            setEditSectionName(null);
            setValue("sectionName", "");
        }

        //loading false
        setLoading(false);
    }

    const cancelEdit = () => {
        setEditSectionName(null);
        setValue("sectionName", "");
    }

    const goBack = () => {
        dispatch(setStep(1));
        dispatch(setEditPost(true));
    }

    const goToNext = () => {
        if(post.postContent.length === 0){
            toast.error("Please add atleast one Section");
            return;
        }
        if(post.postContent.some((section) => section.subSection.length === 0)){
            toast.error("Each section must have atleast one sub-section");
            return;
        }
        //if all is good
        dispatch(setStep(3));
    }

    const handleChangeEditSectionName = (sectionId, sectionName) => {

        if(editSectionName === sectionId){
            cancelEdit();
            return;
        }

        setEditSectionName(sectionId);
        setValue("sectionName", sectionName);
    }

    return(
        <>
            <div className="flex items-center gap-4 mt-6">
                <div className="p-2 w-[35px] h-[35px] bg-black text-white rounded-full flex items-center justify-center overflow-hidden"> 
                    2
                </div>
                <p className="text-2xl font-light">Post Builder</p>
            </div>

            <form className='className="rounded-lg space-y-8 mt-8'
                onSubmit={handleSubmit(onSubmit)}>
                <div className='mb-4'>
                    <label htmlFor='sectionName' className="block font-bold mb-2">
                        Section Name <span className="text-blue-500">*</span>
                    </label>
                    <input
                        type="text"
                        id="sectionName"
                        className="w-full rounded-md p-2 focus:outline-none bg-gray-200 text-black"
                        placeholder="Enter Section Name"
                        {...register("sectionName", {required:true})}
                    />
                    {
                        errors.sectionName && <span className="text-red-500">This field is required</span>
                    }
                </div>

                <div className='flex gap-2 items-center'>
                    <IconBtn 
                        type="submit"
                        text={editSectionName ? <> Edit Section Name <GoPlusCircle /></> :<> Create Section <GoPlusCircle /></>}
                        customClasses="rounded-xl text-blue-500 font-bold border-2 border-blue-500 p-3 px-6 flex gap-3 items-center"
                    />
                    {
                        editSectionName && 
                        <button 
                            type='button'
                            onClick={cancelEdit}
                            className="text-red-500">
                            Cancel Edit
                        </button>
                    }
                </div>
            </form>

            {
                post?.postContent?.length > 0 && (
                    <NestedView handleChangeEditSectionName={handleChangeEditSectionName}/>
                )
            }

            <div className='flex justify-end items-center px-2 gap-5 py-2 mt-6'>

                <button
                    onClick={goBack}
                    className='bg-slate-200 px-6 py-3 rounded-lg hover:bg-slate-300 transition-all duration-300'
                >
                    Back
                </button>


                <IconBtn
                    text={<>Next <IoIosArrowForward /></>}
                    type="submit"
                    onClick={goToNext}
                    customClasses={"px-6 py-3 bg-blue-600 hover:bg-blue-700 transition-all duration-300 rounded-lg text-white flex items-center gap-2"}
                />
            </div>
        </>
    )
};

export default PostBuilderForm;