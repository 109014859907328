import React, { useEffect, useState } from "react";
import EmptyLoader from "../../EmptyLoader";
import { apiConnector } from "../../../services/apiconnector";
import { metricsEndpoints } from "../../../services/apis";
import { useSelector } from "react-redux";
import { contactusEndpoints } from "../../../services/apis";
import 'chart.js/auto'
import { Line, Doughnut } from "react-chartjs-2";
import { formatDate } from "../../../services/formatDate";

const AdminDashboard = () => {

    const {token} = useSelector((state) => state.auth);

    const [registrationMetrics, setRegistrationMetrics] = useState({
        lastMonthRegistrations: 0,
        last20DaysRegistrations: 0,
        last10DaysRegistrations: 0,
    });

    const [postMetrics, setPostMetrics] = useState({
        lastMonthPosts: 0,
        last20DaysPosts: 0,
        last10DaysPosts: 0,
    });

    const [contactForms, setContactForms] = useState([]);

    const [loading, setLoading] = useState(false);

    const { GET_POST_METRICS_API, GET_USER_REGISTRATION_METRICS_API } = metricsEndpoints;
    const {GET_ALL_CONTACT_FORMS_API} = contactusEndpoints;

    useEffect(() => {
        setLoading(true);
        const fetchAllMetrics = async () => {
            try{
                const userMetricsResponse = await apiConnector("GET", GET_USER_REGISTRATION_METRICS_API, null, {
                    Authorization: `Bearer ${token}`
                });

                //console.log("USER METRICS RESPONSE", userMetricsResponse);
                setRegistrationMetrics(userMetricsResponse.data.data);

                const postMetricsResponse = await apiConnector("GET", GET_POST_METRICS_API, null, {
                    Authorization: `Bearer ${token}`
                });

                //console.log("POST METRICS RESPONSE", postMetricsResponse);
                setPostMetrics(postMetricsResponse.data.data);

                const contactFormsResponse = await apiConnector("GET", GET_ALL_CONTACT_FORMS_API, null, {
                    Authorization: `Bearer ${token}`
                });

                //console.log("CONTACT FORMS RESPONSE", contactFormsResponse);
                setContactForms(contactFormsResponse.data.data);
            }
            catch(error){
                console.log(error);
                setLoading(false);
            }
            setLoading(false);
        }

        fetchAllMetrics();
    },[]);

    //data for line chart
    const lineData = {
        labels: ['Last Month', 'Last 20 Days', 'Last 10 Days'],
        datasets: [
            {
                label: 'User Registrations',
                data: [
                    registrationMetrics.lastMonthRegistrations,
                    registrationMetrics.last20DaysRegistrations,
                    registrationMetrics.last10DaysRegistrations,
                ],
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
                fill: true,
            },
        ],
    };

    // Data for the Doughnut Chart
    const doughnutData = {
        labels: ['Last Month', 'Last 20 Days', 'Last 10 Days'],
        datasets: [
            {
                label: 'Posts Created',
                data: [
                    postMetrics.lastMonthPosts,
                    postMetrics.last20DaysPosts,
                    postMetrics.last10DaysPosts,
                ],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };
    
    if(loading){
        return(
            <div className="w-full items-center justify-center flex bg-white">
                <EmptyLoader />
            </div>
            
        )
    }

    return(
        <div className="py-8 px-8 lg:px-[6rem] lg:py-[5rem] overflow-y-auto bg-white flex gap-10 w-full flex-col items-center">

            <h1 className="text-[3rem] sm:text-[5.5rem] text-center font-bold">Admin Dashboard</h1>
             
            <div className="flex w-full flex-col lg:flex-row justify-between gap-y-10 lg:gap-y-0">

                <div className="w-full lg:w-1/2 flex flex-col items-center gap-4">
                    <h2 className="font-semibold text-center text-2xl">User Registrations</h2>
                    <Line data={lineData} />
                </div>

                <div className="w-full lg:w-4/12 flex flex-col items-center gap-4">
                    <h2 className="font-semibold text-center text-2xl">Posts Created</h2>
                    <Doughnut data={doughnutData} />
                </div>

            </div>

            <div className="flex flex-col gap-5 p-5">
                <h1 className="text-2xl font-semibold">Contact Forms Information</h1>

                <div className="flex flex-col flex-wrap sm:flex-row gap-3 w-full p-4">

                    {
                        contactForms?.length === 0 ? (<div className="w-full flex flex-col items-center justify-center"><EmptyLoader /> <p>No Forms Available at the moment</p></div>) : 
                        (
                            contactForms?.map((form, index) => {
                                return(
                                    <div key={index} className="w-fit flex flex-col gap-4 rounded-lg shadow-md bg-white">
                                        <p className="flex gap-4 items-center text-lg font-bold px-5 pt-5">{form?.firstName} {form?.lastName}
                                            <span className="text-slate-400 text-sm font-normal">{formatDate(form?.submittedAt)}</span>
                                        </p>
                                        <a className="flex gap-4 items-center text-sm px-5" href={`mailto:${form?.email}`}>{form?.email}</a>
                                        <p className="text-sm px-5">{form?.message}</p>
                                        <div className="bg-slate-100 w-full p-5">
                                            <p>{form?.contactNumber}</p>
                                        </div>

                                    </div>
                                )
                            })
                        )
                    }

                </div>
            </div>

        </div>
    )
};

export default AdminDashboard;