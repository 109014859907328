import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RxDropdownMenu } from "react-icons/rx";
import { BiSolidEditAlt } from "react-icons/bi";
import { FaAngleDoubleDown } from "react-icons/fa";
import { FaPlus } from "react-icons/fa6";
import SubSectionModal from './SubSectionModal';
import ConfirmationModal from '../../../../common/ConfirmationModal';
import { setPost } from '../../../../../slices/postSlice';
import { deleteSection, deleteSubSection } from '../../../../../services/operations/postDetailsAPI';
import { MdDelete } from "react-icons/md";
import { FaAngleRight } from "react-icons/fa6";
import { MdDeleteSweep } from 'react-icons/md';

const NestedView = ({handleChangeEditSectionName}) => {

    const {post} = useSelector((state) => state.post);
    const {token} = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const [addSubSection, setAddSubSection] = useState(null);
    const [editSubSection, setEditSubSection] = useState(null);
    const [viewSubSection, setViewSubSection] = useState(null);

    const [confirmationModal, setConfirmationModal] = useState(null);

    const handleDeleteSection = async (sectionId) => {
        const result = await deleteSection({
            sectionId,
            postId: post._id},
            token
        );

        //console.log("Result after Section Delete", result);

        if(result){
            dispatch(setPost(result));
        }
        setConfirmationModal(null);
    }

    const handleDeleteSubSection = async (subSectionId, sectionId) => {
        const result = await deleteSubSection({
            subSectionId,
            sectionId,
        }, token);

        if(result){
            const updatedPostContent = post.postContent.map((section) => 
                section._id === sectionId ? result : section);
            const updatedPost = {...post, postContent: updatedPostContent};
            dispatch(setPost(updatedPost));
        }
        setConfirmationModal(null);
    }

    return (
        <div key={post._id} className='relative'>
            <div className='rounded-lg bg-[#EED3BA] p-6 mt-8'>
                {
                    post?.postContent?.map((section) => (
                        <details key={section._id} open className='mb-5'>
                            <summary className='flex flex-col items-start gap-y-4 sm:gap-y-0 sm:flex-row sm:items-center justify-between gap-x-3'>
                                <div className='flex items-center gap-x-3 cursor-pointer'>
                                    <RxDropdownMenu fontSize={24} />
                                    <p className='text-lg font-semibold'>{section.sectionName}</p>
                                </div>
                                <div className='flex items-center gap-x-3 self-end'>
                                    <button
                                        onClick={() => handleChangeEditSectionName(section._id, section.sectionName)}
                                        className='bg-blue-600 p-2 rounded-xl text-white hover:bg-blue-700 transition-all duration-300'
                                    >
                                        <BiSolidEditAlt fontSize={20} />
                                    </button>

                                    <button
                                        className='bg-red-600 p-2 rounded-xl text-white hover:bg-red-700 transition-all duration-300'
                                        onClick={() => {
                                            setConfirmationModal({
                                                text1: "Delete this Section",
                                                text2: "All the Sub-Sections in this Section will be deleted",
                                                btn1Text: "Delete",
                                                btn2Text: "Cancel",
                                                btn1Handler: () => handleDeleteSection(section._id),
                                                btn2Handler: () => setConfirmationModal(null)
                                            })
                                        }}
                                    >
                                        <MdDelete fontSize={20} />
                                    </button>

                                    <span className='w-[2px] h-9 bg-slate-400 '></span>
                                    <div className='text-white bg-slate-300 hover:bg-slate-400 cursor-pointer transition-all duration-300 rounded-xl p-2'>
                                        <FaAngleDoubleDown fontSize={20} />
                                    </div>
                                </div>
                            </summary>

                            <div>
                                {
                                    section?.subSection?.map((data, index) => {
                                        return (
                                        <div key={index}
                                            // onClick={() => setViewSubSection(data)}
                                            className='flex w-full flex-col sm:flex-row items-start gap-y-5 sm:gap-y-0 sm:items-center justify-between gap-x-3 bg-white p-3 mt-4 px-3 sm:px-8 rounded-lg'
                                        >
                                            <div className='flex items-center gap-x-3 p-2 px-5 bg-slate-200 rounded-full hover:bg-slate-300 transition-all duration-300 cursor-pointer'
                                            onClick={() => setViewSubSection(data)}>
                                                <FaAngleRight />
                                                <p>{data?.title}</p>
                                            </div>
                                            <div className='flex items-center gap-x-3'
                                            //onClick={(e) => e.stopPropagation()}
                                            >
                                                <button
                                                    onClick={() => setEditSubSection({...data, sectionId:section._id})}
                                                    className='bg-blue-600 p-2 rounded-full text-white hover:bg-blue-700 transition-all duration-300'
                                                >
                                                    <BiSolidEditAlt />
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        setConfirmationModal({
                                                            text1: "Delete this Sub-Section",
                                                            text2: "All the Content of this Sub-Section will be deleted",
                                                            btn1Text: "Delete",
                                                            btn2Text: "Cancel",
                                                            btn1Handler: () => handleDeleteSubSection(data._id, section._id),
                                                            btn2Handler: () => setConfirmationModal(null)
                                                        })
                                                    }}
                                                    className='bg-red-600 p-2 rounded-full text-white hover:bg-red-700 transition-all duration-300'
                                                >
                                                    <MdDeleteSweep />
                                                </button>
                                            </div>
                                        </div>
                                        )
                                    })
                                }
                                <button
                                    onClick={() => setAddSubSection(section._id)}
                                    className='mt-4 flex items-center gap-x-3 text-red-400 hover:text-red-500 transition-all duration-300 font-bold rounded-lg bg-[#F4EBE2] p-3 px-8'
                                >
                                    <FaPlus />
                                    <p>Add Sub-Section</p>
                                </button>
                            </div>
                        </details>
                    ))
                }
            </div>
            {   
                addSubSection ? (<SubSectionModal 
                    modalData={addSubSection}
                    setModalData={setAddSubSection}
                    add={true}
                />) 
                : viewSubSection ? (<SubSectionModal 
                    modalData={viewSubSection}
                    setModalData={setViewSubSection}
                    view={true}
                />) 
                : editSubSection ? (<SubSectionModal 
                    modalData={editSubSection}
                    setModalData={setEditSubSection}
                    edit={true}
                />) 
                : (<div></div>)
            }
            {
                confirmationModal ? (
                    <ConfirmationModal
                        modalData={confirmationModal}
                        setModalData={setConfirmationModal}
                    />
                ) : (<div></div>)
            }
        </div>
    );
};

export default NestedView;