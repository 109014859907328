// import { useSelector } from "react-redux"

// const {user} = useSelector((state) => state.profile);

// export const ACCOUNT_TYPE = {
//     user:user.accountType
// }

export const POST_STATUS = {
    DRAFT:"Draft",
    PUBLISHED:"Published"
}