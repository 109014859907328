import { combineReducers } from "redux";
import authReducer from '../slices/authSlice';
import profileReducer from '../slices/profileSlice';
import draftReducer from '../slices/draftSlice';
import postReducer from '../slices/postSlice';

const rootReducer = combineReducers({
    auth: authReducer,
    profile: profileReducer,
    draft: draftReducer,
    post: postReducer
})

export default rootReducer;