import { useEffect, useState } from 'react';
import React from 'react';
import { useForm } from 'react-hook-form';
import CountryCode from "../../../data/countrycode.json";
import { useDispatch } from 'react-redux';
import { contactUs } from "../../../services/operations/contactAPI";

const ContactUsForm = () => {

    const dispatch = useDispatch();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isSubmitSuccessful }
    } = useForm();

    //backend api is left to build for contact us form (done)
    const submitContactForm = async(data) => {
        //console.log("Logging data", data);
        try{
            dispatch(contactUs(data));
        }
        catch(error){
            console.log("DATA ERROR", error);
        }
    }

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset({
                email:"",
                firstName:"",
                lastName:"",
                message:"",
                contactNumber:""
            });
        }
    }, [isSubmitSuccessful, reset]);



    return(
        <form onSubmit={handleSubmit(submitContactForm)} className="max-w-lg mx-auto p-6 rounded-lg shadow-md">
            <div className="flex flex-col gap-4">
                <div className="flex gap-3">
                <div className="flex-1">
                    <label className="block mb-2" htmlFor="firstName">First Name</label>
                    <input
                    required
                    type="text"
                    name="firstName"
                    id="firstName"
                    placeholder="First Name"
                    {...register('firstName', { required: true })}
                    className="text-white placeholder-white px-3 py-2 rounded-lg bg-[#D0B8A8] w-full focus:outline-none "
                    />
                    {errors.firstName && <span className="text-red-500 text-sm mt-1 block">Please enter your first name</span>}
                </div>

                <div className="flex-1">
                    <label className="block mb-2" htmlFor="lastName">Last Name</label>
                    <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    placeholder="Last Name"
                    {...register('lastName')}
                    className="text-white placeholder-white px-3 py-2 rounded-lg bg-[#D0B8A8] w-full focus:outline-none "
                    />
                </div>
                </div>

                <div>
                <label className="block mb-2" htmlFor="email">Email Address</label>
                <input
                    required
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter Email Address"
                    {...register('email', { required: true })}
                    className="text-white placeholder-white px-3 py-2 rounded-lg bg-[#D0B8A8] w-full focus:outline-none "
                />
                {errors.email && <span className="text-red-500 text-sm mt-1 block">Please enter your email address</span>}
                </div>

                {/* Phone no field */}
                <div>
                    <label htmlFor='contactNumber' className='block mb-2'>Phone Number</label>
                    <div className='flex flex-row gap-5'>
                        {/* dropdown */}
                        <div className='flex w-[120px]'>
                            <select
                                name='dropdown'
                                id='dropdown'
                                {...register("countrycode", {required:true})}
                                className='text-white placeholder-white px-3 py-2 rounded-lg bg-[#D0B8A8] w-full focus:outline-none'
                            >
                                {
                                    CountryCode.map((element, index) => {
                                        return(
                                            <option key={index} value={element.dial_code}>
                                                {element.dial_code} - {element.name}
                                            </option>
                                        )
                                    })
                                }
                            </select>
                        </div>

                        {/* phone no */}
                        <div className='w-full'>
                            <input 
                                type='number'
                                name='contactNumber'
                                id='contactNumber'
                                placeholder='12345 67890'
                                className="text-white placeholder-white px-3 py-2 rounded-lg bg-[#D0B8A8] w-full focus:outline-none"
                                {...register('contactNumber', 
                                { 
                                    required: {value:true, message:"Please Enter Phone Number"},
                                    maxLength: {value:10, message:"Invalid Phone Number"},
                                    minLength: {value:8, message:"Invalid Phone Number"}
                                })}
                            />
                        </div>
                    </div>
                    {
                        errors.contactNumber && 
                        <span>
                            {errors.contactNumber.message}
                        </span>
                    }
                </div>

                <div>
                <label className="block mb-2" htmlFor="message">Message</label>
                <textarea
                    name="message"
                    id="message"
                    placeholder="Enter your message here"
                    {...register('message', { required: true })}
                    className="text-white placeholder-white px-3 py-2 rounded-lg bg-[#D0B8A8] w-full h-32 focus:outline-none resize-none"
                />
                {errors.message && <span className="text-red-500 text-sm mt-1 block">Please enter your message</span>}
                </div>

                <button
                type="submit"
                className="bg-[#3A7E8D] text-white py-2 rounded-lg w-full mt-4 hover:bg-[#235965] transition duration-300 ease-in-out"
                >
                Send Message
                </button>
            </div>
</form>

    )
};

export default ContactUsForm;