import React from "react";
import Footer from "../components/common/Footer";
import footer from '../assets/footer-bg.jpg';
import logo from '../assets/Logo Circle - PNG.png';
import logotext from '../assets/logo-text.png';
import ContactUsForm from "../components/core/ContactPage/ContactUsForm";
import { IoIosChatboxes } from "react-icons/io";
import { FaXTwitter } from "react-icons/fa6";
import { TiLocationArrow } from "react-icons/ti";
import { FaPinterest } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";

const ContactUs = () => {
    return(
        <div className="w-screen">
            <div className="p-10 mt-10 gap-10 flex flex-col lg:flex-row items-center lg:items-start max-w-[1080px] mx-auto justify-between">
                <div className="lg:w-7/12 p-10 h-fit bg-white shadow-lg rounded-lg flex flex-col gap-10 font-semibold">
                    <div>
                        <div className="flex gap-2 items-center">
                            <IoIosChatboxes />
                            <p>Chat with us</p>
                        </div>
                        <p>Our friendly team is here to help.</p>
                        <p>jeviknows@gmail.com</p>
                    </div>

                    <div>
                        <div className="flex gap-2 items-center">
                            <FaXTwitter />
                            <p>Message us on twitter</p>
                        </div>
                        <p>Follow us on X.</p>
                    </div>

                    <div>
                        <div className="flex gap-2 items-center">
                            <TiLocationArrow fontSize="20px" />
                            <p>Shoot us an email</p>
                        </div>
                        <p>Know about future updates.</p>
                    </div>

                    {/* <div className="flex gap-5 mx-auto text-2xl">
                        <FaPinterest />
                        <FaInstagram />
                        <FaXTwitter />
                    </div> */}
                </div>

                <div className="rounded-lg sm:px-5">
                    <h1 className="text-3xl font-bold text-center leading-[3.5rem]">Got ideas? We've got skills.</h1>
                    <p className="text-center sm:px-28">We're here to help. Chat to our friendly team 24/7 and get set up and ready to start blogging.</p>
                    <div className="py-5">
                        <ContactUsForm />
                    </div>
                </div>
            </div>

            <Footer footer={footer} logo={logo} logotext={logotext}/>
        </div>
    )
};

export default ContactUs;