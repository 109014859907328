import {toast} from 'react-hot-toast';
import { apiConnector } from '../apiconnector';
import { contactusEndpoints } from '../apis';
import { setLoading } from '../../slices/profileSlice';

const {
    CONTACT_US_API
} = contactusEndpoints;

export function contactUs(data){
    return async (dispatch) => {
        const toastId = toast.loading("Loading...");
        
        dispatch(setLoading(true));

        try{
            //console.log("Form data", data);
            
            const response = await apiConnector("POST", CONTACT_US_API, data);

           //console.log("CONTACT_US_API RESPONSE", response);

            if(!response.data.success){
                throw new Error(response.data.message)
            }

            toast.success("Form Submitted Successfully");
        }
        catch(error){
            console.log("CONTACT_US_API ERROR", error);
            toast.error("Failed to submit the form");
        }

        dispatch(setLoading(false));
        toast.dismiss(toastId);
    }
}