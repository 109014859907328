import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../components/Spinner";
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineReload } from "react-icons/ai";
import { sendOtp, signUp } from "../services/operations/authAPI";
import { MuiOtpInput } from 'mui-one-time-password-input';

const VerifyEmail = () => {

    const {loading, signupData} = useSelector((state) => state.auth);
    
    useEffect(() => {
        if(!signupData){
            navigate("/signup");
            //console.log("SIGNUP DATA", signupData);
        }
    }, [])

    const [otp, setOtp] = useState("");

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const handleOnSubmit = (e) => {
        e.preventDefault();

        const {
            accountType,
            firstName,
            lastName,
            email,
            password,
            confirmPassword,
        } = signupData;

        dispatch(signUp(accountType, firstName, lastName, email, password,
            confirmPassword, otp, navigate));
    }

    return(
        <div className="w-screen flex flex-col p-3 justify-center items-center">
            {
                loading ?
                (
                    <div className="mt-[5rem]">
                        <Spinner />
                    </div>
                ) :
                (
                    <div className="flex flex-col gap-5 w-full max-w-[500px] mt-[5rem]
                    text-center border-2 border-white bg-white/80 
                    shadow-lg ring-1 ring-black/5
                    rounded-2xl py-10 px-10 h-auto">
                        <h1 className="text-3xl font-bold">Verify Email</h1>
                        
                        <p>
                           A verification code has been sent to you on the registered email. Enter the code below:
                        </p>

                        <form onSubmit={handleOnSubmit}
                        className="flex flex-col gap-4 justify-center">
                            <label>
                               <MuiOtpInput 
                                    value={otp}
                                    onChange={setOtp}
                                    length={6}
                                    autoFocus
                               /> 
                            </label>

                            <button type="submit"
                            className="w-fit px-6 mx-auto py-4 bg-red-400 rounded-xl font-semibold text-white
                            hover:bg-red-500 transition-all duration-300 hover:scale-95">
                                Verify Email
                            </button>
                        </form>

                        <div className="flex justify-between px-5">
                            <div className="flex items-center">
                                <Link to="/login">
                                    <p className="font-semibold text-blue-600 flex items-center gap-2">
                                        <MdKeyboardArrowRight fontSize={20}/>
                                        Back to Login
                                    </p>
                                </Link>
                            </div>

                            <div className="flex items-center gap-3 group">
                                <button onClick={() => dispatch(sendOtp(signupData.email, navigate))}
                                className="flex gap-2 items-center">
                                    <AiOutlineReload fontSize={20} className="group-hover:rotate-180 transition-all duration-300"/>
                                    Resent it
                                </button>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
};

export default VerifyEmail;