import {toast} from 'react-hot-toast';

import { setLoading, setUser } from "../../slices/profileSlice";
import { apiConnector } from "../apiconnector";
import { logout } from "../operations/authAPI";
import { blogDetailsEndpoints } from "../apis";

const {
    GET_USER_DETAILS_API,
    GET_USER_POSTS_API,
    GET_USER_POST_DETAILS_API,
    GET_ADMIN_POST_DETAILS_API
} = blogDetailsEndpoints;

export async function getAdminPostDetails(token){
    const toastId = toast.loading("Loading...");
    let result = [];
    try{
        const response = await apiConnector("GET", GET_ADMIN_POST_DETAILS_API, null, {
            'Authorization': `Bearer ${token}`,
        });

        //console.log("GET_ADMIN_POST_DETAILS_API RESPONSE", response);

        if(!response.data.success){
            throw new Error(response.data.message);
        }

        result = response.data.data;
        //toast.success("Post Details fetched successfully");
    }
    catch(error){
        //console.log("GET_ADMIN_POST_DETAILS_API_ERROR", error);
        toast.error("Something went while displaying Posts");
    }
    toast.dismiss(toastId);
    return result
}

export async function getUserPosts(token){
    const toastId = toast.loading("Loading...");
    let result = [];
    try{
        const response = await apiConnector("POST", GET_USER_POSTS_API, null, {
            'Authorization': `Bearer ${token}`,
        })

        //console.log("GET_USER_POSTS_API RESPONSE", response);

        if(!response.data.success){
            throw new Error(response.data.message);
        }

        result = response.data.data;
    }
    catch(error){
        //console.log("GET_USER_POSTS_API_ERROR", error);
        //toast.error("Something went while displaying Posts");
    }
    toast.dismiss(toastId);
    return result
}

export async function getUserPostDetails(token){
    const toastId = toast.loading("Loading...");
    let result = [];
    try{
        const response = await apiConnector("GET", GET_USER_POST_DETAILS_API, null, {
            'Authorization': `Bearer ${token}`,
        });

        //console.log("GET_USER_POST_DETAILS_API RESPONSE", response);

        if(!response.data.success){
            throw new Error(response.data.message);
        }

        result = response.data.data;
        //toast.success("Post Details fetched successfully");
        //console.log("Result", result);
    }
    catch(error){
        //console.log("GET_USER_POST_DETAILS_API_ERROR", error);
        toast.error("Something went while displaying Posts");
    }
    toast.dismiss(toastId);
    return result
}