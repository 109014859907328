import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import ProfileEditBtn from "../../../common/ProfileEditBtn";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { updatePassword } from "../../../../services/operations/profileAPI";
import toast from "react-hot-toast";

const UpdatePassword = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {token} = useSelector(state => state.auth);

    const [showPassword, setShowPassword] = useState({
        oldPassword:false,
        newPassword:false,
        confirmNewPassword:false
    });

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isSubmitSuccessful },
    } = useForm();

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset({
                oldPassword:'',
                newPassword:'',
                confirmNewPassword:''
            });
        }
    }, [isSubmitSuccessful, reset]);

    const submitPasswordChangeForm = (data) => {
        //console.log(data);

        // Check if newPassword and confirmNewPassword match
        if (data.newPassword !== data.confirmNewPassword) {
            // Update the UI to show an error message or handle it accordingly
            toast.error("New password and confirm password do not match");
            return;
        }

        // Dispatch the form data here
        dispatch(updatePassword(data, navigate, token));
    };

    return(
        <div className="rounded-3xl shadow-lg bg-white p-10 mt-10 border border-slate-200">
            {/* section 1 */}
            <div>
                <div className="flex flex-col gap-10">
                    <div className="flex flex-col gap-3">
                        <p className="text-5xl font-bold overflow-visible">Change Password</p>
                        <p>Please fill all the fields to change your current password</p>
                    </div>

                    <form onSubmit={handleSubmit(submitPasswordChangeForm)}>
                        <div className="grid grid-cols-1 grid- gap-5 sm:grid-cols-2 xl:grid-cols-3">
                            {/* Old Password */}
                            <div className="flex flex-col relative">
                                <label htmlFor="oldPassword" className="relative">Old Password
                                    
                                </label>
                                <input
                                    type={showPassword.oldPassword ? ("text") : ("password")}
                                    name="oldPassword"
                                    id="oldPassword"
                                    placeholder="Enter old password"
                                    {...register('oldPassword', { required: true })}
                                    className="text-black px-3 py-2 rounded-lg bg-gray-200 focus:outline-none"
                                />
                                {errors.oldPassword && <span className="text-red-500">Fill all the fields</span>}
                                    <span onClick={() => setShowPassword((prev) => ({
                                        ...prev,
                                        oldPassword: !prev.oldPassword
                                    }))}
                                    className="absolute right-3 top-8 cursor-pointer z-1">
                                        {
                                            showPassword.oldPassword ? 
                                            (
                                                <AiFillEyeInvisible fontSize={24}/>
                                            ) : 
                                            (
                                                <AiFillEye fontSize={24}/>
                                            )
                                        }

                                    </span>
                            </div>

                            {/* New Password */}
                            <div className="flex flex-col relative">
                                <label htmlFor="newPassword" className="relative">New Password</label>
                                <input
                                    type={showPassword.newPassword ? ("text") : ("password")}
                                    name="newPassword"
                                    id="newPassword"
                                    placeholder="Enter new password"
                                    {...register('newPassword', { required: true })}
                                    className="text-black px-3 py-2 rounded-lg bg-gray-200 focus:outline-none"
                                />
                                {errors.newPassword && <span className="text-red-500">Fill all the fields</span>}
                                    <span onClick={() => setShowPassword((prev) => ({
                                        ...prev,
                                        newPassword: !prev.newPassword
                                    }))}
                                    className="absolute right-3 top-8 cursor-pointer z-1">
                                        {
                                            showPassword.newPassword ? 
                                            (
                                                <AiFillEyeInvisible fontSize={24}/>
                                            ) : 
                                            (
                                                <AiFillEye fontSize={24}/>
                                            )
                                        }

                                    </span>
                            </div>

                            {/* Confirm New Password */}
                            <div className="flex flex-col relative">
                                <label htmlFor="confirmNewPassword" className="relative">Confirm New Password</label>
                                <input
                                    type={showPassword.confirmNewPassword ? ("text") : ("password")}
                                    name="confirmNewPassword"
                                    id="confirmNewPassword"
                                    placeholder="Confirm New Password"
                                    {...register('confirmNewPassword', { required: true })}
                                    className="text-black px-3 py-2 rounded-lg bg-gray-200 focus:outline-none"
                                />
                                {errors.confirmNewPassword && <span className="text-red-500">Fill all the fields</span>}
                                    <span onClick={() => setShowPassword((prev) => ({
                                        ...prev,
                                        confirmNewPassword: !prev.confirmNewPassword
                                    }))}
                                    className="absolute right-3 top-8 cursor-pointer z-1">
                                        {
                                            showPassword.confirmNewPassword ? 
                                            (
                                                <AiFillEyeInvisible fontSize={24}/>
                                            ) : 
                                            (
                                                <AiFillEye fontSize={24}/>
                                            )
                                        }

                                    </span>
                            </div>
                        </div>

                        {/* Buttons */}
                        <div className="flex gap-4 mt-5">
                            <ProfileEditBtn type="submit" text="Save" />
                            <ProfileEditBtn type="button" text="Cancel" onClick={() => navigate('/dashboard/my-profile')} />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
};

export default UpdatePassword;