import React, { useState } from "react";
import './loginsignup.css';
import threedimage from '../assets/3d.jpg';
import bglog from '../assets/bg-log.jpg';
import { Link, useNavigate } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import toast from "react-hot-toast";
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { sendOtp, signUp } from "../services/operations/authAPI";
import { setSignupData } from "../slices/authSlice";
import { FaUser } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import newlogo from '../assets/mail/LOGO PNG.png';
import { handleGoogleLogin } from "../services/operations/googleAPI";


const SignupPage = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        firstName:"", lastName:"", email:"", password:"", 
        confirmPassword:"", accountType:"User"
    })

    const [showPassword, setShowPassword]  = useState({
        password:false,
        confirmPassword:false
    });

    const {accountType, firstName, lastName, email, password, confirmPassword, otp} = formData;

    function changeHandler(event){
        setFormData((prevData) => (
            {
                ...prevData,
                [event.target.name]:event.target.value
            }
        ))
    }

    function submitHandler(event){
        event.preventDefault();
        if(formData.password !== formData.confirmPassword){
            toast.error("Password do not match");
            return;
        }

        //Dispatch action  to set signupData in redux store
        dispatch(setSignupData(formData));

        //call sendOtp function to send OTP to the user
        dispatch(sendOtp(email, navigate));

        //to empty the form
        setFormData({
            firstName:"",
            lastName:"",
            email:"",
            password:"",
            confirmPassword:""
        });
    }

    const handleGoogleLoginClick = () => {
         dispatch(handleGoogleLogin(navigate));      
    };

    return(
        <div className="w-screen overflow-hidden p-3">
            <div className="relative w-full p-4">
                {/* <img src={bglog} alt="login" className="w-full object-cover aspect-video" />

                <div className="absolute inset-0 bg-gradient-to-r from-black from-60%"></div> */}
                
                <div className="w-full lg:w-5/12 lg:mx-auto bg-white bg-opacity-60 backdrop-blur-[20px] flex flex-col items-center p-5 rounded-xl py-10">
                    <div className="flex flex-col items-center gap-3">
                        <div className="flex w-6/12 lg:w-[150px] lg:h-[120px] xl:w-[230px] xl:h-[190px]">
                            <img src={newlogo} alt="logo" loading="lazy" className="w-full h-full"/>
                        </div>
                        <p className="font-semibold text-xl sm:text-3xl">Create new account<span className="text-[#8D493A]">.</span></p>
                        {/* <p className="text-lg font-medium">Already a member?  
                            <Link to="/login" className="text-blue-600"> Log in</Link>
                        </p> */}
                        <p className="font-bold capitalize text-[1.5rem] sm:text-[2rem]">SIGN UP</p>
                    </div>

                    <div className="w-full mx-auto">

                        <form onSubmit={submitHandler} className="flex flex-col items-center w-full mt-3">
                            <div className="w-full flex flex-col sm:flex-row sm:justify-center lg:flex-col xl:flex-row gap-2">
                                <div className="w-full">
                                    <label for="firstName" className="block mb-1 text-sm font-medium">First Name </label>
                                        <div className="relative mb-3 w-full">
                                            <div className="absolute inset-y-0 end-0 flex items-center pe-3.5 pointer-events-none">
                                                <FaUser className="text-white"/>
                                            </div>

                                            <input
                                                required
                                                type="text"
                                                name="firstName"
                                                onChange={changeHandler}
                                                placeholder="First Name"
                                                value={formData.firstName}
                                                className="w-full text-white placeholder:text-zinc-500 px-3 py-4 rounded-xl 
                                                focus:outline-none bg-zinc-800" 
                                            />
                                        </div>
                                </div>
                                    
                                <div className="w-full">
                                    <label for="lastName" className="block mb-1 text-sm font-medium">Last Name </label>
                                        <div className="relative mb-3">
                                            <div className="absolute inset-y-0 end-0 flex items-center pe-3.5 pointer-events-none">
                                                <FaUser className="text-white"/>
                                            </div>

                                            <input
                                                required
                                                type="text"
                                                name="lastName"
                                                onChange={changeHandler}
                                                placeholder="Last Name"
                                                value={formData.lastName}
                                                className="text-white w-full placeholder:text-zinc-500 px-3 py-4 rounded-xl 
                                                focus:outline-none bg-zinc-800" 
                                            />
                                        </div>
                                        
                                        
                                </div>
                            </div>

                            <div className="w-full">
                                <label for="email" className="block mb-2 text-sm font-medium">Your Email</label>
                                    <div className="relative mb-3">
                                        <div className="absolute inset-y-0 end-0 flex items-center pe-3.5 pointer-events-none">
                                            <MdEmail fontSize={22} className="text-white"/>
                                        </div>

                                        <input
                                            required
                                            type="email"
                                            value={formData.email}
                                            onChange={changeHandler}
                                            placeholder="Enter Email Address"
                                            name="email"
                                            className="text-white placeholder:text-zinc-500 px-3 py-4 w-full rounded-xl 
                                            focus:outline-none bg-zinc-800"
                                        /> 
                                    </div>
                            </div>

                            
                            <div className="flex flex-col sm:flex-row lg:flex-col xl:flex-row gap-2 w-full mb-3">
                                <div className="w-full">
                                    <label for="password" className="block mb-2 text-sm font-medium">Password</label>

                                    <div className="relative mb-3">
                                        <input
                                            required
                                            type={showPassword.password ? ("text") : ("password")}
                                            value={formData.password}
                                            onChange={changeHandler}
                                            name="password"
                                            placeholder="Create Password"
                                            className="w-full text-white placeholder:text-zinc-500 px-3 py-4 rounded-xl 
                                            focus:outline-none bg-zinc-800"
                                        />
                                    
                                        <span onClick={() => setShowPassword((prev) => ({
                                            ...prev,
                                            password: !prev.password
                                        }))}
                                        className="absolute right-3 top-[16px] cursor-pointer text-white">
                                            {
                                                showPassword.password ? 
                                                (
                                                    <AiFillEyeInvisible fontSize={24}/>
                                                ) : 
                                                (
                                                    <AiFillEye fontSize={24}/>
                                                )
                                            }

                                        </span>
                                    </div>
                                </div>

                                <div className="w-full">
                                    <label for="confirmPassword" className="block mb-2 text-sm font-medium">Confirm Password</label>

                                    <div className="relative mb-3">
                                        <input
                                            required
                                            type={showPassword.confirmPassword ? ("text") : ("password")}
                                            value={formData.confirmPassword}
                                            onChange={changeHandler}
                                            name="confirmPassword"
                                            placeholder="Confirm Password"
                                            className="w-full text-white placeholder:text-zinc-500 px-3 py-4 rounded-xl 
                                            focus:outline-none bg-zinc-800"
                                        />
                                    
                                        <span onClick={() => setShowPassword((prev) => ({
                                            ...prev,
                                            confirmPassword: !prev.confirmPassword
                                        }))}
                                        className="absolute right-3 top-[16px] cursor-pointer text-white">
                                            {
                                                showPassword.confirmPassword ? 
                                                (
                                                    <AiFillEyeInvisible fontSize={24}/>
                                                ) : 
                                                (
                                                    <AiFillEye fontSize={24}/>
                                                )
                                            }

                                        </span>
                                    </div>
                                </div>
                            </div>

                            <button className="w-full py-2 bg-red-400 rounded-xl font-semibold text-white
                            hover:bg-red-500 transition-all duration-300 hover:scale-95">
                                Create Account
                            </button>

                            <p className="text-[14px] sm:text-base text-center px-10 font-medium text-[#8D493A] mt-4">By creating the account, You accept to the terms mentioned in the <span onClick={() => navigate('/privacy')} className="underline cursor-pointer">Privacy Policy</span> of our platform</p>

                            <div className="flex w-full items-center justify-center mt-4">
                                <Link onClick={handleGoogleLoginClick} className="w-full text-center">
                                    <div className="w-full py-2 px-4 bg-white text-[14px] rounded-xl flex items-center justify-center gap-3">
                                        <FcGoogle/> 
                                        <span>Sign Up with Google</span>
                                    </div>
                                </Link>   
                            </div>

                            <div className="mt-4 text-[14px] text-center">
                                <p>Already have an account?
                                    <Link className="ml-1 font-semibold text-center text-[#8D493A]" to="/login">
                                        Log in to get started!
                                    </Link>
                                </p>
                            </div>
                        
                        </form>

                    </div>
                </div>
            </div>
        </div>
    )
};

export default SignupPage;