import React, { useEffect, useState } from 'react';
import Footer from '../components/common/Footer';
import { Link, useLocation, useParams } from 'react-router-dom';
import { postEndpoints } from '../services/apis';
import { getCatalogPageData } from '../services/operations/pageAndComponentData';
import { apiConnector } from '../services/apiconnector';
import Post_Card from '../components/core/Catalog/Post_Card';
import PostSlider from '../components/core/Catalog/PostSlider';
import footer from '../assets/footer-bg.jpg';
import logo from '../assets/Logo Circle - PNG.png';
import logotext from '../assets/logo-text.png';
import { FaFire } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaPinterest } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { BsDot } from "react-icons/bs";
import { FaLocationDot } from "react-icons/fa6";
import SearchBar from '../components/core/SearchBar/SearchBar';

const Catalog = () => {

    const {catalogName} = useParams();
    const [catalogPageData, setCatalogPageData] = useState(null);
    const [categoryId, setCategoryId] = useState("");
    const [categoryName, setCategoryName] = useState("");
    const [allCategoryData, setAllCategoryData] = useState([]);

    const { POST_CATEGORIES_API } = postEndpoints;

    //fetch all categories
    useEffect(() => {
        const getCategories = async () => {
            const res = await apiConnector("GET", POST_CATEGORIES_API);
            const category_id = res?.data?.allCategory?.filter((ct) => ct.name.split(" ").join("-").toLowerCase() === catalogName)[0].
            _id;
            const category_name = res?.data?.allCategory?.filter((ct) => ct.name.split(" ").join("-").toLowerCase() === catalogName)[0].
            name;
            setCategoryId(category_id);
            setCategoryName(category_name);
        }
        getCategories();
    }, [catalogName]);
    // console.log("CATEGORY ID", categoryId);
    // console.log("CATEGORY NAME", categoryName)
    // console.log("CATALOG NAME", catalogName);
    // console.log("CATALOG PAGE DATA SECLECTED CATEGORY", catalogPageData?.data?.selectedCategory?.name);

    useEffect(() => {
        const getCategoryDetails = async() => {
          try{
                const res = await getCatalogPageData(categoryId);
                //console.log("PRINTING RES", res);
                setCatalogPageData(res);
            }
            catch(error){
                console.log("Error", error);
            }
        }
        if(categoryId) {
          getCategoryDetails();
        }
    }, [categoryId]);

    useEffect(() => {
      const getCategories = async () => {
        const res = await apiConnector("GET", POST_CATEGORIES_API);
        //console.log("RES", res);
        setAllCategoryData(res?.data?.allCategory);
      }
      getCategories();
    },[])

    const truncateText = (text = '', wordLimit) => {
      const words = text.split(' ');
      return words.length > wordLimit ? `${words.slice(0, wordLimit).join(' ')}...` : text;
    };

  return (
    <div className="w-screen pt-16">
      <div className="max-w-[1500px] mx-auto mb-10 space-y-8 px-3">
        <div className="w-full rounded-3xl isolate p-10 bg-[#8D493A]/60 backdrop-blur-sm shadow-lg">
          <p className="text-4xl sm:text-8xl lg:text-9xl text-white font-bold overflow-hidden">JEVIKNOWS</p>
          <div className="flex justify-between mt-5 lg:mt-10">
            <div className="sm:pl-2 text-white">
              <p className="text-white font-light">CATEGORIES</p>
              {/* HERE ALL CATEGORIES WILL COME */}
              <div className='text-[12px] sm:text-base flex flex-wrap gap-2 sm:gap-5 py-4'>
                {
                  allCategoryData?.filter((ct) => ct?.name !== "All").map((category, index) => {
                    
                    const isDisabled = category?.posts.length === 0;

                    //gather posts from allCategoryData
                    const filteredPosts = category?.posts?.filter((post) => post?.status === "Published");

                    return(
                      <div className={` px-4 py-2 rounded-xl font-semibold ${category.name === categoryName ? "bg-white text-black" : "bg-[#2A2A32]"}`}>
                        <Link key={index} to={`/catalog/${category?.name
                                    .split(" ")
                                    .join("-")
                                    .toLowerCase()}`} className={isDisabled ? "pointer-events-none": ""}>
                          
                            <p className='flex items-center'>{category?.name} <BsDot fontSize={24} /> {filteredPosts?.length} </p>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
          <div className='text-white sm:pl-2 mt-2 sm:mt-4'>
            {/* location */}
              <p className='flex items-center gap-2 text-sm sm:text-base'>
                <FaLocationDot />
                {`Catalog /`}
                <span className={`${categoryName === catalogPageData?.data?.selectedCategory?.name ? "text-blue-500 font-bold" : ""}`}>
                  {catalogPageData?.data?.selectedCategory?.name}
                </span>
              </p>
              <p className='text-xl sm:text-3xl font-bold uppercase mt-3'>{catalogPageData?.data?.selectedCategory?.name}</p>
              <p className='text-[12px] sm:text-sm font-light mt-3'>{catalogPageData?.data?.selectedCategory?.description}</p>
          </div>
        </div>

        <div className='w-full h-auto flex flex-col-reverse lg:flex-row lg:space-x-5'>
          {/* section 1 */}
          {/* admin posts and user posts */}
          <div className='w-full sm:max-lg:px-20 lg:w-9/12 h-auto space-y-5'>
                {/* admin posts */}
                <div className="flex gap-x-6 items-center text-center">
                    <p className='text-[3rem] py-2 rounded-xl font-semibold'>Blogs from Jeviknows</p>
                </div>
              
              <div className='w-full bg-slate-400 rounded-3xl'>    
                <div className='w-full overflow-hidden'>
                    <PostSlider posts={catalogPageData?.data?.selectedCategory?.posts}/>
                </div>
              </div>

              {/* user posts */}
              <div className='w-full space-y-10'>
                <p className='text-xl text-center lg:text-left sm:text-3xl lg:text-4xl overflow-hidden font-semibold uppercase'>
                  Top Posts in {catalogPageData?.data?.selectedCategory?.name}
                </p>
                <div className='flex flex-wrap gap-8'>
                  {
                    catalogPageData?.data?.selectedCategory?.posts.slice(0, 3).map((post, index) => {
                      //console.log("POST", post);
                      return(
                        <Post_Card post={post} key={index} height={"h-[300px]"} />
                      )
                    })
                  }
                </div>
              </div>
              
          </div>
          {/* search bar trending social media */}
          <div className='w-full sm:max-lg:px-20 lg:w-3/12 p-2 flex flex-col gap-6'>
              <div className='w-full rounded-lg flex items-center pt-5 justify-center'>
                  <SearchBar />
              </div>

              <div className='w-full bg-white rounded-lg flex flex-col py-8 gap-5'>
                  <div className='flex items-center gap-3 px-10'>
                    <div className='bg-[#FF6942] p-2 rounded-md'>
                      <FaFire className='text-white text-xl'/>
                    </div>
                    <div className='font-bold text-xl'>
                        Trending
                    </div>
                  </div>

                  <div className='w-full h-[3px] bg-slate-100'></div>

                  <div className='flex flex-col px-8 lg:px-3 w-full gap-5'>
                    {/* right now selected is put but after most liked will be put */}
                    {
                        catalogPageData?.data?.selectedCategory?.posts.slice(0, 3).map((post, index) => {
                          return(
                            <Link to={`/posts/${post._id}`} key={index}>
                              <div className='flex flex-row lg:flex-col xl:flex-row space-x-3 gap-y-3 items-center p-2 rounded-lg hover:bg-black/10 group transition-all duration-300'>
                                  <div className='w-4/12 lg:w-8/12 xl:w-4/12 rounded-xl'>
                                    <img src={post?.thumbnail?.url} alt={post?.postName} className='aspect-square object-cover'/>
                                  </div>
                                  <div className='w-8/12'>
                                    <p className='font-bold sm:text-2xl lg:text-sm'>{post?.postName}</p>
                                    <p className='text-slate-300 text-[12px] sm:text-lg lg:text-[10px] group-hover:text-black transition-all duration-300'>{truncateText(post?.postDescription, 5)}</p>
                                  </div>
                              </div>
                            </Link>
                          )
                        })
                    }
                  </div>
              </div>

              {/* <div className='w-full bg-white rounded-lg flex items-center justify-between px-10 py-6'>
                  <p>Check our social!</p>
                  <div className='flex gap-4 items-center text-xl'>
                    <FaInstagram />
                    <FaPinterest />
                    <FaXTwitter />
                  </div>
              </div> */}
          </div>


        </div>
      </div>

      {/* Footer here */}
      <Footer footer={footer} logo={logo} logotext={logotext}/>
    </div>
  );
};

export default Catalog;
