import React from "react";  
import { useSelector } from "react-redux";
import Spinner from "../components/Spinner";
import { Outlet } from "react-router-dom";
import Sidebar from "../components/core/Dashboard/Sidebar";

const Dashboard = () => {

    const {loading: authLoading} = useSelector((state) => state.auth);
    const {loading: profileLoading} = useSelector((state) => state.profile);

    if(authLoading || profileLoading){
        return(
            <div>
                <Spinner />
            </div>
        )
    }
    //h-[calc(100vh-4rem)]
    return (
        <div className="w-screen xl:h-[calc(100vh-4rem)]">
            <div className="relative flex flex-col xl:flex-row gap-5 w-full h-full p-3 xl:p-14 items-center">
                <Sidebar />
                <div className="w-full shadow-lg xl:flex-1 h-full rounded-2xl overflow-y-auto">
                    <div>
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    );
};  

export default Dashboard;