import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import newlogo from '../../assets/text.png';
import { NavbarLinks } from "../../data/navbar-links";
import { useLocation, matchPath } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ProfileDropDown from "../core/Auth/ProfileDropDown";
import { Popover, PopoverButton, PopoverPanel, Transition } from "@headlessui/react";
import { MdWebStories } from "react-icons/md";
import { fetchPostCategories } from "../../services/operations/postDetailsAPI";
import SearchBar from "../core/SearchBar/SearchBar";
import { HiMenu } from "react-icons/hi";
import { IoIosArrowForward } from "react-icons/io";
import { FiLogIn } from "react-icons/fi";
import { SiGnuprivacyguard } from "react-icons/si";
import MobileProfileDropDown from "../core/Auth/MobileProfileDropDown";


const Navbar = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {token} = useSelector((state) => state.auth );
    const {user} = useSelector((state) => state.profile );

    const location = useLocation();

    const [subLinks, setSubLinks] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchSublinks = async() => {
        try{
            setLoading(true);
            const result = await fetchPostCategories();
            
            const filteredResult = result.filter((item) => item.name !== "All");
            
            if(filteredResult){
                setSubLinks(filteredResult);
                //console.log("Printing Sub Links result:", result);
            }
            setLoading(false);
        }
        catch(error){
            console.log("Could not fetch category list", error)
        }
    } 

    useEffect(() => {
        fetchSublinks();
    }, [])

    const matchRoute = (route) => {
        return matchPath({path:route}, location.pathname)
    }

    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const togglePopover = () => {
        setIsPopoverOpen(!isPopoverOpen);
    };

    return(
        <div className="flex h-20 items-center justify-center bg-gradient-to-r from-[#D0B8A8] to-[#8D493A] w-full">
            <div className="px-3 lg:px-5 2xl:px-5 w-full flex items-center justify-between">

                <Link to="/"  className="w-fit">
                    <div className="flex w-[110px] h-[50px] md:w-[130px] md:h-[50px] lg:w-[200px] lg:h-[80px] 2xl:w-[250px] 2xl:h-[80px]">
                       <img src={newlogo} alt="logo" loading="lazy" className="w-full h-full"/>
                    </div>
                </Link>

                {/* Navlinks */}
                <nav className="w-[60%] lg:w-fit">
                    <div className="flex justify-center items-center rounded-lg h-fit">
                        <div className="bg-[#D0B8A8] text-white px-2 py-2 lg:px-4 lg:py-3 2xl:px-4 2xl:py-3 rounded-lg shadow-lg w-[12rem] lg:w-96 2xl:w-96">
                            <ul className="flex justify-between">
                            {NavbarLinks.map((item) => (

                                    <li key={item?.id} className="flex gap-2 items-center cursor-pointer">
                                        {
                                            item?.id === "blog" ? 
                                            (
                                                <>
                                                    <div className="relative group mt-[4px] lg:mt-[7px] 2xl:mt-[7px]">
                                                        <Popover>
                                                            <PopoverButton className="focus:outline-none
                                                            data-[focus]:outline-1 data-[focus]:outline-white text-[14px] lg:text-[20px] 2xl:text-[20px]">
                                                                {item?.Logo}
                                                            </PopoverButton>
                                                            <Transition
                                                                enter="transition ease-out duration-200"
                                                                enterFrom="opacity-0 translate-y-1"
                                                                enterTo="opacity-100 translate-y-0"
                                                                leave="transition ease-in duration-150"
                                                                leaveFrom="opacity-100 translate-y-0"
                                                                leaveTo="opacity-0 translate-y-1"
                                                            >
                                                                <PopoverPanel anchor="bottom" className="translate-y-[30px] opacity-0 group-hover:opacity-100">
                                                                    <div className="bg-white p-1 lg:p-3 2xl:p-3 rounded-lg text-[12px] lg:text-[1rem]">
                                                                        { loading ? (
                                                                            <p>Loading...</p>
                                                                        ) : subLinks.length ? 
                                                                            (
                                                                                <>
                                                                                {
                                                                                    subLinks?.filter(
                                                                                        (subLink) => subLink?.posts?.length > 0
                                                                                    )
                                                                                    ?.map((subLink, i) =>{

                                                                                        return(
                                                                                            <Link
                                                                                                to={`/catalog/${subLink.name
                                                                                                    .split(" ")
                                                                                                    .join("-")
                                                                                                    .toLowerCase()}`}
                                                                                                className="rounded-lg py-3 px-4 transition-all duration-300 hover:bg-gray-400 font-semibold block hover:text-white"
                                                                                                key={i}     
                                                                                            >
                                                                                                <div className="flex items-center gap-3">
                                                                                                    <p>{subLink.name}</p>
                                                                                                </div>
                                                                                            </Link>
                                                                                        )
                                                                                    })
                                                                                }
                                                                                </>
                                                                            ) : 
                                                                            (<div>
                                                                                <p>No Posts Found</p>
                                                                            </div>)
                                                                        }
                                                                    </div>
                                                                </PopoverPanel>
                                                            </Transition>
                                                        </Popover>
                                                    </div>
                                                </>
                                            ) :
                                            (
                                                item?.id === "search" ?
                                                (
                                                    <>
                                                        <div className="relative group mt-[4px] lg:mt-[7px] 2xl:mt-[7px]">
                                                        <Popover>
                                                            <PopoverButton className="focus:outline-none text-[14px] lg:text-[20px] 2xl:text-[20px]
                                                            data-[focus]:outline-1 data-[focus]:outline-white">
                                                                {item?.Logo}
                                                            </PopoverButton>
                                                            <Transition
                                                                enter="transition ease-out duration-200"
                                                                enterFrom="opacity-0 translate-y-1"
                                                                enterTo="opacity-100 translate-y-0"
                                                                leave="transition ease-in duration-150"
                                                                leaveFrom="opacity-100 translate-y-0"
                                                                leaveTo="opacity-0 translate-y-1"
                                                            >
                                                                <PopoverPanel anchor="bottom" className="translate-y-[30px] opacity-0 group-hover:opacity-100
                                                                min-w-[100px] lg:min-w-[400px] 2xl:min-w-[400px]">
                                                                    <div className="bg-white p-1 lg:p-3 2xl:p-3 rounded-lg">
                                                                        <SearchBar />
                                                                    </div>
                                                                </PopoverPanel>
                                                            </Transition>
                                                        </Popover>
                                                    </div>
                                                    </>
                                                ) :
                                                (
                                                    <>
                                                        <Link to={item?.path} className="flex items-center gap-2 cursor-pointer">
                                                            <div
                                                                className={`p-1 lg:p-3 2xl:p-3 rounded-full w-fit text-[14px] lg:text-[20px] 2xl:text-[20px]
                                                                ${matchRoute(item?.path) ? 'bg-white text-[#8D493A]' : ''
                                                                }`}
                                                            >
                                                                {item?.Logo}
                                                            </div>
                                                                {matchRoute(item?.path) && <span className="text-white 
                                                                font-semibold hidden lg:block 2xl:block">{item?.title}</span>}
                                                        </Link>
                                                    </>
                                                )
                                            )
                                        }
                                    </li>
                            ))}
                            </ul>
                        </div>
                    </div>
                </nav>

                {/*Login/Signup/dashboard/create Post/drafts/user profile/search*/}
                <div className="hidden gap-x-4 items-center overflow-hidden w-fit lg:flex 2xl:flex">

                    {
                        <Link to="/stories">
                            <button className="bg-[#3A7E8D] text-white font-semibold px-6 py-2 rounded-md hover:bg-[#255b67]
                            transition-all duration-300 mr-2">
                                Story
                            </button>
                        </Link>
                    }
                    {
                        token === null && (
                            <Link to="/login">
                                <button  className="bg-[#723A8D] text-white font-semibold
                                px-5 py-2 rounded-md hover:scale-90 transition-all duration-300">
                                    Log In
                                </button>
                            </Link>
                        )
                    }

                    {
                        token === null && (
                            <Link to="/signup">
                                <button className="font-semibold text-white bg-[#558D3A]
                                px-5 py-2 rounded-md hover:scale-90 transition-all duration-300">
                                    Sign Up
                                </button>
                            </Link>
                        )
                    }
                    {
                        token !== null && <ProfileDropDown />
                    }
                </div>
                
                {/* Hamburger icon for mobile view */}
                <div className="flex lg:hidden 2xl:hidden">
                    <button onClick={togglePopover} className="text-white">
                        <HiMenu  fontSize={26}/>
                    </button>
                </div>

                {/* Popover for mobile view */}
                {isPopoverOpen && (
                    <div className="absolute top-16 right-4 bg-white shadow-lg rounded-md p-4 flex flex-col items-center z-50">
                        <Link to="/stories" onClick={togglePopover}>
                            <button className="flex justify-between gap-10 text-sm items-center py-2">
                                <div className="flex gap-2 items-center">
                                    <p className="p-2 rounded-full bg-slate-200"><MdWebStories className="text-[11px]"/></p>
                                    <p>Stories</p>
                                </div>
                                <p><IoIosArrowForward /></p>
                            </button>
                        </Link>

                        {token === null && (
                            <>
                                <Link to="/login" onClick={togglePopover}>
                                    <button className="flex justify-between gap-10 text-sm items-center py-2">
                                        <div className="flex gap-2 items-center">
                                            <p className="p-2 rounded-full bg-slate-200"><FiLogIn className="text-[11px]"/></p>
                                            <p className="font-semibold">Log In</p>
                                        </div>
                                        <p><IoIosArrowForward /></p>
                                    </button>
                                </Link>
                                <Link to="/signup" onClick={togglePopover}>
                                    <button className="flex justify-between gap-7 text-sm items-center py-2">
                                        <div className="flex gap-2 items-center">
                                            <p className="p-2 rounded-full bg-slate-200"><SiGnuprivacyguard className="text-[11px]"/></p>
                                            <p className="font-semibold">Sign Up</p>
                                        </div>
                                        <p><IoIosArrowForward /></p>
                                    </button>
                                </Link>
                            </>
                        )}

                        {token !== null && (
                            <div onClick={togglePopover}>
                                <MobileProfileDropDown user={user} />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

export default Navbar;