import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import IconBtn from "../../../../common/IconBtn";
import { setStep, resetPostState } from "../../../../../slices/postSlice";
import { POST_STATUS } from "../../../../../utils/constants";
import { editPostDetails } from "../../../../../services/operations/postDetailsAPI";
import { useNavigate } from 'react-router-dom';

const PublishPost = () => {

    const {register, handleSubmit, setValue, getValues} = useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {token} = useSelector((state) => state.auth);
    const {post} = useSelector((state) => state.post);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(post?.status === POST_STATUS.PUBLISHED) {
            setValue("public", true);
        }
    })

    const goBack = () => {
        //handle go back
        dispatch(setStep(2));
    }

    const goToPosts = () => {
        dispatch(resetPostState());
        navigate("/dashboard/my-posts");
    }

    const handlePostPublish = async () => {
        if(post?.status === POST_STATUS.PUBLISHED && getValues("public") === true ||
        (post?.status === POST_STATUS.DRAFT && getValues("public") === false)) {
            //no updation in form
            //no need to make api call
            goToPosts();
            return;
        }
        //if form updated
        const formData = new FormData();
        formData.append("postId", post._id);
        const postStatus = getValues("public") ? POST_STATUS.PUBLISHED : POST_STATUS.DRAFT;
        formData.append("status", postStatus);

        setLoading(true);

        const result = await editPostDetails(formData, token);

        if(result){
            goToPosts();
        }
        setLoading(false);
    }

    const onSubmit = () => {
        handlePostPublish();
    }

    return(
        <div className="rounded-md border-1 bg-[#EED3BA] p-6">
            <div className="flex items-center gap-4 mt-6">
                <div className="p-2 w-[35px] h-[35px] bg-black text-white rounded-full flex items-center justify-center overflow-hidden"> 
                    3
                </div>
                <p className="text-2xl font-light">Publish Post</p>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}
                className="bg-white m-5 p-9"
            >
                <div className="text-lg w-fit">
                    <label htmlFor="public" className="flex gap-2 items-center">
                        <input 
                            type="checkbox"
                            id="public"
                            {...register("public")}
                            className="rounded-lg h-4 w-4"
                        />
                        <span>
                            Publish this post
                        </span>
                    </label>
                </div>

                <p className="mt-3"><span className="font-bold">Note: </span> 
                    After publishing, your post will be displayed publicly to other users. If not published, post will be sent to draft.
                </p>

                <div className="flex flex-col-reverse sm:flex-row justify-end items-center px-2 gap-5 py-2 mt-6">
                    <button
                        disabled={loading}
                        type="button"
                        onClick={goBack}
                        className='bg-slate-200 px-6 py-3 rounded-lg hover:bg-slate-300 transition-all duration-300'
                    >
                        Back
                    </button>
                    <IconBtn 
                        disabled={loading}
                        text={"Save Changes"} 
                        customClasses={"px-6 py-3 bg-blue-600 hover:bg-blue-700 transition-all duration-300 rounded-lg text-white flex items-center gap-2"}
                    />
                </div>
            </form>
        </div>
    )
};

export default PublishPost;