import React, { useState } from "react";
import './loginsignup.css';
import { Link, useNavigate } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { MdEmail } from "react-icons/md";
import newlogo from '../assets/mail/LOGO PNG.png';
import { login } from "../services/operations/authAPI";
import { handleGoogleLogin } from "../services/operations/googleAPI";

const LoginPage = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        email:"", password:""
    })

    const [showPassword, setShowPassword]  = useState(false);

    const {email, password} = formData;

    function changeHandler(event){
        setFormData((prevData) => (
            {
                ...prevData,
                [event.target.name]:event.target.value
            }
        ))
    }

    function submitHandler(event){
        event.preventDefault();
        dispatch(login(email, password, navigate));
    }

    const handleGoogleLoginClick = () => {
        dispatch(handleGoogleLogin(navigate));
    };

    return(
        <div className="w-screen overflow-hidden p-3">
            <div className="relative w-full p-4">
                {/* <img src={bglog} alt="login" className="w-full object-cover aspect-video" />

                <div className="absolute inset-0 bg-gradient-to-r from-black from-60%"></div> */}
                
                <div className="w-full lg:w-5/12 lg:mx-auto bg-white bg-opacity-60 backdrop-blur-[20px] flex flex-col items-center p-5 rounded-xl py-10">
                    <div className="flex flex-col items-center gap-3">
                        <div className="flex w-6/12 lg:w-[150px] lg:h-[120px] xl:w-[230px] xl:h-[190px]">
                            <img src={newlogo} alt="logo" loading="lazy" className="w-full h-full"/>
                        </div>
                        
                        <p className="font-semibold text-xl sm:text-3xl">Welcome back artist<span className="text-[#8D493A]">.</span></p>
                        {/* <p className="font-medium text-[14px]">New Account?  
                            <Link to="/signup" className="text-blue-600"> 
                                Sign Up
                            </Link>
                        </p> */}
                        <p className="font-bold capitalize text-[1.5rem] sm:text-[2rem]">LOGIN</p>
                    </div>

                    <div className="w-full mx-auto">

                        <form onSubmit={submitHandler} className="flex flex-col items-center w-full">

                            <div className="w-full mt-3">
                                <label for="email" className="block mb-2 text-sm font-medium text-black">Your Email</label>
                                <div className="relative">
                                    <div className="absolute inset-y-0 end-0 flex items-center pe-3.5 pointer-events-none">
                                        <MdEmail fontSize={22} className="text-white"/>
                                    </div>
                                    <input
                                        required
                                        type="email"
                                        value={formData.email}
                                        onChange={changeHandler}
                                        placeholder="Enter Email Address"
                                        name="email"
                                        className="text-white w-full placeholder:text-zinc-500 px-3 py-4 rounded-xl 
                                        focus:outline-none bg-zinc-800"
                                    /> 
                                </div>
                            </div>

                            <div className="w-full relative mt-3">
                                <label for="password" className="block mb-2 text-sm font-medium">Password</label>
                                <input
                                    required
                                    type={showPassword ? ("text") : ("password")}
                                    value={formData.password}
                                    onChange={changeHandler}
                                    name="password"
                                    placeholder="Enter Password"
                                    className="text-white placeholder:text-zinc-500 px-3 py-4 rounded-xl w-full
                                    focus:outline-none bg-zinc-800"
                                />
                            
                                <span onClick={() => setShowPassword((prev) => !prev)}
                                className="absolute right-3 top-[44px] cursor-pointer text-white">
                                    {
                                        showPassword ? 
                                        (
                                            <AiFillEyeInvisible fontSize={24}/>
                                        ) : 
                                        (
                                            <AiFillEye fontSize={24}/>
                                        )
                                    }

                                </span>
                            </div>
                             
                            <Link to="/forgot-password" className="mt-3 self-end">
                                <p className="text-right text-[12px]">Recover Password</p>
                            </Link>

                            <button className="w-full py-2 bg-red-400 rounded-xl font-semibold text-white
                            hover:bg-red-500 transition-all duration-300 hover:scale-95 mt-4"
                            type="submit">
                                Log In
                            </button>

                            <div className="flex w-full items-center justify-center mt-4">
                                <Link onClick={handleGoogleLoginClick} className="w-full text-center">
                                    <div className="w-full py-2 px-4 bg-white text-[14px] rounded-xl flex items-center justify-center gap-3">
                                        <FcGoogle/> 
                                        <span>Sign in with Google</span>
                                    </div>
                                </Link>   
                            </div>

                            <div className="mt-4 text-[14px] text-center">
                                <p>Don't have an account?
                                    <Link className="ml-1 text-center font-semibold text-[#8D493A]" to="/signup">
                                        Sign up. it's free!
                                    </Link>
                                </p>
                            </div>
                        
                        </form>

                    </div>


                </div>
            </div>
            
            {/* <div className="w-11/12 max-w-11/12 h-[800px] bg-white/80 rounded-2xl mx-auto mt-4 mb-4 p-3 text-center flex 
            border-2 border-white
            isolate aspect-video shadow-lg ring-1 ring-black/5">    
                <div className="w-[50%] h-full rounded-2xl overflow-hidden">
                    <img src={threedimage} alt="login" />
                </div>  
                <div className="flex flex-col items-center pr-8 w-[50%]">
                    <div className="font-semibold text-[14px] mt-8 ml-[25rem]">
                        <p>Not a member? 
                            <Link to="/signup">
                                <span className="text-blue-600 ml-1">
                                    Register now
                                </span>
                            </Link>
                        </p>
                    </div>
                    <div className="gap-10 mt-16 flex flex-col">
                       <h1 className="text-4xl font-bold overflow-hidden">Hello Again</h1>
                       <p>Welcome back you've been missed!</p> 
                        <form onSubmit={submitHandler} className="flex flex-col gap-5">
                            <label>
                               <input
                                    required
                                    type="email"
                                    value={formData.email}
                                    onChange={changeHandler}
                                    placeholder="Enter username"
                                    name="email"
                                    className="text-white placeholder:text-white px-3 py-4 w-[350px] rounded-xl focus:outline-none bg-gray-400"
                                /> 
                            </label>
                            
                            <label className="w-full relative text-white">
                              <input
                                required
                                type={showPassword ? ("text") : ("password")}
                                value={formData.password}
                                onChange={changeHandler}
                                name="password"
                                placeholder="Password"
                                className="text-white placeholder:text-white px-3 py-4 w-[350px] rounded-xl focus:outline-none bg-gray-400"
                            />
                            
                                <span onClick={() => setShowPassword((prev) => !prev)}
                                className="absolute right-3 top-[18px] cursor-pointer">
                                    {
                                        showPassword ? 
                                        (
                                            <AiFillEyeInvisible fontSize={24}/>
                                        ) : 
                                        (
                                            <AiFillEye fontSize={24}/>
                                        )
                                    }

                                </span>
                            </label>
                            
                            
                            <Link to="/forgot-password">
                                <p className="text-right text-sm">Recover Password</p>
                            </Link>

                            <button className="w-full py-4 bg-red-400 rounded-xl font-semibold text-white
                            hover:bg-red-500 transition-all duration-300 hover:scale-95"
                            type="submit">
                                Log In
                            </button>
                        
                        </form>

                        <div className="flex w-full items-center gap-2 justify-center">
                            <div className="w-[28%] h-[3px] bg-gradient-to-l from-white to-gray-200 rounded-full"></div>
                            <p>or continue with</p>
                            <div className="w-[28%] h-[3px] bg-gradient-to-r from-white to-gray-200 rounded-full"></div>
                        </div>

                        <div className="flex w-full items-center justify-center">
                            <Link>
                                <div className="py-2 px-4 border-2 border-gray-400 text-[25px] rounded-xl">
                                    <FcGoogle/> 
                                </div>
                            </Link>
                            
                            
                        </div>
                    </div>
                    
                </div>

            </div> */}
        </div>
    )
};

export default LoginPage;