import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../components/Spinner";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { resetPassword } from "../services/operations/authAPI";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { MdKeyboardArrowRight } from 'react-icons/md';


const UpdatePassword = () => {

    const dispatch = useDispatch();

    const location = useLocation();

    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        password:"", confirmPassword:""
    });

    const {loading} = useSelector((state) => state.auth);

    const [showPassword, setShowPassword] = useState({
        password:false,
        confirmPassword:false
    });

    const {password, confirmPassword} = formData;

    function handleOnChange(e){
        setFormData((prevData) => (
            {
                ...prevData,
                [e.target.name]:e.target.value
            }
        ))
    }

    const handleOnSubmit = (e) => {
        e.preventDefault();
        const token = location.pathname.split('/').at(-1);
        dispatch(resetPassword(password, confirmPassword, token));
        navigate("/login");
    }

    return(
        <div className="w-screen h-screen flex flex-col bg-[#EBF1FF]
        justify-center items-center">
            {
                loading ? (
                    <div>
                        <Spinner/>
                    </div>
                ) :
                (
                    <div className="flex flex-col gap-5 w-full max-w-[500px]
                    text-center border-2 border-white bg-white/80 
                    shadow-lg ring-1 ring-black/5
                    rounded-2xl py-10 px-10 translate-y-[-10%] h-auto">
                        <h1 className="text-3xl font-bold">
                            Enter new Password
                        </h1>

                        <p>
                            Your new password must be different from previous
                            used passwords.
                        </p>

                        <form onSubmit={handleOnSubmit}
                        className="flex flex-col gap-4 justify-center">
                            <label className="relative w-full text-white">
                               <input 
                                    required
                                    type={showPassword.password ? ("text") : ("password")}
                                    name="password"
                                    value={password}
                                    onChange={handleOnChange}
                                    placeholder="New Password"
                                    className="text-white placeholder:text-white px-3 py-4 rounded-xl focus:outline-none bg-gray-400 w-[80%]"
                                />
                                <span onClick={() => setShowPassword((prev) => ({
                                        ...prev,
                                        password: !prev.password
                                    }))}
                                    className="absolute right-14 top-[18px] cursor-pointer">
                                        {
                                            showPassword.password ? 
                                            (
                                                <AiFillEyeInvisible fontSize={24}/>
                                            ) : 
                                            (
                                                <AiFillEye fontSize={24}/>
                                            )
                                        }

                                </span>
                            </label>

                            <label className="w-full relative text-white">
                                    <input
                                        required
                                        type={showPassword.confirmPassword ? ("text") : ("password")}
                                        value={formData.confirmPassword}
                                        onChange={handleOnChange}
                                        name="confirmPassword"
                                        placeholder="Confirm Password"
                                        className="text-white placeholder:text-white px-3 py-4 rounded-xl focus:outline-none bg-gray-400 w-[80%]"
                                    />
                                
                                    <span onClick={() => setShowPassword((prev) => ({
                                        ...prev,
                                        confirmPassword: !prev.confirmPassword
                                    }))}
                                    className="absolute right-14 top-[18px] cursor-pointer">
                                        {
                                            showPassword.confirmPassword ? 
                                            (
                                                <AiFillEyeInvisible fontSize={24}/>
                                            ) : 
                                            (
                                                <AiFillEye fontSize={24}/>
                                            )
                                        }

                                    </span>
                            </label>

                            <button type="submit"
                            className="w-fit px-6 mx-auto py-4 bg-red-400 rounded-xl font-semibold text-white
                            hover:bg-red-500 transition-all duration-300 hover:scale-95">
                                Reset Password
                            </button>
                            
                        </form>

                        <div className="flex flex-col items-center">
                            <Link to="/login">
                                <p className="font-semibold text-blue-600 flex items-center gap-2">
                                    Back to Login
                                    <MdKeyboardArrowRight fontSize={20}/>
                                </p>
                            </Link>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default UpdatePassword;