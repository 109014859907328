import Card from "../../../common/Card Component/Card"
import RenderSteps from "./RenderSteps"

export default function AddPost() {
    return(
        <div className="px-8 py-8 xl:px-[6rem] xl:py-[5rem] overflow-y-auto bg-white flex flex-col-reverse lg:flex-row gap-10 w-full justify-center flex-1 min-h-[790px] items-center lg:items-start">
            <div className="w-full lg:w-8/12">
                <h1 className="text-4xl text-center sm:text-left font-bold overflow-hidden">Add Post</h1>
                <div>
                    <RenderSteps />
                </div>
            </div>
            <div className="w-full lg:w-4/12 2xl:p-10">
                <Card text={"post"}/>
            </div>
        </div>
    )
}