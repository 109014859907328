import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Popover, PopoverButton, PopoverPanel, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { getAdminPostDetails, getUserPosts } from "../../../services/operations/blogDetailsAPI";
import MobileProfileDropDown from "./MobileProfileDropDown";

const ProfileDropDown = () => {

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const {user} = useSelector((state) => state.profile);
    const {token} = useSelector((state) => state.auth);
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const fetchDraftPosts = async () => {
            try{
                let response;
                if(user.accountType === "Admin"){
                    response = await getAdminPostDetails(token);
                } else {
                    response = await getUserPosts(token);
                }

                if(response){
                    setPosts(response);
                }
            }
            catch(error){
                console.log(error);
            }
        }

        fetchDraftPosts();
    },[]);

    const draftPosts = posts.filter((post) => post.status === "Draft");

    return (
        <div className="w-fit translate-y-[3px]">
            <Popover className="w-fit">
                <PopoverButton className="focus:outline-none data-[focus]:outline-1 data-[focus]:outline-white">
                    <div className="flex items-center gap-3">
                        <div className="rounded-full">
                            <img src={user?.image?.url} alt="user"
                            className="w-[35px] h-[35px] bg-white" />
                        </div>

                        <div className="font-bold text-white">
                            <p>Hi, {user?.firstName}</p>
                        </div>
                        <div className="text-white">
                            <MdOutlineKeyboardArrowDown />
                        </div>
                    </div>                            
                </PopoverButton>

                    <Transition
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <PopoverPanel anchor="bottom" className="translate-y-[20px] -translate-x-[10px] absolute z-20">
                            <MobileProfileDropDown user={user} draftPosts={draftPosts}/>
                            {/* <div className="border-2 w-[300px] rounded-xl h-auto flex flex-col items-center bg-white">
                                <div className="flex w-full items-center">
                                    <div className="w-full h-fit flex p-2 py-5 gap-4 items-center justify-center">
                                        <div className="rounded-full">
                                          <img src={user?.image?.url} className="w-[50px] h-[50px] rounded-full" />  
                                        </div>
                                        
                                        <div className="flex flex-col font-semibold">
                                            <h3>{user?.firstName} {user?.lastName}</h3>
                                            <p className="text-[13px] text-gray-300">{user?.email}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="w-[90%] h-[1px] bg-gray-200"></div>

                                <div className="w-full p-4 px-8 flex flex-col h-auto gap-2">
                                    <Link to="dashboard/my-profile">
                                        <div className="flex items-center gap-3 hover:bg-gray-200 p-2 rounded-lg transition-all duration-300
                                        text-gray-400 hover:text-black">
                                            <VscAccount />Dashboard
                                        </div>
                                    </Link>
                                    <Link to="dashboard/draft">
                                        <div className="flex items-center gap-3 hover:bg-gray-200 p-2 rounded-lg transition-all duration-300
                                            text-gray-400 hover:text-black">
                                                <LuFileSpreadsheet />Drafts
                                                <span className="bg-black w-4 h-4 rounded-full text-[11px] flex justify-center items-center
                                                text-white">
                                                    {draftPosts?.length}
                                                </span>
                                        </div>
                                    </Link>
                                    
                                        <div className="flex items-center gap-3 p-2 rounded-lg transition-all duration-300
                                            text-gray-400 select-none line-through">
                                            <MdOutlineNotifications />Notifications
                                        </div>
                                    
                                    <Link to="dashboard/help">
                                        <div className="flex items-center gap-3 hover:bg-gray-200 p-2 rounded-lg transition-all duration-300
                                            text-gray-400 hover:text-black">
                                            <IoMdHelpCircleOutline />Help Center
                                        </div>
                                    </Link>
                                </div>

                                <div className="w-[90%] h-[1px] bg-gray-200"></div>

                                <div>
                                    <button className="text-gray-400 flex gap-3 items-center font-semibold
                                    px-5 py-4 hover:scale-90 hover:text-black transition-all duration-300"
                                    onClick={() => dispatch(logout(navigate))}>
                                        <LuDoorOpen />
                                        Log Out
                                    </button>
                                </div>
                            </div> */}
                        </PopoverPanel>

                    </Transition>
            </Popover>
           {/* <div className="border-2 border-blue-500 w-[40px] h-[40px] rounded-lg">
            <img src="" />

            </div> 

            <div>
                
            </div> */}
        </div>
        
    )
};

export default ProfileDropDown;