import React, { useState } from "react";
import { IoSearchSharp } from "react-icons/io5";
import {TypeAnimation} from 'react-type-animation';
import { IoSend } from "react-icons/io5";
import { RiAccountBoxFill } from "react-icons/ri";
import { BsFillFilePostFill } from "react-icons/bs";
import { MdPrivacyTip } from "react-icons/md";
import { MdOutlineManageSearch } from "react-icons/md";
import { LuSquareStack } from "react-icons/lu";
import GettingStarted from "../components/core/Dashboard/Help/GettingStarted";
import ManageAccount from "../components/core/Dashboard/Help/ManageAccount";
import ManagePosts from "../components/core/Dashboard/Help/ManagePosts";
import PrivacyAndSecurity from "../components/core/Dashboard/Help/PrivacyAndSecurity";
import OtherTopics from "../components/core/Dashboard/Help/OtherTopics";
import FutureScopes from "../components/core/Dashboard/Help/FutureScopes";

const HelpCenter = () => {

    const [selectedSection, setSelectedSection] = useState('gettingStarted');

    const handleSectionClick = (section) => {
        setSelectedSection(section);
    };

    const getClasses = (section) => {
        return selectedSection === section ? 'bg-[#8D493A] text-white rounded-lg' : 'bg-white text-black hover:bg-[#F8EDE3] transition-all duration-300';
    };

    return (
        <div className="overflow-y-auto bg-white flex gap-10 w-full flex-col h-fit">

            <div className="flex items-center gap-4 xl:gap-6 bg-[#D0B8A8] px-8 py-8 xl:px-[6rem] xl:py-[5rem]">
                <p className="text-[20px] sm:text-[22px] lg:text-[30px] xl:text-[4rem] text-white"><IoSearchSharp /></p>
                <h1 className="text-[20px] sm:text-[22px] lg:text-[30px] xl:text-[4rem] text-center font-bold text-white">
                    <TypeAnimation
                        sequence={[
                            // Same substring at the start will only be typed once, initially
                            'How can we help you?',
                            4000,
                            'Need help with something?',
                            4000,
                        ]}
                        speed={300}
                        repeat={Infinity}
                    />
                </h1>
            </div>

            <div className="flex flex-col w-full items-center gap-20 bg-white px-8 py-5 xl:px-[6rem] xl:py-10">
                <div className="flex gap-y-10 flex-wrap lg:flex-nowrap justify-between w-full">
                    <p className={`flex flex-col items-center gap-4 p-4 cursor-pointer 
                        ${getClasses('gettingStarted')}`} onClick={() => handleSectionClick('gettingStarted')}>
                        <IoSend className="text-[2rem]"/> <span>Getting Started</span></p>
                    
                    <p className={`flex flex-col items-center gap-4 p-4 cursor-pointer 
                        ${getClasses('manageAccount')}`} onClick={() => handleSectionClick('manageAccount')}>
                        <RiAccountBoxFill className="text-[2rem]"/><span>Manage Account</span></p>
                    
                    <p className={`flex flex-col items-center gap-4 p-4 cursor-pointer 
                        ${getClasses('managingPosts')}`} onClick={() => handleSectionClick('managingPosts')}>
                        <BsFillFilePostFill className="text-[2rem]"/><span>Managing/Creating Posts</span></p>
                    
                    <p className={`flex flex-col items-center gap-4 p-4 cursor-pointer 
                        ${getClasses('privacySecurity')}`} onClick={() => handleSectionClick('privacySecurity')}>
                        <MdPrivacyTip className="text-[2rem]"/><span>Privacy & Security</span></p>
                    
                    <p className={`flex flex-col items-center gap-4 p-4 cursor-pointer 
                        ${getClasses('futureScopes')}`} onClick={() => handleSectionClick('futureScopes')}>
                        <LuSquareStack className="text-[2rem]"/><span>Future Scopes</span></p>
                </div>
                
                <div className="bg-white w-full overflow-hidden">
                    {selectedSection === 'gettingStarted' && <GettingStarted />}
                    {selectedSection === 'manageAccount' && <ManageAccount />}
                    {selectedSection === 'managingPosts' && <ManagePosts />}
                    {selectedSection === 'privacySecurity' && <PrivacyAndSecurity />}
                    {selectedSection === 'futureScopes' && <FutureScopes />}
                </div>
                
            </div>

            
        </div>
    )
};

export default HelpCenter;